import { Box, Button } from "@chakra-ui/react";
import { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { routes } from "@/app/routes";
import { BeatLoader } from "react-spinners";
import { bottomBarHeight } from "@/app/types";
type Props = {
}

export default function TaskAddButton({ }: Props) {
    const [isLoading, setIsloading] = useState<boolean>(false);
    return (
        <Box
            position='fixed'
            bottom={bottomBarHeight}
            right={4}
            zIndex={3}
        >
            <Link to={routes.ADD_TASK}>
                <Button
                    leftIcon={<AddIcon />}
                    onClick={() => {
                        setIsloading(true);
                    }}
                    disabled={isLoading}
                    spinner={<BeatLoader size={8} color='white' />}
                    isLoading={isLoading}
                    colorScheme={'blue'}
                    size='lg'
                >
                    Nova tarefa
                </Button>
            </Link>
        </Box>
    )
}