"use client";

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useSelector as useReduxSelector, useDispatch as useReduxDispatch, } from 'react-redux'
import { authReducer, setAuthState } from './slices/auth/slice'

import {
  persistStore,
  persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { NODE_ENV, NODE_ENV_PRODUCTION } from '@/app/config';


const authPersistConfig = {
  key: "auth",
  storage: storage,
};

const persistedReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
});

export const store = configureStore({
  reducer: persistedReducer,
  devTools: NODE_ENV !== NODE_ENV_PRODUCTION,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
export const persistor = persistStore(store)

export const useDispatch = () => useReduxDispatch()
export const useSelector = useReduxSelector

export { setAuthState }

export type IRootState = ReturnType<typeof persistedReducer>
