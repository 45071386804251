import { Id } from "@/app/types";
import { useChatContext } from "@/contexts/chatContext";
import { StackProps, VStack, Text } from "@chakra-ui/react";
import MessageBaloon from "./messageBaloon";
import moment from "moment";
import { Fragment } from "react";

interface Props extends StackProps {
    scrollRef: React.RefObject<HTMLDivElement>;
    currentUserId: Id;
    sx?: any;
}

function groupMessagesByDate(messages: any[]) {
    const grouped: Record<string, any[]> = {};
    messages.forEach((message) => {
        const createdDate = moment(message.created_at);
        let label;

        if (createdDate.isSame(moment(), 'day')) {
            label = 'Hoje';
        } else if (createdDate.isSame(moment().subtract(1, 'day'), 'day')) {
            label = 'Ontem';
        } else if (createdDate.isSame(moment(), 'week')) {
            label = createdDate.format('dddd');
        } else {
            label = createdDate.format('DD/MM/YYYY');
        }

        if (!grouped[label]) grouped[label] = [];
        grouped[label].push(message);
    });
    return grouped;
}


export default function ConversationsMessagesList({
    scrollRef,
    currentUserId,
    ...props
}: Props) {
    const { messages } = useChatContext();
    const groupedMessages = groupMessagesByDate(messages);

    return (
        <VStack
            ref={scrollRef}
            flexDirection={"column-reverse"}
            width={"100%"}
            spacing={5}
            maxHeight={"100%"}
            sx={{
                "&::-webkit-scrollbar": {
                    width: "0px",
                },
                ...props.sx,
            }}
            {...props}
        >
            {Object.entries(groupedMessages).map(([dateLabel, messages]) => (
                ((messages ?? []).length > 0) &&
                <Fragment key={dateLabel}>
                    {messages.map((message) => (
                        <MessageBaloon
                            key={message.id}
                            message={message}
                            currentUserId={currentUserId}
                        />
                    ))}
                    <Text
                        fontSize={"sm"}
                        color={"gray.500"}
                        fontWeight={"bold"}
                        alignSelf={"center"}
                        mt={4}
                    >
                        {dateLabel}
                    </Text>
                </Fragment>


            ))}
        </VStack>
    );
}
