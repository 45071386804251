
import { Flex, useColorModeValue } from '@chakra-ui/react';

export function SidebarBrand() {
	let logoColor = useColorModeValue('blue.400', 'blue.400');

	return (
		<Flex color={logoColor} alignItems='center' flexDirection='column'>
			H X O
		</Flex>
	);
}

export default SidebarBrand;