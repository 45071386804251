import { Assignee } from "@/lib/api/assigners.types";

import { Avatar, Box, Card, CardBody, Flex, Spacer, Tag, TagLabel, Text } from "@chakra-ui/react";
import { taskAssigneeStatus } from "@/utils/translators";
import { CheckCircleIcon } from "@chakra-ui/icons";

type AssigneeItemProps = {
    assignee: Assignee
    withStatus?: boolean
    isChecked?: boolean
    onClick?: (assignee: Assignee) => void
}

export default function AssigneeItem({
    assignee,
    withStatus = true,
    isChecked = false,
    onClick = () => { }
}: AssigneeItemProps) {
    return (
        <Card
            onClick={() => {
                onClick && onClick(assignee)
            }}
        >
            <CardBody>
                <Flex maxWidth='500px' alignItems='center' gap='2'>
                    <Avatar size="sm" name={assignee.name} src={assignee.picture} />
                    <Text>{assignee.name}</Text>
                    <Spacer />
                    <Box p='2'>
                        {withStatus && !!assignee.pivot?.status && (
                            <Tag size='lg' borderRadius='full'>
                                <TagLabel>{taskAssigneeStatus(assignee.pivot.status)}</TagLabel>
                            </Tag>
                        )}
                        {isChecked && (
                            <CheckCircleIcon color={'blue.400'} />
                        )}
                    </Box>
                </Flex>
            </CardBody>
        </Card>
    )
}