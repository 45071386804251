import { Box, Button, Card, CardBody, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Assignee } from "@/lib/api/assigners.types";
import { User } from "@/lib/api/user.types";
import AssigneeItem from "./assigneeItem";
import { requestGetUsers, requestGetUsersForTask } from "@/lib/api/user";
import { Select } from "chakra-react-select";
import { requestGetDepartments, requestGetDepartmentsForTask } from "@/lib/api/departments";
import { Department } from "@/lib/api/departments.types";
import DepartmentItem from "./departmentItem";
import { SAFE_AREA_TOP_CSS } from "@/app/config";

type Props = {
  taskAssigners: Assignee[];
  taskDepartments: Department[];
  buttonLabel: string;
  onRefresh?: () => Promise<any>;
  onError?: (error: string) => void;
  onAddAssignee?: (assignee: Assignee) => void;
  onRemoveAssignee?: (assignee: Assignee) => void;
  onAddDepartment?: (department: Department) => void;
  onRemoveDepartment?: (department: Department) => void;
}

export default function AssigneeAddModal({
  taskAssigners,
  taskDepartments,
  buttonLabel,
  onAddAssignee,
  onRemoveAssignee,
  onAddDepartment,
  onRemoveDepartment,
}: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [users, setUsers] = useState<User[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    requestGetUsersForTask().then((request) => {
      setUsers(request.data);
    })

    requestGetDepartmentsForTask().then((request) => {
      setDepartments(request.data);
    })
  }, [])

  return (
    <>
      <Button
        onClick={onOpen}
        variant={'outline'}
        colorScheme={'blue'}
        size={'sm'}
        mt={2}
        w={'100%'}
        h={'40px'}
        border={'1px solid'}
        borderColor={'blue.500'}
      >
        {buttonLabel}
      </Button>
      <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent pt={SAFE_AREA_TOP_CSS}>
          <ModalHeader>Adicionar responsável</ModalHeader>
          <ModalCloseButton
            position={'fixed'}
            top={`calc(1rem+${SAFE_AREA_TOP_CSS})`}
            right={'1rem'}
            size='lg'
            zIndex={3}
          />
          <ModalBody>
            <Tabs
              isFitted
              variant='solid-rounded'
              colorScheme='blue'
              isLazy
              defaultIndex={!!taskAssigners.length ? 1 : 0}
            >
              <TabList>
                <Tab
                  isDisabled={!!taskAssigners.length}
                  borderRadius={5}
                >
                  Departamentos
                </Tab>
                <Tab
                  isDisabled={!!taskDepartments.length}
                  borderRadius={5}
                >
                  Usuários
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel >
                  <Card>
                    <CardBody>
                      <SimpleGrid spacing={2}>
                        {departments.map((department, key) => {
                          return (
                            <DepartmentItem
                              key={key}
                              withCheckBox
                              isChecked={!!taskDepartments.find(dp => dp.id === department.id)}
                              onClick={() => {
                                const isDepartment = taskDepartments.find(dp => dp.id === department.id);
                                if (!isDepartment) {
                                  onAddDepartment && onAddDepartment(department as Department);
                                } else {
                                  onRemoveDepartment && onRemoveDepartment(department as Department);
                                }
                              }}
                              department={department as Department}
                            />
                          )
                        })}
                      </SimpleGrid>
                    </CardBody>
                  </Card>
                </TabPanel>
                <TabPanel >
                  <Box mb={2}>
                    <Select
                      isClearable={false}
                      isMulti
                      value={taskAssigners.map(assignee => {
                        return {
                          value: assignee.id,
                          label: assignee.name
                        }
                      })}
                      onChange={(value) => {
                        const added = value.filter(v => !taskAssigners.find(a => a.id === v.value));
                        const removed = taskAssigners.filter(a => !value.find(v => v.value === a.id));
                        onAddAssignee && added.forEach(v => onAddAssignee(users.find(u => u.id === v.value) as Assignee));
                        onRemoveAssignee && removed.forEach(v => onRemoveAssignee(users.find(u => u.id === v.id) as Assignee));
                      }}
                      placeholder="Pesquise"
                      options={users.map(user => {
                        return {
                          value: user.id,
                          label: user.name
                        }
                      })}
                    />
                  </Box>
                  <Card>
                    <CardBody>
                      <SimpleGrid spacing={2}>
                        {users.map((user, key) => {
                          return (
                            <AssigneeItem
                              key={key}
                              isChecked={!!taskAssigners.find(assignee => assignee.id === user.id)}
                              onClick={() => {
                                const isAssignee = taskAssigners.find(assignee => assignee.id === user.id);
                                if (!isAssignee) {
                                  onAddAssignee && onAddAssignee(user as Assignee);
                                } else {
                                  onRemoveAssignee && onRemoveAssignee(user as Assignee);
                                }

                              }}
                              assignee={user as Assignee}
                            />
                          )
                        })}
                      </SimpleGrid>
                    </CardBody>
                  </Card>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter id={'bottom'}>
            <Button />
          </ModalFooter>
        </ModalContent>
      </Modal></>
  )
}