import { Id } from "@/app/types"

export type Service = {
    id: Id
    code: string
    name: string
    created_at: string
    updated_at: string
}

export type CreateServiceRequest = {
    code: string
    name: string
}

export type UpdateServiceRequest = {
    code: string
    name: string
}

export const createServiceRequestInitialValues: CreateServiceRequest = {
    name: '',
    code: ''
}
