"use client"

import { requestResetPassword } from '@/lib/api/password';
import { PasswordResetPayload } from '@/lib/api/password.types';
import { Button, Card, CardBody, CardFooter, FormControl, FormErrorMessage, Heading, Icon, Input, InputGroup, InputRightElement, Stack, Text, useToast } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import * as Yup from 'yup';

export default function ResetPasswordPage() {
    const toast = useToast();
    
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const { token } = useParams();
    const [hidePassw, setHidePassw] = useState(true);

    const initialValues = { password: '', password_confirmation: '' }

    const validationSchema = Yup.object({
        password: Yup.string().required('A senha é obrigatória'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais')
            .required('Confirmação de senha é obrigatória')
    });

    return (

        <Card w="100%" maxW="md" align='center' p={4} display="flex" justifyContent="center" alignItems="center" >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const payload: PasswordResetPayload = {
                        email,
                        token: token as string,
                        password: values.password,
                        password_confirmation: values.password_confirmation
                    }
                    requestResetPassword(payload)
                        .then(() => {
                            toast({
                                title: 'Senha redefinida com sucesso',
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            });
                            navigate('/login');
                        })
                        .catch(err => {
                            toast({
                                title: 'Erro ao redefinir a senha',
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <CardBody>
                            <Heading size="md" mb={4}>Redefinir Senha</Heading>
                            <Text mb={6}>
                                Crie uma nova senha para sua conta.
                            </Text>

                            <Stack mt='6' spacing='5'>
                                <FormControl isInvalid={!!errors.password}>
                                    <InputGroup size='lg'>
                                        <Input
                                            name="password"
                                            type={hidePassw ? 'password' : 'text'}
                                            placeholder='Nova Senha'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        <InputRightElement width='4.5rem'>
                                            <Icon
                                                as={hidePassw ? FaEye : FaEyeSlash}
                                                onClick={() => setHidePassw(!hidePassw)}
                                                cursor="pointer"
                                            />
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.password && touched.password && errors.password}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors.password_confirmation}>
                                    <InputGroup size='lg'>
                                        <Input
                                            name="password_confirmation"
                                            type={hidePassw ? 'password' : 'text'}
                                            placeholder='Confirme a Nova Senha'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password_confirmation}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.password_confirmation && touched.password_confirmation && errors.password_confirmation}
                                    </FormErrorMessage>
                                </FormControl>
                            </Stack>
                        </CardBody>
                        <CardFooter mt={4}>
                            <Button
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                type='submit'
                                size='lg'
                                w='100%'
                                variant='solid'
                                colorScheme='blue'
                            >
                                Redefinir Senha
                            </Button>
                        </CardFooter>
                    </form>
                )}
            </Formik>
        </Card>

    );
}
