import { instance } from "./api";

export function requestLogin(email, password) {
    return instance.post(`/login`, {
        email,
        password
    })
}

export function requestLogout() {
    return instance.post(`/logout`)
}