"use client"

import { Provider } from "react-redux"
import { store } from "@/lib/redux/store"
import persistStore from "redux-persist/es/persistStore"

persistStore(store)

export const ReduxProvider = (props) => {
  return (
    <Provider store={store}>
      {props.children}
    </Provider>

  )
}