import { Id } from "@/app/types";
import { instance } from "./api";
import { InfiniteData } from "@tanstack/react-query";
import { TaskResponse } from "./task.types";
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { saveAs } from 'file-saver'; // Para web
import { Attachment } from "./attatchment.types";
import { requestDownloadAttachment } from "./attachments";
import { Share } from '@capacitor/share';
import { FileOpener } from "@capacitor-community/file-opener";


export type Address = {
    zip_code: string;
    street: string;
    district: string;
    city: string;
    state: string;
}

export async function requestGetAddress(cep: string) {
    return await instance.get<Address>(`/cep/${cep}`);
}

export async function downloadFile(url: string, filename: string) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
}


async function shareFile(fileName: string, base64Data: string) {
    await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Cache
    });
    const uriResult = await Filesystem.getUri({
        directory: Directory.Cache,
        path: fileName
    });
    return await Share.share({
        title: fileName,
        text: fileName,
        url: uriResult.uri,
    });
  }


  async function openFile(fileName: string, base64Data: string, mimeType: string) {
    await Filesystem.writeFile({
        path: fileName,
        data: base64Data,
        directory: Directory.Cache
    });
    const uriResult = await Filesystem.getUri({
        directory: Directory.Cache,
        path: fileName
    });
    const filePath = uriResult.uri;

    await FileOpener.open({
        filePath,
        contentType: mimeType,
    });
}



export const downloadAttachment = async (attachment: Attachment) => {
    const isMobile = Capacitor.isNativePlatform();

    try {
        const response = await requestDownloadAttachment(attachment.id);
        const blob = new Blob([response.data], { type: response.data.type });

        if (!isMobile) {
            saveAs(blob, attachment.original_name);
            return
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64Data = reader.result?.toString().split(',')[1];

            if (base64Data) {
                await shareFile(attachment.original_name, base64Data);
            }
        };

        reader.readAsDataURL(blob); 
   
    } catch (err) {
        alert(`Erro ao baixar o arquivo`);
        console.error('Erro ao baixar o arquivo:', err);
    }
};

export const viewAttachment = async (attachment: Attachment) => {
    try {
        const response = await requestDownloadAttachment(attachment.id);
        const blob = new Blob([response.data], { type: response.data.type });

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64Data = reader.result?.toString().split(',')[1];

            if (base64Data) {
                await openFile(attachment.original_name, base64Data, blob.type);
            }
        };

        reader.readAsDataURL(blob); 
   
    } catch (err) {
        alert(`Erro ao abrir o arquivo`);
        console.error('Erro ao abrir o arquivo:', err);
    }
};

export function arrayColumn(arr, column) {
    return arr.map(function (row) {
        return row[column];
    });
}

export function laravelNextPage(nextPageUrl: string) {
    if (nextPageUrl) {
        const url = new URL(nextPageUrl);
        return parseInt(url.searchParams.get('page'));
    }
    return null;
}

export function mergeLaravelReactQueryPages(data: InfiniteData<TaskResponse, unknown>) {
    return data?.pages.reduce((acc, page) => {
        acc.data = [...acc.data, ...page.data]
        return acc;
    }, { data: [] }) as TaskResponse
}
