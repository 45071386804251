import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import TaskFinishModal from "./taskFinishModal";
import { useTaskDetailsContext } from "@/contexts/taskDetailsContext";
import { requestAssignTask } from "@/lib/api/task";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { Id } from "@/app/types";
import { useState } from "react";
import { IRootState } from "@/lib/redux/store";
import { useSelector } from "react-redux";
import { SAFE_AREA_TOP_CSS } from "@/app/config";

export default function TaskAssignConfirmModal() {
  const { isOpen: isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false);
  const { getTask, task } = useTaskDetailsContext();

  const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
  const toast = useToast();

  if (!auth.userInfo?.id) {
    return <></>;
  }

  return (
    <>
      {task.can_assign && <Button colorScheme='blue' onClick={onOpen} >Atribuir tarefa</Button>}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent pt={SAFE_AREA_TOP_CSS}>
          <ModalHeader>Atribuir tarefa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Deseja se atribuir à essa tarefa?
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='blue' mr={3}
              onClick={() => {
                setLoading(true)
                requestAssignTask(task.id, { assigner_id: auth.userInfo.id as Id }).then(() => {
                  getTask();
                  toast({
                    title: 'A tarefa foi atribuída a você',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                  })

                  return;

                }).catch((error) => {
                  toast({
                    title: 'Erro ao atribuir tarefa',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  })
                  return;
                }).finally(() => {
                  onClose();
                  setLoading(false);
                })
              }}>
              Sim
            </Button>
            <Button isLoading={loading} variant='ghost' onClick={onClose}>Não</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
