'use client'
import { routes } from "@/app/routes"
import { useAuthContext } from "@/contexts/authContext"
import TaskFiltersContext, { TaskFiltersType } from "@/contexts/taskFiltersContext"
import { Task, TaskResponse } from "@/lib/api/task.types"
import { Box, Center, SimpleGrid, Spinner } from "@chakra-ui/react"
import { Link } from "react-router-dom";

import { useContext } from "react"
import TasksFilters from "../filters/tasks"
import ItemsGenerator from "../itemsGenerator/itemsGenerator"
import EndPageDetector from "../scroll/endPageDetector"
import TaskSkeleton from "../skeletons/taskSkeleton"
import EmptyTaskList from "../vectors/EmptyTaskList"
import TaskAddButton from "./taskAddButton"
import TaskCard from "./taskCard"

type Props = {
    tasks?: Task[]
    tasksPaginated?: TaskResponse
    isLoading: boolean
    isLoadingMore?: boolean
    withFilters?: boolean
    onSetFilters?: (filters: TaskFiltersType) => void
    onNextPage?: () => void
}

export default function TasksList({
    tasks,
    tasksPaginated,
    isLoading = true,
    isLoadingMore = false,
    withFilters,
    onSetFilters,
    onNextPage,
}: Props) {
    const { filters, setFilters } = useContext(TaskFiltersContext);

    const { userInfo, userClinic } = useAuthContext();
    const canCreateNewTask = userInfo?.is_admin || !!userClinic?.clinic?.id

    const tasksData = tasksPaginated ? tasksPaginated.data : tasks

    if (!userInfo) return null;

    return (
        <Box>
            {withFilters && (
                <TasksFilters
                    filters={filters}
                    setFilters={setFilters}
                    onSetFilters={(_filters) => {
                        onSetFilters?.(_filters)
                    }}
                />
            )}
            {!isLoading && !tasksData?.length ? (
                <EmptyTaskList />
            ) : (
                <SimpleGrid
                    columns={{ base: 1, md: 1, lg: 2, "2xl": 3, }}
                    gap={2}
                >
                    {isLoading ? (
                        <ItemsGenerator range={4}>
                            <TaskSkeleton />
                        </ItemsGenerator>
                    ) : (
                        tasksData?.map((task, key) => {
                            const taskRoute = `${routes.TASKS}/${task.id}`
                            return (<Link key={key} to={taskRoute}>
                                <TaskCard key={task.id} task={task} />
                            </Link>)
                        })
                    )}
                </SimpleGrid>
            )}

            {canCreateNewTask && <TaskAddButton />}

            {isLoadingMore &&
                <Center m={3}>
                    <Spinner />
                </Center>
            }
            <EndPageDetector
                onEndOfPageReach={onNextPage}
            />
        </Box>
    )
}