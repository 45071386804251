"use client";

import { Card, CardBody, CardFooter, Text, Stack, Button, Input, InputRightElement, InputGroup, IconButton, Icon, Image, Center, FormErrorMessage, FormControl, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";
import { Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
    useDispatch,
    setAuthState
} from "@/lib/redux/store"
import { requestLogin } from '@/lib/api/login';
import { INTERNAL_SERVER_ERROR, INVALID_CREDENTIALS_ERROR } from '@/utils/enums';


function Login() {
    const toast = useToast();
    const dispatch = useDispatch()
    const [hidePassw, setHidePassw] = useState(true)

    const initialValues = { email: '', password: '' }
    const validationSchema = Yup.object({
        email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
        password: Yup.string().required('A senha é obrigatória')
    });

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);

        const loginPromise = requestLogin(values.email, values.password)
            .then(({ data }) => {
                dispatch(setAuthState({
                    success: true,
                    userToken: data.token,
                    error: false,
                    message: 'Sucesso',
                }));
                return {
                    title: 'Login realizado!',
                    message: ``,
                };
            })
            .catch(function(error) {
                let errorMsg = INTERNAL_SERVER_ERROR;

                dispatch(setAuthState({
                    success: false,
                    userToken: null,
                    error: true,
                    message: INVALID_CREDENTIALS_ERROR,
                }));

                if (error.response) {
                    if (error.response.status === 401) {
                        errorMsg = INVALID_CREDENTIALS_ERROR;
                    }
                  } else {
                    errorMsg = INVALID_CREDENTIALS_ERROR;
                  }             

                throw new Error(errorMsg);
            })
            .finally(() => {
                setSubmitting(false);
            });

        const toastObj = {
            title: 'Entrando...',
            description: 'Por favor, aguarde.',
            duration: 5000,
            isClosable: true,
        }

        toast.promise(loginPromise, {
            loading: toastObj,
            success: ({ message }) => ({
                ...toastObj,
                title: 'Login realizado',
                description: message,
            }),
            error: err => ({
                ...toastObj,
                title: 'Erro de login',
                description: err.message,
            }),
        });
    };


    return (
        <Card align='center'>
            <Formik
                initialValues={initialValues}
                validateOnBlur
                validationSchema={validationSchema}

                onSubmit={handleSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <CardBody>
                            <Stack mt='6' spacing='5'>
                                <Center>
                                    <Image
                                        boxSize='150px'
                                        src='/logo.png'
                                        alt='Logo'
                                        mb={'2rem'}
                                    />
                                </Center>
                                <FormControl isInvalid={errors.email}>
                                    <InputGroup size='lg'>
                                        <Input
                                            type='email'
                                            name="email"
                                            isInvalid={errors.email}
                                            placeholder='Email'
                                            onChange={(e) => {
                                                handleChange(e)
                                            }}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.email && touched.email && errors.email}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={errors.password}>
                                    <InputGroup size='lg'>
                                        <Input
                                            isInvalid={errors.password}
                                            name="password"
                                            pr='4.5rem'
                                            type={hidePassw ? 'password' : 'text'}
                                            placeholder='Senha'
                                            onChange={(e) => {
                                                handleChange(e)
                                            }}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        <InputRightElement width='4.5rem'>
                                            <Icon
                                                as={hidePassw ? FaEye : FaEyeSlash}
                                                onClick={() => setHidePassw(!hidePassw)
                                                } />
                                        </InputRightElement>
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {errors.password && touched.password && errors.password}
                                    </FormErrorMessage>
                                </FormControl>
                                <Text fontSize='sm'>
                                    <Link href='/login/recover-password'>
                                        Esqueci minha senha <ExternalLinkIcon mx='2px' />
                                    </Link>
                                </Text>
                            </Stack>

                        </CardBody>
                        <CardFooter
                            justify='space-between'
                            alignItems={'center'}
                        >
                            <Button
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                type='submit'
                                size='lg'
                                w='100%'
                                h='50px'
                                variant='solid'
                                colorScheme='blue'
                            >
                                Entrar
                            </Button>
                        </CardFooter>
                    </form>
                )}
            </Formik>
        </Card>
    )
}

export default function LoginPage() {
    return (<Login />)
}