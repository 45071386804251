import { Department } from "@/lib/api/departments.types";

import { Avatar, Box, Card, CardBody, Checkbox, Flex, FormControl, Icon, Spacer, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { MdWork } from "react-icons/md";

type Props = {
    department: Department
    isChecked?: boolean
    onClick?: (department: Department) => void
    withIcon?: boolean
    withCheckBox?: boolean
}

export default function DepartmentItem({
    department,
    isChecked = false,
    onClick = () => { },
    withIcon = false,
    withCheckBox = false,
}: Props) {
    return (
        <Card>
            <CardBody>
                <Flex maxWidth='500px' alignItems='center' gap='2'>
                    {withIcon && <Avatar size="sm" bg='red.500' icon={<MdWork fontSize='1rem' />} />}
                    {withCheckBox && (
                        <Box p='2'><FormControl>
                            <Checkbox
                                isChecked={isChecked}
                                defaultChecked={isChecked}
                                onChange={(e) => {
                                    onClick && onClick(department)
                                }}
                            >
                                {department.description}
                            </Checkbox>
                        </FormControl>
                        </Box>
                    )}
                    <Box>
                        {!withCheckBox && <Text>{department.description}</Text>}
                    </Box>
                </Flex>
            </CardBody>
        </Card>

    )
}