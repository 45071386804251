import { Box, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ChatConversation from "./chatConversation";
import ChatConversations from "./chatConversations";
import { SAFE_AREA_TOP_CSS } from "@/app/config";

type Props = {
    onClose?: () => void
    title?: string
}

export default function ChatModal({ onClose = () => { }, title = 'Mensagens' }: Props) {
    const { conversationID } = useParams();

    const conversationIsOpen = !!conversationID;

    return (<Modal
        size={{
            base: 'full',
        }}
        isOpen={true}
        onClose={() => {
            onClose();
        }}
        motionPreset='slideInRight'
    >
        <ModalOverlay />
        <ModalContent pt={SAFE_AREA_TOP_CSS}>
            <ModalHeader>{!conversationIsOpen && title}</ModalHeader>
            <ModalCloseButton
                position={'fixed'}
                top={`calc(1rem+${SAFE_AREA_TOP_CSS})`}
                right={'1rem'}
                size='lg'
                zIndex={2}
            />
            <Box p={4}>
                {conversationIsOpen ? <ChatConversation /> : <ChatConversations />}
            </Box>
        </ModalContent>
    </Modal>)
}