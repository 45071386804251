import { useEffect, useRef } from "react";

type Props = {
    onEndOfPageReach: () => void;
}

export default function EndPageDetector({ onEndOfPageReach }: Props) {
    const endPageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        onEndOfPageReach();
                    }
                });
            },
            {
                threshold: 0.1 // Define a porcentagem do elemento que deve estar visível para acionar o callback
            }
        );

        if (endPageRef.current) {
            observer.observe(endPageRef.current);
        }

        return () => {
            if (endPageRef.current) {
                observer.unobserve(endPageRef.current);
            }
        };
    }, [onEndOfPageReach]);

    return <div ref={endPageRef}></div>;
}