import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { FormControl, Input, InputGroup, InputLeftElement, InputRightElement, Stack } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useEffect } from "react";

type SearchBarProps = {
    onSearch: (search: string) => void;
    value: string;
}

export default function SearchBar({ onSearch, value }: SearchBarProps) {
    const initialValues = {
        search: value,
    }

    useEffect(() => {
        if(!value){
            onSearch('')
        }
    }, [value])

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => {
                onSearch(values.search)
            }}
        >
            {({values, handleSubmit, setFieldValue}) => (
                <Stack spacing={4} as={Form}>
                    <FormControl
                    >
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <SearchIcon color='gray.300' />
                            </InputLeftElement>
                            <Input
                                value={values.search}
                                as={Field}
                                onBlur={() => {
                                    handleSubmit()
                                }}
                                id='search'
                                name='search'
                                type='text'
                                placeholder='Pesquisa'
                            />
                            <InputRightElement
                                sx={{
                                    cursor: 'pointer',
                                }}
                            >
                                <CloseIcon
                                    onClick={() => {
                                        setFieldValue('search', '')
                                        onSearch('')
                                    }}
                                    color='gray.300'
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </Stack>
            )}
        </Formik>
    )
}