import { useChatContext } from "@/contexts/chatContext";
import { Box, VStack } from "@chakra-ui/react";
import ConversationCard from "./conversationCard";
import NewConversationButton from "./newConversationButton";


export default function ChatConversations() {
    const { conversations } = useChatContext();
    const orderedConversations = conversations.sort((a, b) => {
        return new Date(b.last_message.created_at).getTime() - new Date(a.last_message.created_at).getTime();
    });
    return (
        <Box width={'100%'}>
            <VStack
                spacing={4}
                align="stretch"
            >
                {orderedConversations.map(conversation => {
                    return (
                        <ConversationCard
                            key={conversation.id}
                            p={2}
                            conversation={conversation}
                        />
                    )
                })}
            </VStack>
            <NewConversationButton />
        </Box>
    )
};
