'use client';
import {
  Flex
} from '@chakra-ui/react';
import NotificationModal from '../notifications/notificationModal';
import ClinicSwitchMenu from './clinicSwitch';
import MessagesIconButton from './messagesIconButton';

type Props = {
  secondary: boolean;
  onOpen: () => void;
  fixed: boolean;
}
export default function NavbarItems(props: Props) {
  const { secondary } = props;

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      gap={2}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
    >
      <ClinicSwitchMenu />
      <NotificationModal />
      <MessagesIconButton />
    </Flex>
  );
}