"use client";
import { routes } from '@/app/routes';
import { Progress } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function TasksPage() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(routes.MY_TASKS)
    })
    return <Progress isIndeterminate />
}