import { Me, UserClinicsDepartments } from "@/lib/api/user.types"
import { UpDownIcon } from "@chakra-ui/icons"
import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, IconButton, Box, useColorModeValue } from "@chakra-ui/react"
import ClinicSwitchSelect from "../clinicSwitch/clinicSwitchSelect"
import { IRootState, useDispatch, useSelector } from "@/lib/redux/store"
import { AuthStateProps, setAuthState } from "@/lib/redux/slices/auth/slice"
import { SAFE_AREA_TOP_CSS } from "@/app/config"

type Props = {
  alwaysShow?: boolean
  disabled?: boolean
}

export default function ClinicSwitch({ alwaysShow = false, disabled = false }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()

  const navbarIcon = useColorModeValue('gray.600', 'white');
  
  const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
  const userProfile = auth.userInfo
  const userClinic = auth.userClinic
  if (!userProfile?.clinics) return <></>


  const ClinicSwitchComponent = () => (
    <ClinicSwitchSelect
      disabled={disabled}
      value={userClinic && userProfile.clinics.find((c) => c.clinic.id === userClinic.clinic.id)}
      userClinicDepartments={userProfile.clinics}
      onChange={(clinic) => {
        dispatch(setAuthState({
          ...auth,
          userClinic: clinic
        }))
      }}
    />
  )

  if (alwaysShow) return (
    <ClinicSwitchComponent />
  )

  return (
    <>
      <Box
        display={{ base: "none", md: "block" }}
        mr={2}
      >
        <ClinicSwitchComponent />
      </Box>
      <Box
        display={{ base: "block", md: "none" }}
      >
        <UpDownIcon onClick={onOpen} mr={5}/>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent pt={SAFE_AREA_TOP_CSS}>
            <ModalHeader>Selecione sua clínica</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ClinicSwitchComponent />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Ok
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}

