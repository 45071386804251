export type Id = number | string

export type FileUpload = {
    id?: Id,
    original_name: string
    extension: string
    type?: string;
    size: number
    progress: number
    url?: string
    base64: string
    bucket?: string;
    task_id?: Id;
    created_at?: string;
    updated_at?: string;
    created_by?: Id;
    updated_by?: Id;
    path: string,
    content?: File
}

export const emptyFileUpload: FileUpload = {
    original_name: '',
    size: 0,
    extension: '',
    progress: 0,
    url: '',
    base64: '',
    bucket: '',
    task_id: 0,
    created_at: '',
    updated_at: '',
    created_by: 0,
    updated_by: 0,
    path: ''
}

export type ViewType = 'grid' | 'list'

export const enums = {
    VIEW_TYPE_GRID: 'grid' as ViewType,
    VIEW_TYPE_LIST: 'list' as ViewType
}

export const bottomBarHeight = '5rem'

declare global {
    interface Window {
        Echo: any; // Add the appropriate type if you have typings for Echo
        Pusher: any;
    }
}
