'use client';
import {
  Box,
  Center,
  Grid,
  IconButton,
  Tooltip,
  useColorModeValue,

} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { useCallback } from 'react';

export default function BottomBarItems(props) {
  const { pathname } = useLocation();

  let activeIcon = useColorModeValue('brand.500', 'blue.400');
  let textColor = useColorModeValue('secondaryGray.500', 'white');

  const activeRoute = useCallback(
    (routeName) => {
      return pathname?.includes(routeName);
    },
    [pathname],
  );

  const { items = [] } = props;
  return (
    <Grid templateColumns='repeat(5, 1fr)' gap={2}>
      {items.map((item, index) => (
        <Box key={index}>
          <Center>
            <Link
              key={index}
              to={item.path ? item.path : ''}
            >
              <IconButton
                variant='ghost'
                width={'100%'}
                size={'lg'}
                color={activeRoute(item.path.toLowerCase())
                  ? activeIcon
                  : textColor}
                icon={item.icon}
              />
            </Link>
          </Center>
        </Box>
      ))}
    </Grid>
  );
}