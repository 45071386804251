'use client'
import {
  Box,
  Center,
  Flex,
  useColorModeValue
} from '@chakra-ui/react'

import BottomBarItems from './bottomBarItems'

export default function BottomBar(props) {
  const { secondary, items = [], minH } = props;
  let navbarBg = useColorModeValue(
    'gray.100', 'gray.900'
  )


  return (
    <Box
      minH={minH}
      position={'fixed'}
      boxShadow={'none'}
      bg={navbarBg}
      borderColor={'transparent'}
      filter={'none'}
      backdropFilter={'blur(20px)'}
      backgroundPosition='center'
      backgroundSize='cover'
      shadow='0px 7px 23px rgba(0, 0, 0, 0.2)'
      borderWidth='1.0px'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      justifyContent={{ xl: 'center' }}
      lineHeight='25.6px'
      mx='auto'
      mt={'0px'}
      pb='8px'
      px={{
        base: '10px',
        sm: '30px',
      }}
      ps={{
        xl: '12px'
      }}
      pt='8px'
      right={{ base: '0px' }}
      bottom={{ base: '0px' }}
      w={{
        base: '100vw',
        xl: 'calc(100vw - 365px)',
        '2xl': 'calc(100vw - 365px)'
      }}
    >
      <Flex
        w='100%'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Box w='100%' >
          <BottomBarItems
            items={items}
            me="10px"
            _hover={{ cursor: 'pointer' }}
            onOpen={props.onOpen}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Box>
  )
}