import { DATES } from "@/utils/enums";
import { colorByDueDate } from "@/utils/translators";
import { Icon, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import moment from "moment";
import { FaRegFlag } from "react-icons/fa6";
type Props = {
    due_date: string
    onlyIcon?: boolean
    isDone?: boolean
}
export default function DueDateBadge({ due_date, onlyIcon = false, isDone = false }: Props) {
    {
        if (onlyIcon) {
            return <Icon as={FaRegFlag} color={!isDone ? colorByDueDate(due_date) : 'gray' }/>
        }
        return (
            <Tag size='md' colorScheme={!isDone ? colorByDueDate(due_date) : 'gray'} borderRadius='full'>
                <TagLeftIcon boxSize='12px' as={FaRegFlag} />
                {<TagLabel>{moment(due_date).format(DATES.DEFAULT_DATE_FORMAT)}</TagLabel>}
            </Tag>
        )
    }
}