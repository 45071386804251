import { routes } from '@/app/routes';
import { useChatContext } from '@/contexts/chatContext';
import { Box, Icon } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { MdMessage } from 'react-icons/md';

export default function MessagesIconButton() {
    const { conversations } = useChatContext();
    const newMessages = conversations.filter(c => c.unread_messages_count);
    const newMessagesCount = newMessages.reduce((acc, c) => acc + c.unread_messages_count, 0);
    return (
        <Box
            position="relative"
            as={Link}
            to={routes.MESSAGES}
        >
            <Icon
                mt="6px"
                as={MdMessage}
                w="18px"
                h="18px"
                me="10px"
            />

            {newMessages.length > 0 && (
                <Box
                    position="absolute"
                    bottom="0"
                    right="0"
                    bg="red"
                    color="white"
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                    fontSize="8px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding="2px"
                >
                    {newMessagesCount}
                </Box>
            )}
        </Box>
    )
}
