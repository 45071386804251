import { BACKEND_URL } from "@/app/config";
import axios from "axios";

export const instance = axios.create({
    baseURL: BACKEND_URL
});

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            if (!window.location.pathname?.includes("/login")) {
                localStorage.clear();
                window.location.href = "/login";
                return;
            }
        }
        return Promise.reject(error);
    }
);