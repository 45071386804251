import { Id } from "@/app/types"
import { UserClinicsDepartments } from "@/lib/api/user.types"
import { Select } from "chakra-react-select"

type Props = {
    userClinicDepartments: UserClinicsDepartments[]
    onChange: (clinic: UserClinicsDepartments) => void
    value: UserClinicsDepartments
    disabled?: boolean
}

const ALL_CLINICS = {
    value: 0,
    label: 'Todas as clínicas'
}

export default function ClinicSwitchSelect({
    userClinicDepartments = [],
    onChange,
    value,
    disabled = false
}: Props) {
    return (
        <Select
            isReadOnly={disabled}
            value={value ? { value: value.clinic.id, label: value.clinic.name, } : ALL_CLINICS}
            onChange={(value) => {
                if (value) {
                    onChange(userClinicDepartments.find(
                        (clinic) => clinic.clinic.id === value.value as Id
                    ))
                }
            }}
            isDisabled={userClinicDepartments.length === 0}
            placeholder='Selecione a clínica'
            options={[ALL_CLINICS, ...userClinicDepartments.map((clinic) => {
                return {
                    value: clinic.clinic.id,
                    label: clinic.clinic.name,
                }
            })]}
        ></Select>
    )
}