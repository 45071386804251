"use client"
import TasksList from "@/components/tasks/tasksList";
import { TaskFiltersType } from "@/contexts/taskFiltersContext";
import { requestGetAllTasks } from "@/lib/api/task";
import { laravelNextPage, mergeLaravelReactQueryPages } from "@/lib/api/utils";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState, useSelector } from "@/lib/redux/store";
import { Tabs, TabPanels, TabPanel } from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export default function AllTasksPage() {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const clinicId = auth.userClinic?.clinic?.id ?? null;

    const [pageIsReady, setPageIsReady] = useState(false);
    const [filters, setFilters] = useState<TaskFiltersType>(null);

    useEffect(() => {
        setPageIsReady(true);
    }, [])

    const {
        data,
        fetchNextPage,
        isFetching,
        isFetchingNextPage,
        error: _error,
        status: _status,
        hasNextPage: _hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['tasks_all', clinicId, pageIsReady, filters],
        initialPageParam: 1,
        queryFn: async ({ pageParam = 1 }) => (await requestGetAllTasks(clinicId, { ...filters, page: pageParam }))?.data,
        getNextPageParam: (lastPage) => {
            const nextPage = laravelNextPage(lastPage.next_page_url)
            return nextPage > lastPage.current_page ? nextPage : null
        },
        enabled: pageIsReady,
        staleTime: 10000,
    })

    const paginatedResponse = mergeLaravelReactQueryPages(data);

    return (
        <Tabs>
            <TabPanels>
                <TabPanel >
                    <TasksList
                        withFilters
                        tasksPaginated={paginatedResponse}
                        isLoading={isFetching && !data?.pages?.length}
                        isLoadingMore={isFetchingNextPage}
                        onSetFilters={setFilters}
                        onNextPage={fetchNextPage}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs >

    )
}