'use client'

import { routes } from "@/app/routes";
import { Id } from "@/app/types";
import { requestCreateService, requestGetService, requestUpdateService } from "@/lib/api/services";
import { CreateServiceRequest, UpdateServiceRequest, createServiceRequestInitialValues } from "@/lib/api/services.types";
import { Stack, FormControl, FormErrorMessage, InputGroup, Input, Button, Box, useToast, Progress, Heading, FormLabel } from "@chakra-ui/react";
import { Formik } from "formik";
import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState } from "react";

import * as Yup from 'yup';

export default function AddOrEditService() {
    const { id } = useParams();
    const isEdit = !!id;
    const [initialValues, setInitialValues] = useState<CreateServiceRequest | UpdateServiceRequest>(createServiceRequestInitialValues)
    const [isLoading, setIsLoading] = useState(isEdit ? true : false)
    const navigate = useNavigate();
    const toast = useToast();

    const validationRules = Yup.object(
        {
            name: Yup.string().required('O nome do serviço é obrigatório'),
            code: Yup.string().required('O código do serviço é obrigatório'),
        }
    );


    useEffect(() => {
        if (isEdit) {
            requestGetService(id as Id).then((response) => {
                setInitialValues(response.data as UpdateServiceRequest)
            }).catch(err => {
                toast({
                    title: 'Erro ao buscar serviço',
                    status: 'error',
                })
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [id])

    if (isLoading) {
        return <Progress isIndeterminate />
    }
    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validateOnBlur
            validationSchema={validationRules}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                if (isEdit) {
                    requestUpdateService(id as Id, values as UpdateServiceRequest).then(({ data }) => {
                        toast({
                            title: 'Serviço atualizado com sucesso',
                            status: 'success',
                        })
                        navigate(routes.SERVICES)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao atualizar serviço',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                } else {
                    requestCreateService(values as CreateServiceRequest).then(({ data }) => {
                        toast({
                            title: 'Serviço criado com sucesso',
                            status: 'success',
                        })
                        navigate(routes.SERVICES)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao criar serviço',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                }
            }}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Box>
                    <Heading size='md' mb={4}> {isEdit ? "Edição de serviço" : "Cadastro de serviço"}</Heading>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing='5'>
                            <FormControl isInvalid={touched.code && !!errors.code}>
                                <FormLabel>Código</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='code'
                                        name="code"
                                        isInvalid={touched.code && !!errors.code}
                                        placeholder='RX'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.code} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.code && !!errors.code && errors.code}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.name && !!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='name'
                                        name="name"
                                        isInvalid={touched.name && !!errors.name}
                                        placeholder='Nome'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.name && !!errors.name && errors.name}
                                </FormErrorMessage>
                            </FormControl>


                        </Stack>
                        <Button
                            isDisabled={isSubmitting || !isValid}
                            isLoading={isSubmitting}
                            type='submit'
                            mt={10}
                            width={'100%'}
                            colorScheme='blue'
                        >
                            {isEdit ? 'Salvar' : 'Criar'}
                        </Button>
                    </form>
                </Box>
            )}
        </Formik>
    )
}