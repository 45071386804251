"use client"
import TasksList from "@/components/tasks/tasksList";
import { useAuthContext } from "@/contexts/authContext";
import { TaskFiltersType } from "@/contexts/taskFiltersContext";
import { requestGetAssignedToMeTasks, requestGetCreatedByMeTasks, requestGetUnassignedTasks } from "@/lib/api/task";
import { laravelNextPage, mergeLaravelReactQueryPages } from "@/lib/api/utils";
import { TASK_FILTERS } from "@/utils/enums";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import {
    useInfiniteQuery,
} from '@tanstack/react-query';
import { useEffect, useState } from "react";


export default function UnfinishedTasksPage() {
    const {token,  userClinic, activeFiltersTasksTabRef, setActiveFiltersTasksTabRef} = useAuthContext();
    const [pageIsReady, setPageIsReady] = useState(false);
    const [filters, setFilters] = useState<TaskFiltersType>(null);

    const tabs = [
        {
            title: 'Minhas tarefas',
            ref: TASK_FILTERS.ASSIGNED_TO_ME
        },
        {
            title: 'Em aberto',
            ref: TASK_FILTERS.UNASSIGNED
        },
    ];

    const clinicId = userClinic?.clinic?.id ?? null;
    const taskType = activeFiltersTasksTabRef ?? TASK_FILTERS.ASSIGNED_TO_ME;
    const activeTabIndex = tabs.findIndex(tab => tab.ref === taskType) ?? 0;

    const getTaskRequest = () => {
        switch (taskType) {
            case TASK_FILTERS.ASSIGNED_TO_ME:
                return requestGetAssignedToMeTasks;
            case TASK_FILTERS.CREATED_BY_ME:
                return requestGetCreatedByMeTasks;
            case TASK_FILTERS.UNASSIGNED:
                return requestGetUnassignedTasks;
            default:
                return requestGetUnassignedTasks;
        }
    }

    useEffect(() => {
        setPageIsReady(true);
    }, [token])


    const {
        data,
        fetchNextPage,
        isFetching,
        isFetchingNextPage,
        error: _error,
        status: _status,
        hasNextPage: _hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['tasks', clinicId, pageIsReady, taskType, filters],
        initialPageParam: 1,
        queryFn: async ({ pageParam = 1 }) => (await (getTaskRequest())(clinicId, { ...filters, page: pageParam }))?.data,
        getNextPageParam: (lastPage) => {
            const nextPage = laravelNextPage(lastPage.next_page_url)
            return nextPage > lastPage.current_page ? nextPage : null
        },
        enabled: pageIsReady,
        staleTime: 10000,
    })

    const paginatedResponse = mergeLaravelReactQueryPages(data);

    return (
        <>
            <Tabs
                index={activeTabIndex}
                isFitted
                variant='solid-rounded'
                colorScheme='blue'
            >
                <TabList>
                    {tabs.map((tab, index) => (
                        <Tab
                            onClick={() => {
                                setActiveFiltersTasksTabRef(tab.ref)
                            }}
                            borderRadius={5} key={index}>{tab.title}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {tabs.map((_, index) => (
                        <TabPanel key={index}>
                            <TasksList
                                tasksPaginated={paginatedResponse}
                                isLoading={isFetching && !data?.pages?.length}
                                isLoadingMore={isFetchingNextPage}
                                onSetFilters={setFilters}
                                onNextPage={fetchNextPage}
                            />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </>
    )
}