"use client"
import { requestSendDeletionRequest } from '@/lib/api/deletion';
import { Button, Card, CardBody, ChakraProvider, FormControl, FormErrorMessage, Heading, Input, Text, useToast } from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styles from "@/app/page.module.css";


export default function RequestEmailDeletionPage() {
    const toast = useToast();

    const initialValues = { email: '' };

    const validationSchema = Yup.object({
        email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório')
    });

    return (
        <ChakraProvider
            toastOptions={{ defaultOptions: { position: 'top' } }}
        >
            <main className={styles.login}>
                <Card w="100%" maxW="md" align='center' p={4} display="flex" justifyContent="center" alignItems="center">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            requestSendDeletionRequest(values.email) // Função para enviar a solicitação
                                .then(({ data }) => {
                                    toast({
                                        title: data.status,
                                        status: 'success',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                })
                                .catch((err) => {
                                    let errorMsg = 'Erro ao enviar solicitação de exclusão';
                                    if (err.response.status === 400) {
                                        errorMsg = err.response.data.status;
                                    }
                                    toast({
                                        title: errorMsg,
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <CardBody>
                                    <Heading size="md" mb={4}>Solicitação de Exclusão de E-mail</Heading>
                                    <Text mb={6}>
                                        Insira seu e-mail abaixo e enviaremos uma solicitação para excluir sua conta.
                                    </Text>

                                    <FormControl isInvalid={!!errors.email} mb={4}>
                                        <Input
                                            type='email'
                                            name="email"
                                            placeholder='Digite seu e-mail'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            size="lg"
                                        />
                                        <FormErrorMessage>
                                            {errors.email && touched.email && errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <Button
                                        isLoading={isSubmitting}
                                        disabled={isSubmitting}
                                        type='submit'
                                        size='lg'
                                        w='100%'
                                        variant='solid'
                                        colorScheme='red' // Cor diferente para a exclusão
                                    >
                                        Solicitar Exclusão
                                    </Button>
                                </CardBody>
                            </form>
                        )}
                    </Formik>
                </Card>
            </main>
        </ChakraProvider>
    );
}
