import moment from "moment"
import { FaFileExcel, FaFileImage, FaRegFilePdf } from "react-icons/fa6"

export const priorityMap = {
    'low': {
        text: 'Baixa',
        color: 'whatsapp'
    },
    'medium': {
        text: 'Média',
        color: 'yellow'
    },
    'high': {
        text: 'Alta',
        color: 'orange'
    },
    'urgent': {
        text: 'Urgente',
        color: 'red'
    },
    'critical': {
        text: 'Crítico',
        color: 'black'
    },
}

export const priorities = [
    'low',
    'medium',
    'high',
    'urgent'
]

export default function priorityText(priority: string) {
    return priorityMap[priority]?.text
}

export function priorityColor(priority: string) {
    return priorityMap[priority]?.color
}

const fileExtensionMap = {
    'xls': {
        'icon': FaFileExcel,
        'color': 'green'
    },
    'xlsx': {
        'icon': FaFileExcel,
        'color': 'green'
    },
    'pdf': {
        'icon': FaRegFilePdf,
        'color': 'green'
    },
    'png': {
        'icon': FaFileImage,
        'color': 'red.400'
    },
    'jpg': {
        'icon': FaFileImage,
        'color': 'red.400'
    },
    'jpeg': {
        'icon': FaFileImage,
        'color': 'red.400'
    },
    'gif': {
        'icon': FaFileImage,
        'color': 'red.400'
    },
    'bmp': {
        'icon': FaFileImage,
        'color': 'red.400'
    },
}

export function fileExtensionIcon(format: string) {
    return fileExtensionMap[format]?.icon
}

export function fileExtensionColor(format: string) {
    return fileExtensionMap[format]?.color
}

export function colorByDueDate(dueDate: string) {
    const dueDateIsExpired = moment(dueDate).isBefore(moment());
    const dueDateIsToday = moment(dueDate).isSame(moment(), 'day');
    const dueDateIsTomorrow = moment(dueDate).isSame(moment().add(1, 'day'), 'day');

    const duteDateTagColor = dueDateIsExpired ? 'red' : dueDateIsToday ? 'orange' : dueDateIsTomorrow ? 'green' : 'gray';
    return duteDateTagColor;
}

export const TASK_ASSIGNEE_STATUS_ASSIGNED = 'assigned'
export const TASK_ASSIGNEE_STATUS_DOING = 'doing'
export const TASK_ASSIGNEE_STATUS_DONE = 'done'
export const TASK_ASSIGNEE_STATUS_CANCELLED = 'cancelled'

export function taskAssigneeStatus(status: string) {
    switch (status){
        case TASK_ASSIGNEE_STATUS_ASSIGNED:
            return 'Atribuído'
        case TASK_ASSIGNEE_STATUS_DOING:
            return 'Fazendo'
        case TASK_ASSIGNEE_STATUS_DONE:
            return 'Finalizado'
        case TASK_ASSIGNEE_STATUS_CANCELLED:
            return 'Cancelado'
        default:
            return 'Não atribuído'
    }
}

export const taskStatusMap = {
    'new': 'Em aberto',
    'pending': 'Atribuído',
    'in_progress': 'Em andamento',
    'done': 'Finalizado',
    'cancelled': 'Cancelado',
    'reopened': 'Reaberta'
}

export function taskStatus(status: string) {
    return taskStatusMap[status]
}

const permissionsMap = {
    "clinic-tasks-read": "Visualizar tarefas",
    "clinic-tasks-create": "Criar tarefas",
    "app-settings-profile": "Perfil",
    "app-settings-user-read": "Visualizar Usuários",
    "app-settings-user-create": "Criação de Usuários",
    "app-settings-department-read": "Visualizar Departamentos",
    "app-settings-department-create": "Criação de Departamentos",
    "app-settings-clinic-read": "Visualizar Clínicas",
    "app-settings-clinic-create": "Criação de Clínicas",
    "app-settings-service-read": "Visualizar Serviços",
    "app-settings-service-create": "Criação de Serviços",
}

export function permissionText(permission: string) {
    return permissionsMap[permission]
}