import { Id } from "@/app/types"
import { User } from "./user.types"
import { Task } from "./task.types"
import moment from "moment"
import 'moment/locale/pt-br';
import { routes } from "@/app/routes";
import { Message } from "./messages.types";


export type TaskNotification = {
    message: string
    user: User
    task: Task
}

export type NewMessageNotification = {
    message: string
    user: User
    chatMessage: Message
}

export type Notification = {
    id: Id
    type: string
    notifiable_type: string
    notifiable_id: Id
    data: TaskNotification | NewMessageNotification| null
    read_at: string | null
    created_at: string
    updated_at: string
}

export type FormattedNotification = {
    id: Id
    message: string
    url: string
    user: User
    date: string
}

export const formattedNotification = (notification: Notification) => {
    if (!!(notification.data as TaskNotification).task) {
        const data: TaskNotification = notification.data as TaskNotification
        const formattedDate = moment(notification.created_at).fromNow(true)

        return {
            id: notification.id,
            message: data.message,
            url: `${routes.TASKS}/${data.task.id}`,
            user: data.user,
            date: formattedDate
        }
    }

    if (!!(notification.data as NewMessageNotification).chatMessage) {
        const data: NewMessageNotification = notification.data as NewMessageNotification
        const formattedDate = moment(notification.created_at).fromNow(true)

        return {
            id: notification.id,
            message: data.message,
            url: `${routes.MESSAGES}/${data.chatMessage.conversation_id}`,
            user: data.user,
            date: formattedDate
        }
    }
}