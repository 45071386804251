import { Comment } from "@/lib/api/comment.types";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import CommentItem from "./commentIItem";
import { useEffect, useRef } from "react";
import CommentAddButton from "./commentAddButton";
import { Id } from "@/app/types";
import { useTaskDetailsContext } from "@/contexts/taskDetailsContext";
import { SAFE_AREA_TOP_CSS } from '../../app/config';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  comments: Comment[];
  onRefresh: () => Promise<any>;
  onError: (error: string) => void;
}

export default function TaskCommentsModal({ comments, isOpen, onClose, onRefresh, onError }: Props) {
  const toast = useToast();

  const { task } = useTaskDetailsContext();

  const scrollToComponent = useRef<HTMLDivElement>(null);
  const { onToggle: onNewCommentAdded } = useDisclosure();

  useEffect(() => {
    if (scrollToComponent.current) {
      scrollToComponent.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [onNewCommentAdded]);


  return (
    <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent pt={SAFE_AREA_TOP_CSS}>
        <ModalHeader>Comentários</ModalHeader>
        <ModalCloseButton
          position='fixed'
          top='20px'
          zIndex={3}
        />
        <ModalBody>
          {comments.map(comment => {
            const isReply = !!comment.task_comment_id;
            if (isReply) return;

            return (
              <CommentItem
                key={comment.id}
                comment={comment}
                onNewComment={(feedback, id) => {
                  onRefresh().then(() => {
                    setTimeout(() => {
                      scrollToComponent.current = document.getElementById(`comment-${id}`) as HTMLDivElement;
                      toast({
                        title: feedback,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                      })
                      onNewCommentAdded();
                    }, 400);
                  })
                }}
                onError={onError}
              />
            )
          })}

        </ModalBody>
        <ModalFooter id={'bottom'}>
          <CommentAddButton
            taskId={task.id}
            onCommentAddError={(error) => {
              toast({
                title: error,
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
            }}
            onCommentAdded={(feedback, id) => {
              onRefresh().then(() => {
                setTimeout(() => {
                  scrollToComponent.current = document.getElementById(`comment-${id}`) as HTMLDivElement;
                  toast({
                    title: feedback,
                    status: 'success',
                    duration: 1000,
                    isClosable: false,
                  })
                  onNewCommentAdded();
                }, 400);
              })
            }}
          />
          <Button />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}