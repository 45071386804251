"use client";

import { useEffect, useState } from 'react';
import ProfileSettingsPage from './profile/page';

export default function SettingsPage() {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    if (!isClient) return null;
    return <ProfileSettingsPage/>
}

