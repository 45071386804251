"use client";

import { ChakraProvider } from "@chakra-ui/react";
import styles from "@/app/page.module.css";
import {
    useSelector,
} from "@/lib/redux/store"
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "@/app/routes";

export default function LoginLayout() {
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        if (auth.success) {
            navigate(routes.HOME);
        }
    });

    return (
        <ChakraProvider
            toastOptions={{ defaultOptions: { position: 'top' } }}
        >
            <main className={styles.login}>
                <Outlet />
            </main>
        </ChakraProvider>
    );
}
