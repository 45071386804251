'use client'

import { routes } from "@/app/routes";
import { Id } from "@/app/types";
import ClinicBadge from "@/components/badges/clinicBadge";
import LinkUserClinicModal, { LinkUserClinicModalType } from "@/components/settings/users/linkUserClinicModal";
import { requestGetPermissions } from "@/lib/api/permissions";
import { Permission } from "@/lib/api/permissions.types";
import { requestCreateUser, requestGetUser, requestUpdateUser } from "@/lib/api/user";
import { CreateUserRequest, UpdateUserRequest, UserClinicsDepartments, createUserRequestInitialValues } from "@/lib/api/user.types";
import { permissionText } from "@/utils/translators";
import { Stack, FormControl, FormErrorMessage, InputGroup, Input, InputRightElement, Icon, CardFooter, Button, Text, Box, HStack, useDisclosure, Link, VStack, Spacer, useToast, Progress, Heading, FormLabel, FormHelperText } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Formik } from "formik";
import {useNavigate, useParams} from "react-router-dom";

import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

import * as Yup from 'yup';

const defaultPermissions = [
    'app-settings-profile',
    'clinic-tasks-read',
    'clinic-tasks-create'
];

export default function AddOrEditUser() {
    const { id } = useParams();
    const isEdit = !!id;
    const [hidePassw, setHidePassw] = useState(true)
    const [workingUserClinic, setWorkingUserClinic] = useState<UserClinicsDepartments>({} as UserClinicsDepartments)
    const [linkUserModalType, setLinkUserModalType] = useState<LinkUserClinicModalType>('ADD')
    const [initialValues, setInitialValues] = useState<CreateUserRequest | UpdateUserRequest>(createUserRequestInitialValues)
    const [permissions, setPermissions] = useState<Permission[]>([])
    const [isLoading, setIsLoading] = useState(isEdit ? true : false)
    const { isOpen, onClose, onOpen } = useDisclosure();
    const navigate = useNavigate();
    const toast = useToast();

    const getValidationRules = () => {
        const rules = {
            name: Yup.string().required('O nome é obrigatório'),
            email: Yup.string().required('O e-mail é obrigatório').test(
                'is-email',
                'O e-mail deve ser válido',
                (value) => {
                    if (!value) return false
                    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)

                }
            ),
            clinics: Yup.array().min(1, 'É necessário selecionar pelo menos uma clínica'),
        }

        const passwordRule = Yup.string().required('A senha é obrigatória').min(8, 'A senha deve ter no mínimo 8 caracteres');
        const passwordConfirmationRule = Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais');

        const addRules = {
            ...rules,
            password: passwordRule,
            passwordConfirmation: passwordConfirmationRule
        };

        const editPasswordRule = Yup.string().test('is-edit-password', 'Senha inválida', function (value) {
            const password = this.resolve(Yup.ref('password')) as string;
            if (!!password && password?.length > 0) {
                return passwordRule.isValidSync(value);
            }
            return true;
        });

        const editPasswordConfirmationRule = Yup.string().when('password', (password, schema) => {
            return password && password.length > 0 ? passwordConfirmationRule : schema.notRequired();
        });

        const editRules = {
            ...rules,
            password: editPasswordRule,
            passwordConfirmation: editPasswordConfirmationRule
        };

        return Yup.object(
            isEdit ? editRules : addRules
        );
    }

    useEffect(() => {
        if (isEdit) {
            requestGetUser(id as Id).then((response) => {
                setInitialValues(response.data as UpdateUserRequest)
            }).catch(err => {
                toast({
                    title: 'Erro ao buscar usuário',
                    status: 'error',
                })
            }).finally(() => {
                setIsLoading(false)
            })
        }
        requestGetPermissions().then((response) => {
            setPermissions(response.data.filter((_permission)=>{
                return !defaultPermissions.includes(_permission.name)
            }))
        })
    }, [id])

    if (isLoading) {
        return <Progress isIndeterminate />
    }
    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validateOnBlur
            validationSchema={getValidationRules()}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                if (isEdit) {
                    requestUpdateUser(id as Id, values as UpdateUserRequest).then(({ data }) => {
                        toast({
                            title: 'Usuário atualizado com sucesso',
                            status: 'success',
                        })
                        navigate(routes.USERS)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao atualizar usuário',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                } else {
                    requestCreateUser(values as CreateUserRequest).then(({ data }) => {
                        toast({
                            title: 'Usuário criado com sucesso',
                            status: 'success',
                        })
                        navigate(routes.USERS)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao criar usuário',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                }
            }}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => (
                <Box>
                    <Heading size='md' mb={4}> {isEdit ? "Edição de usuário" : "Cadastro de usuário"}</Heading>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing='5'>
                            <FormControl isInvalid={touched.name && !!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='name'
                                        name="name"
                                        isInvalid={touched.name && !!errors.name}
                                        placeholder='Nome'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.name && !!errors.name && errors.name}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.email && !!errors.email}>
                                <FormLabel>E-mail</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='email'
                                        name="email"
                                        isInvalid={touched.email && !!errors.email}
                                        placeholder='email@provedor.com'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.email && !!errors.email && errors.email}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.password && !!errors.password}>
                                <FormLabel>Senha</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        isInvalid={touched.password && !!errors.password}
                                        name="password"
                                        pr='4.5rem'
                                        type={hidePassw ? 'password' : 'text'}
                                        placeholder='*****'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password} />
                                    <InputRightElement width='4.5rem'>
                                        <Icon
                                            as={hidePassw ? FaEye : FaEyeSlash}
                                            onClick={() => setHidePassw(!hidePassw)} />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.password && !!errors.password && errors.password}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.password_confirmation && !!errors.password_confirmation}>
                                <FormLabel>Confirmar senha</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        isInvalid={touched.password_confirmation && !!errors.password_confirmation}
                                        name="password_confirmation"
                                        pr='4.5rem'
                                        type={hidePassw ? 'password' : 'text'}
                                        placeholder='*****'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password_confirmation} />
                                    <InputRightElement width='4.5rem'>
                                        <Icon
                                            as={hidePassw ? FaEye : FaEyeSlash}
                                            onClick={() => setHidePassw(!hidePassw)} />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.password_confirmation && !!errors.password_confirmation && errors.password_confirmation}
                                </FormErrorMessage>
                            </FormControl>
                            <Box>
                                <HStack spacing={4} my={2}>
                                    {values.clinics?.map((clinic, index) => (
                                        <ClinicBadge
                                            size='lg'
                                            colorScheme='gray'
                                            key={index}
                                            clinic={clinic}
                                            onDelete={() => {
                                                setFieldValue('clinics', values.clinics?.filter((c) => c.clinic.id !== clinic.clinic.id));
                                            }}
                                            onEdit={() => {
                                                setWorkingUserClinic(clinic);
                                                setLinkUserModalType('EDIT');
                                                onOpen();
                                            }} />
                                    )
                                    )}
                                </HStack>
                                <LinkUserClinicModal
                                    ignoreIds={values.clinics?.map((c) => c.clinic.id)}
                                    onNewClinicButtonClick={() => {
                                        setWorkingUserClinic({} as UserClinicsDepartments);
                                        setLinkUserModalType('ADD');
                                    }}
                                    isOpen={isOpen}
                                    onClose={onClose}
                                    onOpen={onOpen}
                                    defaultUserClinic={workingUserClinic}
                                    buttonLabel="Vincular clínica"
                                    onSave={(savedUserClinic: UserClinicsDepartments) => {
                                        const existingUserClinic = values.clinics?.find(
                                            (userClinic) => userClinic.clinic.id === savedUserClinic.clinic.id
                                        );
                                        if (existingUserClinic) {
                                            setFieldValue('clinics', values.clinics?.map((userClinic) => {
                                                if (userClinic.clinic.id === savedUserClinic.clinic.id) {
                                                    return savedUserClinic;
                                                }
                                                return userClinic;
                                            }));
                                        }
                                        else {
                                            setFieldValue('clinics', [...values.clinics, savedUserClinic]);
                                        }
                                        setWorkingUserClinic({} as UserClinicsDepartments);
                                    }}
                                    modalType={linkUserModalType}
                                />
                            </Box>
                            <FormControl>
                                <FormLabel>Permissões adicionais</FormLabel>
                                <Select
                                    isMulti
                                    placeholder='Defina as permissões do usuário'
                                    value={values.app_permissions?.map((pName) => {
                                            const _permission = permissions.find((p) => p.name === pName);
                                            return {
                                                value: _permission?.name,
                                                label: permissionText(_permission?.name)
                                            }
                                        })
                                        .filter((p) => !!p.value)
                                    }
                                    options={permissions
                                        .filter((permission) => !defaultPermissions?.includes(permission.name))
                                        .map((permission) => ({
                                        value: permission.name,
                                        label: !!permission.name ? permissionText(permission.name) : permission.name
                                    }))}
                                    onChange={(value) => {
                                        setFieldValue('app_permissions', value.map((v) => v.value));
                                    }}
                                />
                                <FormHelperText>Por padrão, todos os usuários poderão visualizar o próprio perfil e tarefas de suas clínicas e departamentos</FormHelperText>
                            </FormControl>
                        </Stack>
                        <Button
                            isDisabled={isSubmitting || !isValid}
                            isLoading={isSubmitting}
                            type='submit'
                            mt={10}
                            width={'100%'}
                            colorScheme='blue'
                        >
                            {isEdit ? 'Salvar' : 'Criar'}
                        </Button>
                    </form>
                </Box>
            )}
        </Formik>
    )
}