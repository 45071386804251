import { Box, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { FaPaperclip } from "react-icons/fa6";

type AttachmentCountBadge = {
    count: number
    onlyNumber: boolean
}
export default function AttachmentCountBadge({ count, onlyNumber }: AttachmentCountBadge) {
    return (
        <Tag size='lg' colorScheme='gray' borderRadius='full'>
            <TagLeftIcon as={FaPaperclip} />
            <TagLabel>{count}{!onlyNumber && ' anexos'}</TagLabel>
        </Tag>
    )
}