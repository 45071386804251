import { Me, UserClinicsDepartments } from '@/lib/api/user.types'
import { createSlice } from '@reduxjs/toolkit'
import { IRootState, useSelector } from '../../store'
import { TASK_FILTERS } from '@/utils/enums'

export type AuthStateProps = {
    loading: boolean,
    userInfo: Me,
    userClinic: UserClinicsDepartments | null,
    userToken: null,
    error: null,
    success: boolean,
    message: null,
    activeFiltersTasksTabRef: string
}
const initialState : AuthStateProps = {
    loading: false,
    userInfo: null,
    userClinic: null,
    userToken: null,
    error: null,
    success: false,
    message: null,
    activeFiltersTasksTabRef: TASK_FILTERS.ASSIGNED_TO_ME,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state, action) => {
            state.loading = action.payload.loading
            state.userInfo = action.payload.userInfo
            state.userToken = action.payload.userToken
            state.userClinic = action.payload.userClinic
            state.error = action.payload.error
            state.success = action.payload.success
            state.message = action.payload.message
            state.activeFiltersTasksTabRef = action.payload.activeFiltersTasksTabRef
        }
    },
})

export const authReducer = authSlice.reducer;
export const { setAuthState } = authSlice.actions;
// export const useAuth = () => useSelector<IRootState, AuthStateProps>(state => state.auth);
