import { Id } from "@/app/types";
import { instance } from "./api";
import { Clinic, CreateClinicRequest, UpdateClinicRequest } from "./clinic.types";

export async function requestGetClinics() {
    return await instance.get<Clinic[]>('/clinics');
}

export async function requestGetClinic(id: Id) {
    return await instance.get<Clinic>(`/clinics/${id}`);
}

export async function requestCreateClinic(body: CreateClinicRequest) {
    return await instance.post<Clinic>('/clinics', body);
}

export async function requestUpdateClinic(id: Id, body: UpdateClinicRequest) {
    return await instance.put<Clinic>(`/clinics/${id}`, body);
}