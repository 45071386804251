'use client'
import {
  Box,
  Flex,
  HStack,
  Heading,
  Spacer,
  useColorModeValue
} from '@chakra-ui/react'

import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom';

import { routes } from '@/app/routes'
import NavbarItems from './navbarItems'
import { SAFE_AREA_TOP_CSS } from '@/app/config';

type Props = {
  secondary?: boolean
  brandText?: string
  onOpen?: () => void
  fixed?: boolean
}

export default function Navbar(props: Props) {
  let navbarPosition = 'fixed'
  let navbarFilter = 'none'
  let navbarBackdrop = 'blur(20px)'
  let navbarShadow = 'none'
  let navbarBg = useColorModeValue(
    'gray.100', 'gray.900'
  )
  let navbarBorder = 'transparent'

  const { pathname } = useLocation();

  return (
    <Box
      position={navbarPosition as any}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition='center'
      backgroundSize='cover'
      shadow=' 0 8px 6px -6px rgba(0, 0, 0, 0.2)'
      borderWidth='1.0px'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      alignItems={{ xl: 'center' }}
      display={props.secondary ? 'block' : 'flex'}
      minH='75px'
      justifyContent={{ xl: 'center' }}
      lineHeight='25.6px'
      mx='auto'
      pb='8px'
      right={{ base: '0px' }}
      top={{ base: '0px' }}
      px={{
        base: '10px',
        sm: '30px',
      }}
      ps={{
        xl: '12px'
      }}
      pt={`calc(8px + ${SAFE_AREA_TOP_CSS})`}
      w={{
        base: '100vw',
        xl: 'calc(100vw - 5.7rem)',
        '2xl': 'calc(100vw - 5.7rem)',
      }}
    >
      <Flex
        w='100%'
        alignItems='center'
      >
        <HStack
          ml={5}
          spacing={2}
        >
          {pathname !== routes.TASKS && (
            <ChevronLeftIcon
              onClick={() => window.history.back()}
            />
          )}
          <Heading
            size='md'
          >
            {props.brandText}
          </Heading>
        </HStack>
        <Spacer />
        <Box>
          <NavbarItems
            onOpen={props.onOpen}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Box>
  )
}