import { Comment } from "@/lib/api/comment.types";
import { Box, Button, FormControl, FormErrorMessage, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure, useToast } from "@chakra-ui/react";

import { useTaskDetailsContext } from "@/contexts/taskDetailsContext";
import AttachmentList from "../attachments/attachmentList";
import FileLoad from "../fileUpload/fileLoad";
import { Attachment } from "@/lib/api/attatchment.types";
import { requestCreateAttachment, requestDownloadAttachment } from "@/lib/api/attachments";
import { FileUpload } from "@/app/types";
import { requestFinishTask } from "@/lib/api/task";
import { TaskFinishRequest } from "@/lib/api/task.types";
import { useState } from "react";
import { downloadAttachment, viewAttachment } from "@/lib/api/utils";
import { SAFE_AREA_TOP_CSS } from "@/app/config";


type Props = {
  isOpen: boolean;
  onClose: () => void;
  comments: Comment[];
  onFinish?: () => Promise<any>;
  onError: (error: string) => void;
}

export default function TaskFinishModal({ isOpen, onClose, onFinish, onError }: Props) {
  const { task, setTask } = useTaskDetailsContext();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const finishTask = () => {
    const payload: TaskFinishRequest = {
      id: task.id,
      comment: comment,
      attachments: task.attachments.map((attachment) => {
        return {
          ...attachment, base64: ''
        }
      }),
    }
    setLoading(true);
    requestFinishTask(task.id, payload).then(res => {
      setComment('');
      onFinish();
    }).catch(error => {
      onError(error.response.data.message)
    }).finally(() => {
      setLoading(false);
      onClose();
    })
  }

  function uploadFile(file: FileUpload) {
    requestCreateAttachment(file.content).then(res => {
      res.data.forEach((a: any) => {
        const updatedFile = { ...file, ...a }
        setTask({ ...task, attachments: [...task.attachments, updatedFile as Attachment] })
      })
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent pt={SAFE_AREA_TOP_CSS}>
        <ModalHeader>Finalizar tarefa</ModalHeader>
        <ModalCloseButton
          position='fixed'
          top='20px'
          zIndex={3}
        />
        <ModalBody>
          <Heading size='md'>Anexar arquivo</Heading>
          <FileLoad
            onFileLoad={uploadFile}
            onError={(error) => {
              toast({
                title: error,
                status: 'error',
              })
            }}
          />
          <AttachmentList
            hideHeader
            enableDelete
            enableProgress
            enableCheckIcon
            enableDownload={false}
            attachments={task.attachments}
            onDelete={(i) => {
              const removeAttachment = async () => { }
              removeAttachment().then(() => {
                setTask({ ...task, attachments: task.attachments.filter((a, index) => index !== i) })
              });

            }}
            onDownloadOrShare={(i) => { downloadAttachment(task.attachments[i])}}
            onView={(i) => { viewAttachment(task.attachments[i]) }}
          />
          <FormControl isInvalid={comment.length === 0}>
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder='Detalhes'
            size='md'
            mt={4}
            resize='none'
            minHeight={100}
            focusBorderColor='blue.500'
          />
          <FormErrorMessage>
            Campo obrigatório
          </FormErrorMessage>
          </FormControl>
          <Button
            isDisabled={comment.length === 0}
            isLoading={loading}
            colorScheme={'blue'}
            onClick={finishTask}
            mt={4}
            width={'100%'}
          >
            Finalizar
          </Button>
        </ModalBody>
        <ModalFooter id={'bottom'}>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}