'use client'

import ChatConversations from "@/components/messages/chatConversations";
import { useChatContext } from "@/contexts/chatContext";
import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

export default function MessagesLayout({ children }) {
    const { viewType } = useChatContext();
    return viewType === 'desktop' ? (
        <Flex h='75vh'>
            <Box
                w='35vw'
                overflowY='scroll'
            >
                <ChatConversations />
            </Box>
            <Box w='65vw' px={10}>
                <Box><Outlet/></Box>
            </Box>
        </Flex>
    ) : <Outlet/>

}