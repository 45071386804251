import { Id } from "@/app/types";
import { instance } from "./api";
import { CreateUserRequest, Me, UpdateUserRequest, User } from "./user.types";

export async function requestGetUsers() {
    return await instance.get<User[]>('/users');
}

export async function requestGetUsersForTask() 
{
    return await instance.get<User[]>('/users/task');
}

export async function requestGetUser(id: Id) {
    return await instance.get<User>(`/users/${id}`);
}

export async function requestGetMe(clinicId: Id | null = null) {
    let url = '/me'
    if (!!clinicId) {
        url += `?clinic_id=${clinicId}`
    }
    return await instance.get<Me>(url);
}

export async function requestToggleNotifications(checked) {
    return await instance.put<Me>(`/me/notifications/toggle`, {checked: checked});
}

export async function requestMarkAllNotificationsAsRead() {
    return await instance.put<Me>(`/me/notifications/mark-all-as-read`);
}

export async function requestMarkNotificationAsRead(notificationId: Id) {
    return await instance.put<Me>(`/me/notifications/${notificationId}/mark-as-read`);
}

export async function requestCreateUser(payload: CreateUserRequest) {
    return await instance.post<User>('/users', payload);
}

export async function requestUpdateUser(id: Id, payload: UpdateUserRequest) {
    if(!payload.password)
        delete payload.password
    return await instance.put<User>(`/users/${id}`, payload);
}