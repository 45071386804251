import { Box, Heading } from "@chakra-ui/react";
import AttachmentItem from "./attachmentItem";
import { Attachment } from "@/lib/api/attatchment.types";

type Props = {
    attachments?: Attachment[];
    title?: string;
    hideHeader?: boolean;
    enableDownload?: boolean;
    enableView?: boolean;
    enableDelete?: boolean;
    enableProgress?: boolean;
    enableCheckIcon?: boolean;
    onDownloadOrShare?: (i: number) => void;
    onView?: (i: number) => void;
    onDelete?: (i: number) => void;
}

export default function AttachmentList({
    attachments = [],
    title = "Anexos",
    hideHeader = false,
    enableDownload = true,
    enableView = true,
    enableDelete = false,
    enableProgress = false,
    enableCheckIcon = false,
    onDownloadOrShare: onDownloadOrShare = ((_i) => { }),
    onView: onView = ((_i) => { }),
    onDelete = ((_i) => { }),
}:Props) {
    return (
        <Box>
            {!hideHeader && <Heading mt={4} mb={2} size='sm'> {title} </Heading>}
            <Box p={2}>
                {attachments?.map((attachment, i) => (
                    <AttachmentItem
                        key={i}
                        attachment={attachment}
                        enableDownloadOrShare={enableDownload}
                        enableView={enableView}
                        enableDelete={enableDelete}
                        enableProgress={enableProgress}
                        onDownloadOrShare={() => onDownloadOrShare(i)}
                        onView={()=> onView(i)}
                        onDelete={() => onDelete(i)}
                        enableCheckIcon={enableCheckIcon}
                    />
                ))}
            </Box>
        </Box>

    )
}