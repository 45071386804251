import { Id } from "@/app/types";
import { instance } from "./api";
import { CreateDepartmentRequest, Department, UpdateDepartmentRequest } from "./departments.types";

export async function requestGetDepartments() {
    return await instance.get<Department[]>("/departments");
}

export async function requestGetDepartmentsForTask() {
    return await instance.get<Department[]>("/departments/task");
}

export async function requestGetDepartment(id: Id) {
    return await instance.get<Department>(`/departments/${id}`);
}

export async function requestCreateDepartment(body: CreateDepartmentRequest) {
    return await instance.post<Department>("/departments", body);
}

export async function requestUpdateDepartment(id: Id, body: UpdateDepartmentRequest) {
    return await instance.put<Department>(`/departments/${id}`, body);
}