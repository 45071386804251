import { ONE_SIGNAL_APP_ID, ONE_SIGNAL_SAFARI_WEB_ID } from '@/app/config';
import { Me } from '@/lib/api/user.types';
import OneSignal from 'react-onesignal';

const appId = ONE_SIGNAL_APP_ID;
const safariWebId = ONE_SIGNAL_SAFARI_WEB_ID;

export default async function runOneSignalWeb(user: Me) {
    function notificationDismissedListener(event) {
        console.log(`dismiss event: ${event}`);
    }


    function permissionChangeListener(permission) {
        if (permission) {
            console.log(`permission accepted! ${user.name}`);
        }
    }

    await OneSignal.init({
        appId: appId,
        allowLocalhostAsSecureOrigin: true,
        safariWebId: safariWebId,
        promptOptions: {
            slidedown: {
                prompts: [
                    {
                        autoPrompt: true,
                        text: {
                            actionMessage: 'Deseja habilitar as notificações?',
                            acceptButton: 'Sim',
                            cancelButton: 'Depois',
                        },
                        delay: {
                            pageViews: 1,
                            timeDelay: 2,
                        },
                    },
                ],
            },
        },
    }).then(() => {
        OneSignal.Debug.setLogLevel('trace');
        OneSignal.login(user.email);
        OneSignal.User.addAlias("myAlias", user.email);
    })

    OneSignal.Notifications.addEventListener("permissionChange", permissionChangeListener);
    OneSignal.Notifications.addEventListener("dismiss", notificationDismissedListener);
}