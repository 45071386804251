import { Id } from "@/app/types";
import { Message } from "@/lib/api/messages.types";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState } from "@/lib/redux/store";
import { IoTimeOutline, IoCheckmarkDone, IoCheckmark } from "react-icons/io5";
import { useSelector } from "react-redux";

type Props = {
    message?: Message
}
export default function ConfirmationIcon({ message }: Props) {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    if (!message) return <></>
    const currentUser = auth.userInfo;
    const currentUserId = currentUser?.id as Id;
    const isCurrentUserMessage = message.sender.id === currentUserId

    let statusIcon = <></>
    if (isCurrentUserMessage) {
        if (message.is_pending) {
            statusIcon = <IoTimeOutline />
        } else if (message.read_at) {
            statusIcon = <IoCheckmarkDone color={'#34abeb'} />
        } else {
            statusIcon = <IoCheckmarkDone />
        }
    }

    return statusIcon
}