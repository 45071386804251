// Backend
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

// Laravel Echo
export const REVERB_APP_KEY = process.env.REACT_APP_REVERB_APP_KEY
export const REVERB_HOST = process.env.REACT_APP_REVERB_HOST
export const REVERB_AUTH_ENDPOINT = BACKEND_URL + '/broadcasting/auth'
export const REVERB_PORT = process.env.REACT_APP_REVERB_PORT
export const REVERB_SCHEME = process.env.REACT_APP_REVERB_SCHEME

// Onesignal
export const ONE_SIGNAL_APP_ID = process.env.REACT_APP_ONE_SIGNAL_APP_ID
export const ONE_SIGNAL_SAFARI_WEB_ID = process.env.REACT_APP_ONE_SIGNAL_SAFARI_WEB_ID

// Enums
export const NODE_ENV = process.env.NODE_ENV
export const NODE_ENV_PRODUCTION = 'production'
export const USE_QUERY_STALE_SECONDS = 10;
export const SAFE_AREA_TOP_CSS = 'env(safe-area-inset-top)'
export const DESKTOP_WIDTH = 1024;

export const GCP_NUMBER = parseInt(process.env.REACT_APP_GCP_NUMBER)