import { Card } from "@chakra-ui/react"

type Props = {
    children: React.ReactNode,
    [x: string]: any,
}
export default function CustomCard({ children, ...rest }: Props) {
    return (
        <Card
            {...rest}
        >
            {children}
        </Card>
    )
}