import { Id } from "@/app/types"
import { Clinic } from "./clinic.types"
import { Department } from "./departments.types"
import { Role } from "./roles.types"
import { Notification } from "./notifications.types"

export type User = {
    id: Id
    name: string
    email: string
    picture: string
}

export type Me = {
    id: Id
    name: string
    email: string
    picture: string
    is_admin?: boolean
    clinics: UserClinicsDepartments[]
    notifications: Notification[]
    notifiable: boolean
    can_create_task?: boolean
    app_permissions: string[]
}

export type UserClinicsDepartments = {
    clinic: Clinic
    departments: UserDepartment[]
}

export type UserDepartment = {
    department: Department
    role: Role
}

export type CreateUserRequest = {
    name: string
    email: string
    password: string
    password_confirmation: string
    clinics: UserClinicsDepartments[]
    app_permissions?: string[]
}

export type UpdateUserRequest = {
    name: string
    email: string
    password?: string
    password_confirmation?: string
    clinics?: UserClinicsDepartments[]
    app_permissions?: string[]
}

export const createUserRequestInitialValues: CreateUserRequest = {
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    clinics: [],
    app_permissions: []
}