'use client'
import { SAFE_AREA_TOP_CSS } from "@/app/config";
import ConversationCard from "@/components/messages/conversationCard";
import ConversationsMessagesList from "@/components/messages/conversationMessagesList";
import MessageInput from "@/components/messages/messageInput";
import { useChatContext } from "@/contexts/chatContext";
import {
    Box,
    VStack,
    useColorModeValue
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";

type ChatConversationProps = {
    hideHeader?: boolean
    hideMessageInput?: boolean
}
export default function ChatConversation({ hideHeader = false, hideMessageInput = false }: ChatConversationProps) {
    const { messages, currentUserId, conversation } = useChatContext();

    // scroll
    const scrollRef = useRef(null);

    useEffect(() => {
        scrollRef.current?.firstElementChild?.scrollIntoView()
    }, [messages])

    const messageInputBgColor = useColorModeValue('gray.50', 'gray.800');

    return (
        <VStack width={'100%'}>
            {!hideHeader && (
                <ConversationCard
                    conversation={conversation}
                    zIndex={1}
                    position='fixed'
                    top={0}
                    left={0}
                    right={0}
                    px={{
                        base: '5',
                        lg: '4rem',
                    }}
                    pb={{
                        base: '10',
                        lg: '4rem',
                    }}
                    pt={{
                        base: `calc(4rem + ${SAFE_AREA_TOP_CSS})`,
                        lg: `calc(4rem + ${SAFE_AREA_TOP_CSS})`,
                    }}
                    boxShadow={
                        '0px 1px 3px rgba(0, 0, 0, 0.12)'
                    }
                    isConversationOpen
                />
            )}

            <ConversationsMessagesList
                mt={'6rem'}
                mb={'4rem'}
                scrollRef={scrollRef}
                currentUserId={currentUserId}
                overflow={'hidden'}
            />
            {!hideMessageInput && (
                <Box
                    borderRadius={'md'}
                    position={'fixed'}
                    bottom={0}
                    left={0}
                    right={0}
                    height={'4rem'}
                    bgColor={messageInputBgColor}
                    width={'100%'}
                    pl={6}
                    pr={2}
                >
                    <MessageInput />
                </Box>
            )}
        </VStack>
    )
}