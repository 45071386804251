import { useChatContext } from "@/contexts/chatContext"
import { Box, BoxProps, IconButton, Input, InputGroup, InputRightElement, useColorModeValue } from "@chakra-ui/react"
import { IoSend } from "react-icons/io5"

interface Props extends BoxProps {
}

export default function MessageInput({...props}: Props) {
    const { message, sending, setMessage, sendMessage } = useChatContext();
    return (
        <Box
            width={'100%'}
            {...props}
        >
            <form
                style={{ width: '100%' }}
                onSubmit={(e) => {
                    e.preventDefault()
                    if (message.trim() === '') return
                    sendMessage()
                }}
            >
                <InputGroup>
                    <Input
                        size='lg'
                        isDisabled={sending}
                        variant='flushed'
                        placeholder="Digite uma mensagem..."
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                    />
                    <InputRightElement width={'4.5rem'}>
                        <IconButton
                            mt={2}
                            variant='solid'
                            isRound
                            colorScheme='blue'
                            fontSize='15px'
                            size='md'
                            isDisabled={sending}
                            type="submit"
                            aria-label={"Enviar"}
                            icon={<IoSend />}
                        />
                    </InputRightElement>
                </InputGroup>
            </form>
        </Box>
    )
}