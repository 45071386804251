import { UserClinicsDepartments } from "@/lib/api/user.types";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Tag, TagLabel, TagLeftIcon, TagRightIcon } from "@chakra-ui/react";

type Props = {
    clinic: UserClinicsDepartments,
    onDelete: () => void,
    onEdit: () => void,
    size?: string
    colorScheme?: string
}
export default function ClinicBadge({ clinic, onDelete, onEdit, size = 'md', colorScheme = 'blue', ...props }: Props) {
    return (
        <Tag
            size={size}
            colorScheme={colorScheme}>
            <TagLeftIcon
                onClick={onEdit}
                cursor={'pointer'}
                as={EditIcon} />
            <TagLabel>{clinic.clinic.name}</TagLabel>
            <TagRightIcon
                onClick={onDelete}
                cursor={'pointer'}
                as={CloseIcon}
            />
        </Tag>
    )
}