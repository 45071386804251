import { ONE_SIGNAL_APP_ID } from '@/app/config';
import { Me } from '@/lib/api/user.types';
import OneSignal from 'onesignal-cordova-plugin';

export default async function runOneSignalMobile(user: Me) {
    OneSignal.Debug.setLogLevel(6)
    OneSignal.initialize(ONE_SIGNAL_APP_ID);
    OneSignal.login(user.email);
    OneSignal.User.addAlias("myAlias", user.email);

    OneSignal.Notifications.addEventListener('click', async (e) => {
        let clickData = await e.notification;
        console.log("Notification Clicked: ", clickData);
    });

    OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
        console.log("Notification permission granted: ", success);
    }).catch(()=>{
        console.log("Notification permission rejected");
    });
}