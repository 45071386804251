'use client'

import { routes } from "@/app/routes"
import ClinicItem from "@/components/settings/clinics/clinicItem"
import { requestGetClinics } from "@/lib/api/clinic"
import { Clinic } from "@/lib/api/clinic.types"
import { Box, Button, Flex, VStack } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

export default function ClinicsPage() {
    const [clinics, setClinics] = useState<Clinic[]>([])

    useEffect(() => {
        requestGetClinics().then((response) => {
            setClinics(response.data)
        }).catch(console.error)
    }, [])


    return (
        <VStack w="full" h="full">
            <VStack overflowY={'scroll'} gap={2} h={
                {
                    base: "calc(100vh - 350px)",
                    lg: "calc(65vh)"
                }
            } w="full">
                {clinics.map(
                    (clinic, key) => (
                        <Box key={key} width={'full'}>
                            <Link to={`${routes.CLINICS}/${clinic.id}`}>
                                <ClinicItem
                                    onClick={() => { }}
                                    clinic={clinic}
                                />
                            </Link>
                        </Box>
                    )
                )}
            </VStack>

            <Flex w="full">
                <Button
                    as={Link}
                    to={routes.ADD_CLINIC}
                    width={'100%'}
                    colorScheme='blue'
                >
                    Cadastrar clínica
                </Button>
            </Flex>
        </VStack>
    )
}