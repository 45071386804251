'use client'

import { routes } from "@/app/routes"
import UserItem from "@/components/settings/users/userItem"
import { requestGetUsers } from "@/lib/api/user"
import { User } from "@/lib/api/user.types"
import { Box, Button, Flex, VStack } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

export default function UsersPage() {
    const [users, setUsers] = useState<User[]>([])

    useEffect(() => {
        requestGetUsers().then((response) => {
            setUsers(response.data)
        }).catch(console.error)
    }, [])


    return (
        <VStack w="full" h="full">
            <VStack overflowY={'scroll'} gap={2} h={
                {
                    base: "calc(100vh - 350px)",
                    lg: "calc(65vh)"
                }
            } w="full">
                {users.map(
                    (user, key) => (
                        <Box key={key} width={'full'}>
                            <Link to={`${routes.USERS}/${user.id}`}>
                                <UserItem
                                    onClick={() => { }}
                                    user={user}
                                />
                            </Link>
                        </Box>
                    )
                )}
            </VStack>

            <Flex w="full">
                <Button
                    as={Link}
                    to={routes.ADD_USER}
                    width={'100%'}
                    colorScheme='blue'
                >
                    Cadastrar usuário
                </Button>
            </Flex>
        </VStack>
    )
}