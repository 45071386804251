import React from 'react';

import {
  Box,
  useColorModeValue,
} from '@chakra-ui/react';

import SidebarContent from './components/content';


function Sidebar(props) {
  const { routes, ...rest } = props;

  let variantChange = '0.2s linear';
  let shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset',
  );
  let sidebarBg = useColorModeValue('white', 'navy.800');
  let sidebarMargins = '0px';

  return (
    <Box
      position="fixed"
      minH="100%"
      display={{
        base: 'none',
        xs: 'none',
        sm: 'none',
        md: 'none',
        lg: 'none',
        xl: 'none',
      }}
    >
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="90px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
        boxShadow={shadow}
      >
        <SidebarContent routes={routes} />
      </Box>
    </Box>
  );
}


export default Sidebar;