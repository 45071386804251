import { Assignee } from "@/lib/api/assigners.types";

import { Box, FormLabel, Heading, Text } from "@chakra-ui/react";
import AssigneeItem from "./assigneeItem";
import AssigneeAddModal from "./responsibleAddModal";
import { Department } from "@/lib/api/departments.types";
import DepartmentItem from "./departmentItem";

type Props = {
    assigners: Assignee[]
    departments: Department[]
    withAddButton?: boolean,
    onAddAssignee?: (assignee: Assignee) => void,
    onRemoveAssignee?: (assignee: Assignee) => void,
    onAddDepartment?: (department: Department) => void,
    onRemoveDepartment?: (department: Department) => void,
}

export default function ResponsiblesList({
    assigners = [],
    departments = [],
    withAddButton = false,
    onAddAssignee,
    onRemoveAssignee,
    onAddDepartment,
    onRemoveDepartment,
}: Props) {
    return (
        <Box>
            <FormLabel>
                Usuários ou departamentos responsáveis:
            </FormLabel>
            {assigners.map((assignee, key) => (
                <AssigneeItem key={key} assignee={assignee} />
            ))}
            {departments.map((department, key) => (
                <DepartmentItem key={key} withIcon department={department} />
            ))}
            {
                assigners.length === 0 && departments.length === 0 &&
                <Box mt={4}>
                    Nenhum responsável adicionado ainda.
                </Box>
            }
            {withAddButton && <Box mt={4}>
                <AssigneeAddModal
                    taskAssigners={assigners}
                    taskDepartments={departments}
                    buttonLabel='Gerenciar responsáveis'
                    onAddAssignee={onAddAssignee}
                    onRemoveAssignee={onRemoveAssignee}
                    onAddDepartment={onAddDepartment}
                    onRemoveDepartment={onRemoveDepartment}
                />
            </Box>
            }
        </Box>
    )
}