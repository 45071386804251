import { Id } from "@/app/types"

export type Clinic = {
    id: Id
    name: string
    city: string
    state: string
    address: string
    document_number: string
    zip_code: string | null
    status: boolean
    created_at: string
    updated_at: string
    deleted_at: string | null
    created_by: Id
    updated_by: Id
}

export type CreateClinicRequest = {
    name: string
    city: string
    state: string
    address: string
    document_number: string
    zip_code: string | null
    status: boolean
}

export type UpdateClinicRequest = {
    name: string
    city: string
    state: string
    address: string
    document_number: string
    zip_code: string | null
    status: boolean
}


export const createClinicRequestInitialValues: CreateClinicRequest = {
    name: '',
    city: '',
    state: '',
    address: '',
    document_number: '',
    zip_code: '',
    status: true
}

