import { SettingsIcon } from "@chakra-ui/icons";
import { FaClipboardCheck, FaGlobe } from "react-icons/fa6";
import { MdHome, MdHourglassFull } from "react-icons/md";

const layouts = {
    MANAGER: '/manager',
    LOGIN: '/login',
}

const concatRoutes = (...args) => args.join('');

const paths = {
    TASKS: '/tasks',
    ADD: '/add',
    IN_PROGRESS: '/in_progress',
    MY_TASKS: '/my_tasks',
    FINISHED: '/finished',
    ALL: '/all',
    CREATED_BY_ME: '/created_by_me',
    SETTINGS: '/settings',
    USERS: '/users',
    DEPARTMENTS: '/departments',
    CLINICS: '/clinics',
    PROFILE: '/profile',
    SERVICES: '/services',
    EDIT: '/edit',
    MESSAGES: '/messages',
    CONVERSATION: '/:conversationID'
}

const routes = {
    ROOT: "/",
    HOME: concatRoutes(layouts.MANAGER),
    LOGIN: concatRoutes(layouts.LOGIN),
    TASKS: concatRoutes(layouts.MANAGER, paths.TASKS),
    TASK_DETAILS: concatRoutes(layouts.MANAGER, paths.TASKS, "/:id"),
    MY_TASKS: concatRoutes(layouts.MANAGER, paths.TASKS, paths.MY_TASKS),
    ADD_TASK: concatRoutes(layouts.MANAGER, paths.TASKS, paths.ADD),
    EDIT_TASK: concatRoutes(layouts.MANAGER, paths.TASKS, paths.EDIT, "/:id"),
    IN_PROGRESS: concatRoutes(layouts.MANAGER, paths.TASKS, paths.IN_PROGRESS),
    FINISHED: concatRoutes(layouts.MANAGER, paths.TASKS, paths.FINISHED),
    ALL: concatRoutes(layouts.MANAGER, paths.TASKS, paths.ALL),
    CREATED_BY_ME: concatRoutes(layouts.MANAGER, paths.CREATED_BY_ME),
    SETTINGS: concatRoutes(layouts.MANAGER, paths.SETTINGS),
    USERS: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.USERS),
    ADD_USER: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.USERS, paths.ADD),
    PROFILE: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.PROFILE),
    SERVICES: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.SERVICES),
    ADD_SERVICE: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.SERVICES, paths.ADD),
    DEPARTMENTS: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.DEPARTMENTS),
    ADD_DEPARTMENT: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.DEPARTMENTS, paths.ADD),
    CLINICS: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.CLINICS),
    ADD_CLINIC: concatRoutes(layouts.MANAGER, paths.SETTINGS, paths.CLINICS, paths.ADD),
    MESSAGES: concatRoutes(layouts.MANAGER, paths.MESSAGES),
    CONVERSATION_BY_ID: concatRoutes(layouts.MANAGER, paths.MESSAGES, "/:conversationID"),
}

const sidebarRoutes = [
    {
        'path': routes.MY_TASKS,
        'name': 'Minhas tarefas',
        'icon': <MdHome />
    },
    {
        'path': routes.IN_PROGRESS,
        'name': 'Em progresso',
        'icon': <MdHourglassFull />
    },
    {
        'path': routes.FINISHED,
        'name': 'Finalizadas',
        'icon': <FaClipboardCheck />
    },
    {
        'path': routes.ALL,
        'name': 'Todas',
        'icon': <FaGlobe/>
    },
    {
        'path': '',
        'path': routes.SETTINGS,
        'name': 'Configurações',
        'icon': <SettingsIcon />
    },
]



export { sidebarRoutes, routes, paths };