// create some enums
export const TASK_FILTERS = {
    ALL: 'all',
    CREATED_BY_ME: 'createdByMe',
    ASSIGNED_TO_ME: 'assignedToMe',
    UNASSIGNED: 'unassigned'
}

export const DATES = {
    DEFAULT_DATE_FORMAT: 'DD.MM.YYYY / HH:mm'
}

export const TASK_STATUS = {
    DONE: 'done'
}

export const INVALID_CREDENTIALS_ERROR = 'Credenciais incorretas';
export const INTERNAL_SERVER_ERROR = 'Oops, algo deu errado. Tente novamente mais tarde.';