import { Id } from "@/app/types";
import { instance } from "./api";
import { CreateServiceRequest, Service, UpdateServiceRequest } from "./services.types";

export async function requestGetServices() {
    return await instance.get<Service[]>("/services");
}

export async function requestGetService(id: Id) {
    return await instance.get<Service>(`/services/${id}`);
}

export async function requestCreateService(data: CreateServiceRequest) {
    return await instance.post<Service>("/services", data);
}
export async function requestUpdateService(id: Id, data: UpdateServiceRequest) {
    return await instance.put<Service>(`/services/${id}`, data);
}