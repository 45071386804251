import { Box } from "@chakra-ui/react";

export default function ItemsGenerator({ range = 0, children },) {
    return (
        <>
            {
                [...Array(range)].map((_, i) => (
                    <Box key={i}>
                        {children}
                    </Box>
                ))
            }
        </>
    )
}