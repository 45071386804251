
import { Comment, } from "@/lib/api/comment.types";
import { Avatar, Box, CardBody, Center, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import CommentReplyPopover from "./commentReplyPopover";
import { Id } from "@/app/types";
import CustomCard from "../card/customCard";

type CommentItemProps = {
    comment: Comment,
    onNewComment: (feedback: string, id: Id) => void,
    onError: (error: string) => void,
}

export default function CommentItem({ comment, onNewComment, onError }: CommentItemProps) {
    const sizeByType = 'sm'
    return (
        <CustomCard
            id={`comment-${comment.id}`}>
            <CardBody>
                <Flex >
                    <Box w='60px'>
                        <Avatar
                            name={!!comment.user?.picture && comment.user.name}
                            src={comment.user.picture}
                        // src={`https://source.unsplash.com/featured/?employer-avatar&random=${comment.user.name}`}
                        />
                    </Box>
                    <Box >
                        <Text fontSize={sizeByType} as='b'>{comment.user.name} • {moment(comment.created_at).format('HH:mm')}  </Text>
                        <Text fontSize={sizeByType} >{comment.comment}</Text>
                        <CommentReplyPopover
                            comment={comment}
                            onReply={(id) => {
                                onNewComment('Comentário respondido com sucesso', id)
                            }}
                            onReplyError={() => {
                                onError('Erro ao responder comentário')
                            }}
                        />
                    </Box>
                </Flex>
                {comment.replies && comment.replies.length > 0 &&
                    <Box>
                        {comment.replies.map((reply, index) => (
                            <CommentItem
                                key={index}
                                onNewComment={onNewComment}
                                comment={reply}
                                onError={onError} />
                        ))}
                    </Box>
                }
            </CardBody>
        </CustomCard>
    )
}