'use client';
import TaskDetails from "@/components/tasks/taskDetails"
import TaskDetailsContext from "@/contexts/taskDetailsContext";
import { requestGetTaskById } from "@/lib/api/task";
import { Task } from "@/lib/api/task.types";
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";

export default function TaskDetailsPage() {
    const [task, setTask] = useState<Task>(null);
    const [isLoading, setIsLoading] = useState(true);

    const params = useParams()

    async function getTask() {
        setIsLoading(true)
        try {
            const response = await requestGetTaskById(params.id.toString())
            setTask(response.data)
        } catch (err) {
            console.error(err)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (!!params.id) {
            getTask()
        };
    }, [params])

    return (
        <TaskDetailsContext.Provider value={{ task, setTask, getTask, isLoading, setIsLoading }}>
            <TaskDetails />
        </TaskDetailsContext.Provider>
    )
}