import { Me, UserClinicsDepartments } from "@/lib/api/user.types";
import { AuthStateProps, setAuthState } from "@/lib/redux/slices/auth/slice";
import { IRootState, useDispatch, useSelector } from "@/lib/redux/store";
import { createContext, useContext } from "react";

export type AuthContextType = {
    userInfo: Me,
    userClinic: UserClinicsDepartments | null,
    activeFiltersTasksTabRef: string,
    setActiveFiltersTasksTabRef: (ref: string) => void,
    token: string
}

const AuthContext = createContext<AuthContextType | null>(null);
export default AuthContext;

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const dispatch = useDispatch();
    const userInfo = auth?.userInfo;
    const userClinic = auth?.userClinic;
    const activeFiltersTasksTabRef = auth?.activeFiltersTasksTabRef;
    const token = auth?.userToken;

    const setActiveFiltersTasksTabRef = (ref: string) => {
        dispatch(setAuthState({ ...auth, activeFiltersTasksTabRef: ref }))
    }

    return (
        <AuthContext.Provider value={{
            token: token,
            userInfo: userInfo,
            userClinic: userClinic,
            activeFiltersTasksTabRef: activeFiltersTasksTabRef,
            setActiveFiltersTasksTabRef: setActiveFiltersTasksTabRef,
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuthContext() {
    return useContext(AuthContext) as AuthContextType;
};
