import { User } from "@/lib/api/user.types"
import { Avatar, Tag, TagLabel } from "@chakra-ui/react"

type Props = {
    user: User
    color?: string
}
export default function UserBadge({ user, color = 'blue' }: Props) {
    return (
        <Tag size='md' colorScheme={color} borderRadius='full'>
            <Avatar
                src={user.picture}
                size='2xs'
                name={user.picture && user.name}
                ml={-1}
                mr={2}
            />
            <TagLabel>{user.name}</TagLabel>
        </Tag>
    )
}