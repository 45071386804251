import { Id } from "@/app/types";
import { instance } from "./api";
import { CommentReplyRequest, CommentReplyResponse, CreateCommentRequest, CreateCommentResponse } from "./comment.types";

export async function requestCreateComment(body: CreateCommentRequest) {
    return await instance.post<CreateCommentResponse>('/tasks/comments', body);
}

export async function requestReplyComment(commentId: Id, body: CommentReplyRequest) {
    return await instance.post<CommentReplyResponse>(`/tasks/comments/${commentId.toString()}/reply`, body);
}