"use client"
import TasksList from "@/components/tasks/tasksList";
import { TaskFiltersType } from "@/contexts/taskFiltersContext";
import { requestGetFinishedByMeTasks } from "@/lib/api/task";
import { TaskResponse } from "@/lib/api/task.types";
import { laravelNextPage, mergeLaravelReactQueryPages } from "@/lib/api/utils";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState, useSelector } from "@/lib/redux/store";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";


export default function FinishedTasksPage() {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const clinicId = auth.userClinic?.clinic?.id ?? null;

    const [filters, setFilters] = useState<TaskFiltersType>(null);
    const [pageIsReady, setPageIsReady] = useState(false);

    useEffect(() => {
        setPageIsReady(true);
    }, [])

    const {
        data,
        fetchNextPage,
        isFetching,
        isFetchingNextPage,
        error: _error,
        status: _status,
        hasNextPage: _hasNextPage,
    } = useInfiniteQuery({
        queryKey: ['finished_tasks', clinicId, pageIsReady, filters],
        queryFn: async ({ pageParam = 1 }) => (await requestGetFinishedByMeTasks(clinicId, { ...filters, page: pageParam }))?.data,
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            const nextPage = laravelNextPage(lastPage.next_page_url)
            return nextPage > lastPage.current_page ? nextPage : null
        },
        enabled: pageIsReady,
        staleTime: 10000,
    })

    const paginatedResponse = mergeLaravelReactQueryPages(data);

    return (

        <>
            <Tabs
                isFitted
                variant='solid-rounded'
                colorScheme='blue'
            >
                <TabList>
                    <Tab
                        borderRadius={5}
                    >
                        Tarefas finalizadas
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <TasksList
                            tasksPaginated={paginatedResponse}
                            isLoading={isFetching && !data?.pages?.length}
                            isLoadingMore={isFetchingNextPage}
                            onSetFilters={setFilters}
                            onNextPage={fetchNextPage}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}