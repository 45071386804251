import { Id } from "@/app/types";
import { requestReplyComment } from "@/lib/api/comment";
import { Comment, CommentReplyRequest } from "@/lib/api/comment.types";
import { useDisclosure, Popover, PopoverTrigger, Button, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Textarea, useToast } from "@chakra-ui/react";
import { useState, useRef } from "react";

type Props = {
    comment: Comment
    onReply: (commentId: Id) => void,
    onReplyError: () => void,
}


export default function CommentReplyPopover({ comment, onReply, onReplyError }: Props) {
    const { isOpen, onToggle, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [replyContent, setReplyContent] = useState<string>('')
    const initialFocusRef = useRef()

    const isReply = !!comment.task_comment_id;

    if (isReply) return

    const replyComment = (reply: string) => {
        const body = {
            task_id: comment.task_id,
            comment: reply,
        } as CommentReplyRequest

        setIsLoading(true)
        requestReplyComment(comment.id, body).then(({ data }) => {
            setReplyContent('')
            onReply(data.id);
            onClose();
        }).catch(() => {
            onReplyError();
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <Popover
            initialFocusRef={initialFocusRef}
            isOpen={isOpen} onClose={onClose}
        >
            <PopoverTrigger>
                <Button
                    size={'sm'}
                    my={1}
                    onClick={onToggle}
                >
                    Responder
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Escreva sua resposta</PopoverHeader>
                <PopoverBody>
                    <Textarea
                        value={replyContent}
                        ref={initialFocusRef}
                        onChange={(e) => {
                            setReplyContent(e.target.value)
                        }}
                    />
                    <Button
                        isLoading={isLoading}
                        disabled={!replyContent}
                        loadingText='Enviando...'
                        colorScheme='blue'
                        my={2}
                        onClick={() => replyComment(replyContent)}
                    >
                        Enviar
                    </Button>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}
