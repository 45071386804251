"use client";
import { routes } from '@/app/routes';
import ChatConversation from '@/components/messages/chatConversation';
import ChatModal from '@/components/messages/chatModal';
import ConversationCard from '@/components/messages/conversationCard';
import MessageInput from '@/components/messages/messageInput';
import { useChatContext } from '@/contexts/chatContext';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';

export default function ConversationPage() {
    const navigate = useNavigate();
    const [isClient, setIsClient] = useState(false);
    const { getConversations, viewType, conversation } = useChatContext();


    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) return null;

    switch (viewType) {
        case 'desktop':
            return (
                <>
                    <ConversationCard px={2} conversation={conversation} isConversationOpen />
                    <Box
                        sx={{
                            '::-webkit-scrollbar': {
                                width: '0px',
                                background: 'transparent',
                            },
                            '-ms-overflow-style': 'none',  /* IE and Edge */
                            'scrollbar-width': 'none'  /* Firefox */
                        }}
                        overflowY='scroll'
                        w='100%'
                        h='65vh'
                    >
                        <ChatConversation hideHeader hideMessageInput />
                    </Box>
                    <MessageInput />
                </>

            );
        case 'mobile':
            return (
                <ChatModal
                    onClose={() => {
                        getConversations();
                        if (window.history.length > 1) {
                            navigate(-1);
                        } else {
                            window.location.replace(routes.MY_TASKS);
                        }
                    }}
                />
            );
        default:
            return <></>;
    }
}
