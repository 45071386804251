import { Box, Button, Flex, FormControl, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Stack, Tag, TagLabel, TagRightIcon, VStack, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { UserClinicsDepartments, UserDepartment } from "@/lib/api/user.types";
import { Select } from "chakra-react-select";
import { Clinic } from "@/lib/api/clinic.types";
import { requestGetClinics } from "@/lib/api/clinic";
import { Department } from "@/lib/api/departments.types";
import { requestGetDepartments } from "@/lib/api/departments";
import { AddIcon } from "@chakra-ui/icons";
import { Role } from "@/lib/api/roles.types";
import UserDepartmentCard from "@/components/departments/userDepartmentsCard";
import { requestGetRoles } from "@/lib/api/role";
import { Id } from "@/app/types";
import { SAFE_AREA_TOP_CSS } from "@/app/config";

type Props = {
  isOpen: boolean
  onClose: () => void;
  onOpen: () => void;
  defaultUserClinic: UserClinicsDepartments | null;
  ignoreIds?: Id[];
  buttonLabel: string;
  onSave?: (savedUserClinic: UserClinicsDepartments) => void;
  onError?: (error: string) => void;
  modalType: LinkUserClinicModalType;
  onNewClinicButtonClick: () => void;
}

export type LinkUserClinicModalType = 'ADD' | 'EDIT'

export default function LinkUserClinicModal({
  ignoreIds,
  defaultUserClinic,
  buttonLabel,
  onSave,
  isOpen,
  onClose,
  onOpen,
  modalType,
  onNewClinicButtonClick

}: Props) {
  const [userClinic, setUserClinic] = useState<UserClinicsDepartments>(defaultUserClinic);
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);

  const clinicIsSelected = !!userClinic?.clinic?.id;
  const hasUserDepartments = !!userClinic?.departments?.length;
  const rolesIsSetted = () => {
    for (const department of userClinic?.departments ?? []) {
      if (!department.role?.id) {
        return false;
      }
    }
    return true
  }

  const availableClinics = clinics.filter((clinic) => !ignoreIds?.includes(clinic.id));
  const hasAvailableClinics = !!availableClinics.length;

  useEffect(() => {
    requestGetClinics().then((response) => {
      setClinics(response.data)
    }).catch(console.error);

    requestGetDepartments().then((response) => {
      setDepartments(response.data)
    }).catch(console.error);

    requestGetRoles().then((response) => {
      setRoles(response.data)
    }).catch(console.error);
  }, [])

  useEffect(() => {
    setUserClinic(defaultUserClinic);
  }, [defaultUserClinic])

  const save = () => {
    onSave?.(userClinic);
    setUserClinic({} as UserClinicsDepartments);
    onClose();
  }

  return (
    <>
      <Button
        isDisabled={!hasAvailableClinics}
        onClick={() => {
          onNewClinicButtonClick()
          onOpen()
        }}
        colorScheme={'blue'}
        size={'sm'}
        mt={2}
        h={'40px'}
        border={'1px solid'}
        borderColor={'blue.500'}
        rightIcon={<AddIcon />}
      >
        {buttonLabel}
      </Button>
      <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent pt={SAFE_AREA_TOP_CSS}>
          <ModalHeader>{buttonLabel}</ModalHeader>
          <ModalCloseButton
            position={'fixed'}
            top={`calc(1rem+${SAFE_AREA_TOP_CSS})`}
            right={'1rem'}
            size='lg'
            zIndex={3}
          />
          <ModalBody>
            <VStack gap={2} mb={2}>
              <FormControl>
                <Select
                  isDisabled={modalType === 'EDIT'}
                  isClearable={false}
                  value={clinicIsSelected ? {
                    label: userClinic?.clinic?.name,
                    value: userClinic?.clinic?.id as number,
                  } : null}
                  options={availableClinics.map(clinic => {
                    return {
                      value: clinic.id as number,
                      label: clinic.name
                    }
                  })
                  }
                  onChange={(value) => {
                    if (value.value === userClinic?.clinic?.id) {
                      return
                    }

                    setUserClinic({
                      ...userClinic,
                      clinic: clinics.find(u => u.id === value.value) as Clinic,
                      departments: [],
                    } as UserClinicsDepartments);
                  }}
                  placeholder="Pesquise"
                />
              </FormControl>
              {clinicIsSelected && (
                <FormControl>
                  <Heading mt={4} size='sm'>
                    Defina os departamentos e cargos
                  </Heading>
                  <Box my={4}>
                    {departments
                      .filter(
                        (department) =>
                          !userClinic.departments.find(
                            (d) => d.department.id === department.id
                          )
                      )
                      .map((department, index) => (
                        <Tag
                          m={1}
                          size={'lg'}
                          key={index}
                          colorScheme='blue'>
                          <TagLabel>{department.name}</TagLabel>
                          <TagRightIcon
                            cursor={'pointer'}
                            as={AddIcon}
                            onClick={() => {
                              const addedDepartment: UserDepartment = {
                                department,
                                role: {} as Role
                              }
                              setUserClinic({
                                ...userClinic,
                                departments: [...userClinic.departments, addedDepartment],
                              } as UserClinicsDepartments);
                            }} />
                        </Tag>
                      ))}
                  </Box>
                </FormControl>
              )}

              {hasUserDepartments && (
                <SimpleGrid
                  width={'100%'}
                  gap={4}

                  columns={{
                    base: 1,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    '2xl': 5,
                  }}>
                  {
                    userClinic.departments.map((department, index) => (
                      <Box
                        key={index}
                        width={'full'}>
                        <UserDepartmentCard
                          userDepartment={department}
                          roles={roles}
                          onDelete={() => {
                            setUserClinic({
                              ...userClinic,
                              departments: userClinic.departments.filter(
                                (d) => d.department.id !== department.department.id
                              ),
                            } as UserClinicsDepartments);
                          }}
                          onRoleChange={(roleId) => {
                            if (roleId) {
                              setUserClinic({
                                ...userClinic,
                                departments: userClinic.departments.map(d => {
                                  if (d.department.id === department.department.id) {
                                    return {
                                      ...d,
                                      role: roles.find(r => r.id === roleId) as Role
                                    }
                                  }
                                  return d;
                                })
                              } as UserClinicsDepartments);
                            }
                          }}
                        />
                      </Box>
                    )
                    )
                  }
                </SimpleGrid>
              )
              }
            </VStack>
          </ModalBody>
          <ModalFooter id={'bottom'}>
            <Button
              isDisabled={
                !clinicIsSelected ||
                !hasUserDepartments ||
                !rolesIsSetted()
              }
              onClick={save}
              colorScheme='blue'
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}