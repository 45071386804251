import { Id } from "@/app/types";
import { requestGetConversation, requestGetConversations, requestMarkMessageAsRead, requestPostMessage } from "@/lib/api/message";
import { Conversation, CreateMessage, Message, MessageEvent, ReadConversationEvent } from "@/lib/api/messages.types";
import { requestGetUsers } from "@/lib/api/user";
import { User } from "@/lib/api/user.types";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState } from "@/lib/redux/store";
import { useToast } from "@chakra-ui/react";
import Echo from "laravel-echo";
import moment from "moment";
import { useParams } from "react-router-dom";

import Pusher from "pusher-js";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DESKTOP_WIDTH, REVERB_APP_KEY, REVERB_AUTH_ENDPOINT, REVERB_HOST, REVERB_PORT, REVERB_SCHEME } from "@/app/config";

export type ChatContextType = {
    users: User[],

    conversations: Conversation[],
    setConversations: (conversations: Conversation[]) => void,
    getConversations: () => void,

    conversation: Conversation,
    setConversation: (conversation: Conversation) => void,
    getConversation: (quietly: boolean) => Promise<void>,

    messages: Message[],
    setMessages: (messages: Message[]) => void,

    message: string,
    setMessage: (message: string) => void,
    upsertMessage: (message: Message) => void,
    sendMessage: () => void,
    markAsRead: (message: Message) => Promise<void>,
    sending: boolean,
    setSending: (sending: boolean) => void,

    windowIsReady: boolean,
    currentUserId: Id,
    connectWebSocket: (currentUserId: Id) => void,

    viewType: 'desktop' | 'mobile'

}

const ChatContext = createContext<ChatContextType | null>(null);
export default ChatContext;

export function ChatProvider({ children }: { children: React.ReactNode }) {
    const toast = useToast();
    const { conversationID } = useParams();

    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const currentUser = auth.userInfo;
    const currentUserId = currentUser?.id as Id;

    const [usersForChat, setUsersForChat] = useState<User[]>([]);
    const [windowIsReady, setWindowIsReady] = useState(false);
    const [conversation, setConversation] = useState<Conversation | null>(null);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);

    const [viewType, setViewType] = useState(null)

    useEffect(() => {
        const screenSize = window.innerWidth;
        const isDesktop = screenSize > DESKTOP_WIDTH;
        setViewType(isDesktop ? 'desktop' : 'mobile')
    }, [])

    // socket
    const messagesChannel = `Messages.User.${currentUserId}`;

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.Pusher = Pusher;

            window.Echo = new Echo({
                authEndpoint: REVERB_AUTH_ENDPOINT,
                broadcaster: 'reverb',
                key: REVERB_APP_KEY,
                wsHost: REVERB_HOST,
                wsPort: REVERB_PORT ?? 80,
                wssPort: REVERB_PORT ?? 443,
                forceTLS: (REVERB_SCHEME ?? 'https') === 'https',
                enabledTransports: ['wss', 'ws'],
                auth: { headers: { Authorization: 'Bearer ' + auth.userToken }, },
            });

            console.info(window.Echo)

            setWindowIsReady(true);
        }
    }, []);

    const connectWebSocket = () => {
        const echoMessagesChannel = window.Echo.private(messagesChannel);

        echoMessagesChannel.listen('GotMessage', async (messageEvent: MessageEvent) => {
            upsertMessage(messageEvent.message);
            if (conversationID === messageEvent.message.conversation_id) {
                markAsRead(messageEvent.message);
            }

            if (!conversationID) {
                getConversations();
            }

        });

        echoMessagesChannel.listen('ReadConversationEvent', async (_readConversationEvent: ReadConversationEvent) => {
            const _msgs = (_readConversationEvent?.readMessages ?? []).map(
                (message) => ({
                    ...message, read_at: moment().format('YYYY-MM-DD HH:mm:ss')
                })
            )
            _msgs.forEach(msg => upsertMessage(msg));
        });
    }

    const getConversation = async (quietly: boolean) => {
        if (!conversationID) {
            return;
        }
        const { data } = await requestGetConversation(conversationID as Id, quietly);
        setConversation({ ...data, messages: [] });
        setMessages(data.messages);
    };

    const getConversations = async () => {
        try {
            const { data } = await requestGetConversations();
            setConversations(data);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!windowIsReady) {
            return;
        }
        if (window.Echo === undefined)
            return;

        connectWebSocket();
        getConversations();

        return () => {
            window.Echo.leave(messagesChannel);
        }
    }, [windowIsReady, currentUserId]);

    useEffect(() => {
        if (window.Echo === undefined)
            return;

        getConversation(false);
        connectWebSocket();

        return () => {
            window.Echo.leave(messagesChannel);
        }
    }, [conversationID]);


    // get users
    useEffect(() => {
        if (!currentUser) {
            return;
        }

        requestGetUsers().then(res => {
            const _users = res.data.filter(user => user.id !== currentUser.id);
            setUsersForChat(_users)
        });
    }, [currentUser])

    const upsertMessage = (message: Message) => {
        setMessages((prev) => {
            const messageIndex = prev.findIndex(m => m.client_id === message.client_id);
            if (messageIndex === -1) {
                return [message, ...prev];
            } else {
                const newMessages = [...prev];
                newMessages[messageIndex] = message;
                return newMessages;
            }
        })
    }

    const markAsRead = async (message: Message) => {
        await requestMarkMessageAsRead(message.id);
    }

    const sendMessage = async () => {
        setSending(true);
        const clientId = `${conversation.id}_${currentUserId}_${moment().format('YYYYMMDDHHmmss')}`;

        const body: CreateMessage = {
            conversation_id: conversation.id,
            client_id: clientId as Id,
            content: message,
        }

        upsertMessage({
            client_id: clientId as Id,
            content: message,
            sender: currentUser as User,
            created_at: moment().format(
                'YYYY-MM-DD HH:mm:ss'
            ),
            is_pending: true
        } as Message)

        setMessage('')

        requestPostMessage(body)
            .catch(() => {
                toast({
                    title: 'Erro ao enviar sua mensagem',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            })
            .finally(() => {
                getConversations();
                setSending(false);
            })
    }

    return (
        <ChatContext.Provider value={{
            users: usersForChat,
            currentUserId,

            conversations,
            getConversations,
            setConversations,

            conversation,
            setConversation,
            getConversation,

            messages,
            setMessages,

            message,
            setMessage,
            sending,
            setSending,
            upsertMessage,
            sendMessage,
            markAsRead,

            windowIsReady,
            connectWebSocket,

            viewType,
        }}>
            {children}
        </ChatContext.Provider>
    );
}

export function useChatContext() {
    return useContext(ChatContext) as ChatContextType;
};
