"use client"
import { requestSendResetPasswordLink } from '@/lib/api/password';
import { Button, Card, CardBody, FormControl, FormErrorMessage, Heading, Input, Text, useToast } from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';

export default function RecoverPasswordPage() {
    const toast = useToast();

    const initialValues = { email: '' }

    const validationSchema = Yup.object({
        email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório')
    });

    return (

        <Card w="100%" maxW="md" align='center' p={4} display="flex" justifyContent="center" alignItems="center" >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    requestSendResetPasswordLink(values.email)
                        .then(({ data }) => {
                            toast({
                                title: data.status,
                                status: 'success',
                                duration: 9000,
                                isClosable: true,
                            });
                        })
                        .catch((err) => {
                            let errorMsg = 'Erro ao enviar link de redefinição';
                            if (err.response.status === 400) {
                                errorMsg = err.response.data.status;
                            }
                            toast({
                                title: errorMsg,
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                            });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <CardBody>
                            <Heading size="md" mb={4}>Recuperação de Senha</Heading>
                            <Text mb={6}>
                                Insira seu e-mail abaixo e enviaremos um link para redefinição de senha.
                            </Text>

                            <FormControl isInvalid={!!errors.email} mb={4}>
                                <Input
                                    type='email'
                                    name="email"
                                    placeholder='Digite seu e-mail'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    size="lg"
                                />
                                <FormErrorMessage>
                                    {errors.email && touched.email && errors.email}
                                </FormErrorMessage>
                            </FormControl>
                            <Button
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                                type='submit'
                                size='lg'
                                w='100%'
                                variant='solid'
                                colorScheme='blue'
                            >
                                Enviar link de redefinição
                            </Button>
                        </CardBody>
                    </form>
                )}
            </Formik>
        </Card>
    );
}
