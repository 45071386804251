"use client"
import { routes } from "@/app/routes";
import TaskDeleteConfirmationModal from "@/components/tasks/taskDeleteConfirmationModal";
import TaskDetailsContext from "@/contexts/taskDetailsContext";
import { Task } from "@/lib/api/task.types";
import { downloadAttachment, viewAttachment } from "@/lib/api/utils";
import { DATES } from "@/utils/enums";
import { TASK_ASSIGNEE_STATUS_CANCELLED, TASK_ASSIGNEE_STATUS_DONE, taskStatus } from "@/utils/translators";
import { EditIcon } from "@chakra-ui/icons";
import {
    Box,
    Card,
    CardBody,
    Heading,
    HStack,
    IconButton,
    SimpleGrid,
    Spacer,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AttachmentList from "../attachments/attachmentList";
import CommentsCountBadge from "../badges/commentsCountBadge";
import PriorityBadge from "../badges/priorityBadge";
import UserBadge from "../badges/userBadge";
import TaskCommentsModal from "../comments/taskCommentsModal";
import ResponsiblesList from "../responsibles/responsiblesList";
import TaskSkeleton from "../skeletons/taskSkeleton";
import TaskAssignConfirmModal from "./taskAssignConfirmationModal";
import TaskClaimConfirmationModal from "./taskClaimConfirmationModal";
import TaskFinishConfirmationModal from "./taskFinishConfirmationModal";

type TaskDetailsContextProps = {
    task: Task,
    getTask: () => Promise<any>,
    isLoading: boolean,
}

export default function TaskDetails() {
    const { isOpen: isCommentsModalOpen, onOpen: onCommentsModalOpen, onClose: onCommentsModalClose } = useDisclosure();
    const { task, getTask, isLoading } = useContext<TaskDetailsContextProps>(TaskDetailsContext);
    const toast = useToast();
    const navigate = useNavigate();

    if (!task) return <TaskSkeleton />

    const taskIsDone = task.status === TASK_ASSIGNEE_STATUS_DONE;
    const taskIsCanceled = task.status === TASK_ASSIGNEE_STATUS_CANCELLED;
    const dueDateIsExpired = moment(task.due_date).isBefore(moment());
    const dueDateIsToday = moment(task.due_date).isSame(moment(), 'day');
    const dueDateIsTomorrow = moment(task.due_date).isSame(moment().add(1, 'day'), 'day');
    const dueDateTagColor = (taskIsDone || taskIsCanceled)
        ? 'blue.300'
        : dueDateIsExpired
            ? 'red.300'
            : dueDateIsToday
                ? 'yellow'
                : dueDateIsTomorrow
                    ? 'green'
                    : 'gray';


    return (
        <Box>
            <Box display={isLoading ? 'block' : 'none'}>
                <TaskSkeleton />
            </Box>
            <Box display={isLoading ? 'none' : 'block'}>
                <HStack spacing={2} mb={2}>
                    <UserBadge user={task.created_by_user} />
                    <PriorityBadge priority={task.priority} />
                    <Spacer />
                    {task.can_edit && (
                        <IconButton
                            aria-label={'Editar'}
                            onClick={
                                () => navigate(`${routes.EDIT_TASK}/${task.id}`)
                            }>
                            <EditIcon />
                        </IconButton>
                    )}
                    {task.can_delete && (
                        <TaskDeleteConfirmationModal />
                    )}
                </HStack>
                <Card>
                    <CardBody>
                        <Box mb={4}>
                            <Heading size={'lg'}>{task.title}</Heading>
                            <SimpleGrid columns={2} spacing={2} fontSize={'.8rem'}>
                                <Box>
                                    <Text color={'gray.500'}>Aberto em:</Text>
                                    {moment(task.created_at).format(DATES.DEFAULT_DATE_FORMAT)}
                                </Box>
                                <Box>
                                    <Text color={'gray.500'}>Vence em:</Text>
                                    <Text color={dueDateTagColor}>{moment(task.due_date).format(DATES.DEFAULT_DATE_FORMAT)}</Text>
                                </Box>
                            </SimpleGrid>
                            <SimpleGrid columns={1} spacing={2} fontSize={'.8rem'}>
                                <Box>
                                    <Text color={'gray.500'}>Status:</Text>
                                    {taskStatus(task.status)}
                                </Box>
                            </SimpleGrid>
                        </Box>
                        <Text fontSize={'.8rem'}>{task.description}</Text>
                        <CommentsCountBadge
                            style={{ cursor: 'pointer' }}
                            onClick={onCommentsModalOpen}
                            mt={4}
                            onlyNumber={false}
                            count={task.comments.length}
                        />
                        <TaskCommentsModal
                            onRefresh={getTask}
                            isOpen={isCommentsModalOpen}
                            onClose={onCommentsModalClose}
                            comments={task.comments}
                            onError={(error) => {
                                toast({
                                    title: error,
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                })
                            }}
                        />
                        <AttachmentList
                            attachments={task.attachments}
                            onDownloadOrShare={(i) => { downloadAttachment(task.attachments[i]) }}
                            onView={(i) => { viewAttachment(task.attachments[i]) }}
                        />
                        <ResponsiblesList
                            assigners={task.assigners}
                            departments={task.departments}
                        />
                    </CardBody>
                    <TaskAssignConfirmModal />
                    <TaskClaimConfirmationModal />
                    <TaskFinishConfirmationModal />
                </Card>
            </Box>
        </Box>
    )
}