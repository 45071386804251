import { FileUpload, emptyFileUpload } from '@/app/types'
import { HStack, Image, Img, Input, Spinner, Square, Text, VStack } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import moment from 'moment';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';


type Props = {
    onFileLoad: (file: FileUpload) => void;
    isUploading?: boolean;
    onError: (error: string) => void;
}
export default function FileLoad({
    onFileLoad = () => { },
    onError,
    isUploading
}: Props) {
    const [loadedFile, setLoadedFile] = useState<FileUpload>(emptyFileUpload)
    // const isMobile = Capacitor.isNativePlatform();
    const isMobile = true;

    const handleTakePicture = async () => {
        try {
            const image = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Uri
            });

            const timeNowStr = moment().format('YYYYMMDDHHmmssSSS')
            const filename = `camera_${timeNowStr}.jpg`
            const response = await fetch(image.webPath);
            const blob = await response.blob();
            const imageSize = blob.size;

            setLoadedFile({
                ...loadedFile,
                base64: image.webPath,
                size: imageSize,
                original_name: filename,
                extension: 'jpg',
                content: new File([blob], filename, { type: 'image/jpeg' })
            });
        } catch (error) {
            if (error.message.includes('User cancelled photos app')) {
            } else {
                onError('Ocorreu um erro ao capturar a foto.');
            }
        }
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async () => {
            const base64 = reader.result as string;
            const imageSize = base64.length;
            const extension = file.name.split('.').pop() ?? '';
            setLoadedFile({
                ...loadedFile,
                base64,
                original_name: file.name,
                size: imageSize,
                extension,
                content: file
            });
        }

        reader.readAsDataURL(file);
    };

    const handleDropzone = async (
        _event: ProgressEvent<FileReader>,
        res: string | ArrayBuffer,
        file: File,
        error?: string | null) => {
        if (!!error) {
            onError(error)
            return;
        }

        const extension = file.name.match(/\.([^.]*)$/)[1]
        // const content = new File([file], file.name, { type: file.type })
        setLoadedFile({
            ...loadedFile,
            base64: res as string,
            original_name: file.name,
            size: file.size,
            extension: extension ?? '',
            content: file,
        })
    };

    type onDropHandler<T extends File> = (
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent
    ) => void

    const onDrop: onDropHandler<File> = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = (event) => handleDropzone(event, reader.result, file, null);
            reader.onerror = (event) => handleDropzone(event, reader.result, file, 'Ocorreu um erro ao anexar seu arquivo');
            reader.readAsDataURL(file);
        })
    }, [])

    useEffect(() => {
        if (loadedFile.base64) {
            onFileLoad(loadedFile)
            setLoadedFile(emptyFileUpload)
        }
    }, [loadedFile, onFileLoad])

    const { getRootProps, getInputProps, isDragActive: _isDragActive } = useDropzone({
        onDrop,
    })

    const MobileComponents = () => (
        <HStack>
            <Square
                maxH="150px"
                onClick={handleTakePicture}
                bg="gray.100"
                p={10}
                borderRadius={4}
                sx={{
                    transition: 'all .3s',
                    borderStyle: 'dashed',
                    borderColor: 'gray',
                    borderWidth: 2,
                }}
                cursor="pointer"
                flexDirection="column"
            >
                {isUploading ? (
                    <>
                        <Spinner />
                        <Text fontWeight="bold" color="gray.500" fontSize="md">Carregando...</Text>
                    </>
                ) : (
                    <>
                        <Image width={100} src="/vectors/undraw_camera_re_cnp4.svg" />
                        <Text fontWeight="bold" color="gray.500" fontSize="md">Imagem</Text>
                    </>
                )}
            </Square>
            <Square
                maxH="150px"
                as="label"
                htmlFor="file-upload"
                bg="gray.100"
                p={10}
                borderRadius={4}
                sx={{
                    transition: 'all .3s',
                    borderStyle: 'dashed',
                    borderColor: 'gray',
                    borderWidth: 2,
                }}
                cursor="pointer"
                flexDirection="column"
            >
                {isUploading ? (
                    <>
                        <Spinner />
                        <Text fontWeight="bold" color="gray.500" fontSize="md">Carregando...</Text>
                    </>
                ) : (
                    <>
                        <Image width={100} src="/vectors/undraw_add_files_re_v09g.svg" />
                        <Text fontWeight="bold" color="gray.500" fontSize="md">Documento</Text>
                    </>
                )}
            </Square>
            <Input
                type="file"
                accept="application/pdf,.doc,.docx,.xls,.xlsx"
                style={{ display: 'none' }}
                id="file-upload"
                onChange={handleFileUpload}
            />
        </HStack>
    )

    const DesktopComponents = () => (
        <div {...getRootProps()}>
            {!isUploading && <input {...getInputProps()} />}
            <Square
                my={4}
                bg="gray.100"
                p={10}
                borderRadius={4}
                sx={{
                    borderSpacing: '80rem',
                    transition: 'all .3s',
                    borderStyle: 'dashed',
                    borderColor: 'gray',
                    borderWidth: 2,
                    borderRadius: 4,
                }}
                cursor={'pointer'}
                flexDirection={'column'}
            >
                {isUploading ? (
                    <>
                        <Spinner />
                        <Text fontWeight={'bold'} color={'gray.500'} fontSize={'md'}>Carregando...</Text>
                    </>
                ) : (
                    <>
                        <Image width={100} src="/vectors/undraw_upload_re_pasx.svg" />
                        <Text fontWeight={'bold'} color={'gray.500'} fontSize={'md'}>Clique para adicionar um anexo</Text>
                    </>
                )}
            </Square>
        </div>
    )

    return isMobile ? <MobileComponents /> : <DesktopComponents />
}

