'use client'

import { routes } from "@/app/routes"
import ServiceItem from "@/components/settings/services/serviceItem"
import { requestGetServices } from "@/lib/api/services"
import { Service } from "@/lib/api/services.types"
import { Box, Button, Flex, VStack } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

export default function ServicesPage() {
    const [services, setServices] = useState<Service[]>([])

    useEffect(() => {
        requestGetServices().then((response) => {
            setServices(response.data)
        }).catch(console.error)
    }, [])


    return (
        <VStack w="full" h="full">
            <VStack overflowY={'scroll'} gap={2} h={
                {
                    base: "calc(100vh - 350px)",
                    lg: "calc(65vh)"
                }
            } w="full">
                {services.map(
                    (service, key) => (
                        <Box key={key} width={'full'}>
                            <Link to={`${routes.SERVICES}/${service.id}`}>
                                <ServiceItem
                                    onClick={() => { }}
                                    service={service}
                                />
                            </Link>
                        </Box>
                    )
                )}
            </VStack>

            <Flex w="full">
                <Button
                    as={Link}
                    to={routes.ADD_SERVICE}
                    width={'100%'}
                    colorScheme='blue'
                >
                    Cadastrar serviço
                </Button>
            </Flex>
        </VStack>
    )
}