'use client'

import { routes } from "@/app/routes";
import { Id } from "@/app/types";
import { requestCreateClinic, requestGetClinic, requestUpdateClinic } from "@/lib/api/clinic";
import { CreateClinicRequest, UpdateClinicRequest, createClinicRequestInitialValues } from "@/lib/api/clinic.types";
import { requestGetAddress } from "@/lib/api/utils";
import { SearchIcon } from "@chakra-ui/icons";
import { Stack, FormControl, FormErrorMessage, InputGroup, Input, InputRightElement, Icon, CardFooter, Button, Text, Box, HStack, useDisclosure, Link, VStack, Spacer, useToast, Progress, Heading, Spinner, IconButton, FormLabel } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState } from "react";

import * as Yup from 'yup';

export default function AddOrEditClinic() {
    const { id } = useParams();
    const isEdit = !!id;
    const [initialValues, setInitialValues] = useState<CreateClinicRequest | UpdateClinicRequest>(createClinicRequestInitialValues)
    const [isLoading, setIsLoading] = useState(isEdit ? true : false)
    const [zipCodeIsLoading, setZipCodeIsLoading] = useState(false)
    const navigate = useNavigate();
    const toast = useToast();

    const validationRules = Yup.object(
        {
            name: Yup.string().required('O nome da clínica é obrigatório'),
            address: Yup.string().required('O endereço da clínica é obrigatório'),
            city: Yup.string().required('A cidade da clínica é obrigatória'),
            state: Yup.string().required('O estado da clínica é obrigatório'),
            zip_code: Yup.string().required('O CEP da clínica é obrigatório'),
        }
    );

    const fillAddressByZipCode = (
        zipCode: string,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
        e?: any
    ) => {
        setZipCodeIsLoading(true)
        requestGetAddress(zipCode).then(({ data }) => {
            setFieldValue('address', data.street)
            setFieldValue('city', data.city)
            setFieldValue('state', data.state)
        }).catch(err => {
            toast({
                title: 'CEP inválido',
                status: 'error',
            })
            setFieldValue('zip_code', '')
            e && e.target.focus()
        })
            .finally(() => {
                setZipCodeIsLoading(false)
            })
    }

    useEffect(() => {
        if (isEdit) {
            requestGetClinic(id as Id).then((response) => {
                setInitialValues(response.data as UpdateClinicRequest)
            }).catch(err => {
                toast({
                    title: 'Erro ao buscar clínica',
                    status: 'error',
                })
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [id])

    if (isLoading) {
        return <Progress isIndeterminate />
    }
    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validateOnBlur
            validationSchema={validationRules}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                if (isEdit) {
                    requestUpdateClinic(id as Id, values as UpdateClinicRequest).then(({ data }) => {
                        toast({
                            title: 'Clínica atualizada com sucesso',
                            status: 'success',
                        })
                        navigate(routes.CLINICS)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao atualizar clínica',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                } else {
                    requestCreateClinic(values as CreateClinicRequest).then(({ data }) => {
                        toast({
                            title: 'Clínica criada com sucesso',
                            status: 'success',
                        })
                        navigate(routes.CLINICS)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao criar clínica',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                }
            }}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => (
                <Box>
                    <Heading size='md' mb={4}> {isEdit ? "Edição de clínica" : "Cadastro de clínica"}</Heading>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing='5'>
                            <FormControl isInvalid={touched.name && !!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='name'
                                        name="name"
                                        isInvalid={touched.name && !!errors.name}
                                        placeholder='Nome'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.name && !!errors.name && errors.name}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.document_number && !!errors.document_number}>
                                <FormLabel>CNPJ</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='document_number'
                                        name="document_number"
                                        isInvalid={touched.document_number && !!errors.document_number}
                                        placeholder='CNPJ'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.document_number} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.document_number && !!errors.document_number && errors.document_number}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                isDisabled={zipCodeIsLoading}
                                isInvalid={touched.zip_code && !!errors.zip_code}>
                                <FormLabel>CEP</FormLabel>
                                <InputGroup size='lg'>
                                    <InputRightElement>
                                        <IconButton
                                            isDisabled={zipCodeIsLoading || !values.zip_code}
                                            onClick={(e) => {
                                                !zipCodeIsLoading && fillAddressByZipCode(values.zip_code, setFieldValue, e)
                                            }}
                                            aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputRightElement>
                                    <Input
                                        type='zip_code'
                                        name="zip_code"
                                        isInvalid={touched.zip_code && !!errors.zip_code}
                                        placeholder='CEP'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.zip_code} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.zip_code && !!errors.zip_code && errors.zip_code}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.address && !!errors.address}>
                                <FormLabel>Endereço</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='address'
                                        name="address"
                                        isInvalid={touched.address && !!errors.address}
                                        placeholder='Endereço'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.address && !!errors.address && errors.address}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.city && !!errors.city}>
                                <FormLabel>Cidade</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='city'
                                        name="city"
                                        isInvalid={touched.city && !!errors.city}
                                        placeholder='Cidade'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.city} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.city && !!errors.city && errors.city}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.state && !!errors.state}>
                                <FormLabel>Estado</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='state'
                                        name="state"
                                        isInvalid={touched.state && !!errors.state}
                                        placeholder='Estado'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.state} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.state && !!errors.state && errors.state}
                                </FormErrorMessage>
                            </FormControl>
                        </Stack>
                        <Button
                            isDisabled={isSubmitting || !isValid}
                            isLoading={isSubmitting}
                            type='submit'
                            mt={10}
                            width={'100%'}
                            colorScheme='blue'
                        >
                            {isEdit ? 'Salvar' : 'Criar'}
                        </Button>
                    </form>
                </Box>
            )}
        </Formik>
    )
}