'use client'
import { Id } from "@/app/types";
import { Notification, formattedNotification } from "@/lib/api/notifications.types";
import { requestToggleNotifications } from "@/lib/api/user";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState, useSelector } from "@/lib/redux/store";
import { Center, Flex, Heading, Image, VStack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import NotificationCard from "./notificationCard";
import NotificationSwitch from "./notificationSwitch";

type NotificationsContentWebProps = {
    onClose: () => void;
    notifications: Notification[];
    onNotificationClick: (id: Id) => void;
}

export default function NotificationsContentWeb({ onClose, notifications, onNotificationClick }: NotificationsContentWebProps) {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const toast = useToast();
    const [notifiable, setNotifiable] = useState(!!auth?.userInfo?.notifiable);
    const hasNotifications = notifications.length > 0;

    const notificationRequestPermission = (checked) => {
        if (!checked) {
            toggleNotification(false);
            return;
        }

        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        } else {
            window.Notification.requestPermission().then(
                () => {
                    toggleNotification(true);
                }
            );
        }
    }

    const toggleNotification = (checked) => {
        requestToggleNotifications(checked).then((response) => {
            setNotifiable(response.data.notifiable);
        }).catch((err) => {
            toast({
                title: `Erro ao alterar notificações`,
                status: 'error',
            })
        });
    }
    return <>
        <NotificationSwitch
            isChecked={notifiable}
            onChange={notificationRequestPermission}
        />
        {!hasNotifications ? (
            <Center>
                <VStack>
                    <Image my={10} width={100} src="/vectors/undraw_void_-3-ggu.svg" />
                    <Heading size='md' mb={10}>
                        Você não tem notificações.
                    </Heading>
                </VStack>
            </Center>
        ) : (
            <Flex mt={10} flexDirection="column" maxH={'500px'} overflowY={'scroll'}>
                {notifications.map((notification, key) => {
                    const formatted = formattedNotification(notification)
                    return (<NotificationCard
                        key={key}
                        notification={formatted}
                        onDetailsClick={(id) => {
                            onNotificationClick(id);
                            onClose()
                        }}
                    />)
                })}
            </Flex>
        )}
    </>
}