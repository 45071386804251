import priorityText, { priorityColor } from "@/utils/translators";
import { Tag, TagLabel } from "@chakra-ui/react";
type Props = {
    priority: string
    onlyIcon?: boolean
}
export default function PriorityBadge({ priority, onlyIcon=false }: Props) {
    {
        return (
            <Tag size='md' colorScheme={priorityColor(priority)} borderRadius='full'>
                {onlyIcon ? null : <TagLabel>{priorityText(priority)}</TagLabel>}
            </Tag>
        )
    }
}