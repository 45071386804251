// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';
import SidebarBrand from './brand';
import SidebarLinks from './links';


function SidebarContent(props) {
	const { routes } = props;
	return (
		<Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
			<SidebarBrand />
			<Stack direction='column' mt='8px' mb='auto'>
				<Box
					ps='10px'
					pe={{ lg: '16px', '2xl': '16px' }}
					mt={5}
				>
					<SidebarLinks routes={routes} />
				</Box>
			</Stack>
		</Flex>
	);
}

export default SidebarContent;