import { Avatar, Box, Card, CardBody, Flex, Spacer, Tag, TagLabel, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { User } from "@/lib/api/user.types";

type Props = {
    user: User
    isChecked?: boolean
    onClick?: (user: User) => void
}

export default function UserItem({
    user,
    isChecked = false,
    onClick = () => { }
}: Props) {
    return (
        <Card
            width={'100%'}
            cursor={'pointer'}
            onClick={() => {
                onClick && onClick(user)
            }}
        >
            <CardBody>
                <Flex maxWidth='500px' alignItems='center' gap='2'>
                    <Avatar size="sm" name={user.name} src={user.picture} />
                    <Text>{user.name}</Text>
                    <Spacer />
                    <Box p='2'>
                        {isChecked && (
                            <CheckCircleIcon color={'blue.400'} />
                        )}
                    </Box>
                </Flex>
            </CardBody>
        </Card>
    )
}