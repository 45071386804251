'use client'

import { AuthStateProps, setAuthState } from "@/lib/redux/slices/auth/slice";
import { IRootState } from "@/lib/redux/store";
import { Avatar, Box, Button, Card, CardBody, FormControl, HStack, Heading, Icon, Spacer, Text, VStack, useColorMode } from "@chakra-ui/react";
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { requestLogout } from "@/lib/api/login";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "@/app/routes";
import { Me } from "@/lib/api/user.types";

type Props = {}

export default function ProfileSettingsPage(props: Props) {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const [userInfo, setUserInfo] = useState<Me>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { colorMode, toggleColorMode } = useColorMode();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.userInfo?.name) {
            setUserInfo(auth.userInfo)
        }
        return () => { }
    }, [])

    if (!userInfo) return null;


    return (
        <Card>
            <CardBody>
                <Box>
                    <VStack spacing={4} mb={4} textAlign={'center'}>
                        <Avatar size='lg' name={userInfo?.name} src={userInfo?.picture} />
                        <VStack alignItems={'left'}>
                            <Heading size='md'>{userInfo?.name}</Heading>
                            <Text size='sm'>{userInfo?.email}</Text>
                            <FormControl>
                                <Button
                                    onClick={toggleColorMode}
                                    leftIcon={<Icon as={colorMode === 'light' ? IoMdMoon : IoMdSunny} />}
                                >
                                    {colorMode === 'light' ? 'Apagar a luz' : 'Acender a luz'}
                                </Button>
                            </FormControl>
                        </VStack>
                    </VStack>
                    <HStack>
                        <Spacer />
                        <Button
                            isLoading={isLoading}
                            leftIcon={<IoLogOutOutline />}
                            colorScheme={'blue'}
                            onClick={() => {
                                setIsLoading(true)
                                requestLogout().finally(() => {
                                    setIsLoading(false)
                                    dispatch(setAuthState({
                                        success: false,
                                        userToken: null,
                                        error: false,
                                    }))
                                    navigate(routes.LOGIN);
                                    return;
                                })
                            }}
                        >
                            Sair
                        </Button>
                    </HStack>
                </Box>
            </CardBody>
        </Card>
    )
}