import { Id } from "@/app/types";
import { Task } from "@/lib/api/task.types";
import { createContext, useContext } from "react";

const TaskFiltersContext = createContext(null);

export default TaskFiltersContext;

export type TaskFiltersType = {
    created_by?: Id,
    assigner?: Id,
    status?: string,
    priority?: string,
    service?: Id,
    clinic?: Id,
    department?: Id,
    search?: string,
    due_date_from?: string,
    due_date_to?: string,
    created_at_from?: string,
    created_at_to?: string,
    page: number,
}

export const emptyTaskFilters: TaskFiltersType = {
    page: 1,
}

export type TaskFiltersContextType = {
    setFilters: (filters: TaskFiltersContextType) => void,
    filters: TaskFiltersContextType
}

export function useTaskFiltersContext() {
    return useContext<TaskFiltersContextType>(TaskFiltersContext)
};