
import { Attachment } from "./attatchment.types"
import { Assignee } from "./assigners.types"
import { Id } from "../../app/types"
import { User } from "./user.types"
import { Comment } from "./comment.types"
import { Department } from "./departments.types"
import moment from "moment"
import { priorities, priorityMap } from "@/utils/translators"
import { Clinic } from "./clinic.types"


export type TaskResponse = {
    current_page: number
    data: Task[]
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    links: any[]
    next_page_url: string
    path: string
    per_page: number
    to: number
    total: number
}

export type Task = {
    id: Id
    title: string
    description: string
    due_date: string
    status: string
    priority: string
    clinic: Clinic
    service_id: number | null
    assigners: Assignee[]
    departments: Department[]
    comments: Comment[]
    attachments: Attachment[]
    created_by: string
    updated_by: string
    created_at: string
    updated_at: string
    created_by_user: User
    comments_count: number
    attachments_count: number
    can_finish: boolean
    can_claim: boolean
    can_assign: boolean
    can_edit: boolean
    can_delete: boolean
}

export type TaskCreateRequest = {
    clinic_id: Id | null
    title: string
    description: string
    due_date: string
    status: string
    priority: string
    service_id: number | null
    is_service: boolean
    type_id: number | null
    assigners: Assignee[]
    departments: Department[]
    attachments: Attachment[]
}

export const emptyTaskCreateRequest: TaskCreateRequest = {
    clinic_id: null,
    title: '',
    description: '',
    due_date: moment().add(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
    status: 'new',
    priority: 'medium',
    service_id: null,
    is_service: false,
    type_id: null,
    assigners: [],
    departments: [],
    attachments: []
}

export type TaskFinishRequest = {
    id: Id
    comment: string
    attachments: Attachment[]
}

export type TaskFiltersSelectOptionType = {
    value: string | number
    label: string
}
export type TaskFiltersOptions = {
    created_by: TaskFiltersSelectOptionType[]
    assigner: TaskFiltersSelectOptionType[]
    status: TaskFiltersSelectOptionType[]
    priority: TaskFiltersSelectOptionType[]
    service: TaskFiltersSelectOptionType[]
    clinic: TaskFiltersSelectOptionType[]
    department: TaskFiltersSelectOptionType[]
}