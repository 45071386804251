'use client'

import { routes } from "@/app/routes";
import { Id } from "@/app/types";
import { requestCreateDepartment, requestGetDepartment, requestGetDepartments, requestUpdateDepartment } from "@/lib/api/departments";
import { CreateDepartmentRequest, Department, UpdateDepartmentRequest, createDepartmentRequestInitialValues } from "@/lib/api/departments.types";
import { Stack, FormControl, FormErrorMessage, InputGroup, Input, Button, Box, useToast, Progress, Heading, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Formik } from "formik";
import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState } from "react";

import * as Yup from 'yup';

export default function AddOrEditDepartment() {
    const { id } = useParams();
    const isEdit = !!id;
    const [initialValues, setInitialValues] = useState<CreateDepartmentRequest | UpdateDepartmentRequest>(createDepartmentRequestInitialValues)
    const [departments, setDepartments] = useState<Department[]>([])
    const [isLoading, setIsLoading] = useState(isEdit ? true : false)
    const navigate = useNavigate();
    const toast = useToast();

    const validationRules = Yup.object(
        {
            name: Yup.string().required('O nome do departamento é obrigatório'),
            description: Yup.string().required('A descrição do departamento é obrigatória'),
        }
    );


    useEffect(() => {
        if (isEdit) {
            requestGetDepartment(id as Id).then((response) => {
                setInitialValues(response.data as UpdateDepartmentRequest)
            }).catch(err => {
                toast({
                    title: 'Erro ao buscar departamento',
                    status: 'error',
                })
            }).finally(() => {
                setIsLoading(false)
            })
        }

        requestGetDepartments().then((response) => {
            setDepartments(response.data)
        })
    }, [id])

    if (isLoading) {
        return <Progress isIndeterminate />
    }
    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validateOnBlur
            validationSchema={validationRules}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                if (isEdit) {
                    requestUpdateDepartment(id as Id, values as UpdateDepartmentRequest).then(({ data }) => {
                        toast({
                            title: 'Departamento atualizado com sucesso',
                            status: 'success',
                        })
                        navigate(routes.DEPARTMENTS)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao atualizar departamento',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                } else {
                    requestCreateDepartment(values as CreateDepartmentRequest).then(({ data }) => {
                        toast({
                            title: 'Departamento criado com sucesso',
                            status: 'success',
                        })
                        navigate(routes.CLINICS)
                    }).catch(err => {
                        toast({
                            title: 'Erro ao criar departamento',
                            status: 'error',
                        })
                    }).finally(() => {
                        setSubmitting(false)
                    })
                }
            }}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
            }) => (
                <Box>
                    <Heading size='md' mb={4}> {isEdit ? "Edição de departamento" : "Cadastro de departamento"}</Heading>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing='5'>
                            <FormControl isInvalid={touched.name && !!errors.name}>
                                <FormLabel>Nome</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='name'
                                        name="name"
                                        isInvalid={touched.name && !!errors.name}
                                        placeholder='Nome'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.name && !!errors.name && errors.name}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={touched.description && !!errors.description}>
                                <FormLabel>Descrição</FormLabel>
                                <InputGroup size='lg'>
                                    <Input
                                        type='description'
                                        name="description"
                                        isInvalid={touched.description && !!errors.description}
                                        placeholder='Descrição'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description} />
                                </InputGroup>
                                <FormErrorMessage>
                                    {touched.description && !!errors.description && errors.description}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Departamentos superiores</FormLabel>
                                <Select
                                    name="parents"
                                    isMulti
                                    placeholder="Selecione um ou mais departamentos acima deste"
                                    onChange={
                                        (values) => {
                                            setFieldValue('parents', values?.map((value) => {
                                                return {
                                                    id: value.value,
                                                    name: value.label
                                                }
                                            }))
                                        }
                                    }
                                    options={departments.map((department) => {
                                        return {
                                            value: department.id,
                                            label: department.name
                                        }
                                    })}
                                    value={
                                        values.parents?.map((child) => {
                                            return {
                                                value: child.id,
                                                label: child.name
                                            }
                                        })
                                    }
                                />
                            </FormControl>
                        </Stack>
                        <Button
                            isDisabled={isSubmitting || !isValid}
                            isLoading={isSubmitting}
                            type='submit'
                            mt={10}
                            width={'100%'}
                            colorScheme='blue'
                        >
                            {isEdit ? 'Salvar' : 'Criar'}
                        </Button>
                    </form>
                </Box>
            )}
        </Formik>
    )
}