import { Id } from "@/app/types"
import { Message } from "@/lib/api/messages.types"
import { Avatar, Box, HStack, Spacer, Text, VStack, useColorModeValue } from "@chakra-ui/react"
import ConfirmationIcon from "./confirmationIcon"
import moment from "moment"

type MessageBalloonProps = {
    message: Message
    currentUserId: Id
}

export default function MessageBalloon({ message, currentUserId }: MessageBalloonProps) {
    const isCurrentUserMessage = message.sender.id === currentUserId
    const currentUserCardColor = useColorModeValue('gray.100', 'gray.600')
    const otherUserCardColor = useColorModeValue('blue.100', 'blue.700')
    const cardColor = isCurrentUserMessage ? currentUserCardColor : otherUserCardColor
    const messageAlign = isCurrentUserMessage ? 'flex-end' : 'flex-start';
    const borderRadiusStyle = isCurrentUserMessage ? '24px 0px 24px 24px' : '0px 24px 24px 24px';
    const borderBottomRightRadiusStyle = isCurrentUserMessage ? '0 24px 24px 24px' : '24px 24px 24px 0';

    const formattedCreatedAt = moment(message?.created_at).format('HH:mm:ss')
    const createdAtColor = useColorModeValue('gray.500', 'gray.300')
    return (
        <HStack
            width={'100%'}
            justifyContent={messageAlign}
        >
            {!isCurrentUserMessage && <Avatar size="sm" name={message.sender.name} />}
            <VStack>
                <Box
                    px={4}
                    py={2}
                    bgColor={cardColor}
                    alignItems={messageAlign}
                    borderRadius={borderRadiusStyle}

                    borderBottomRightRadius={borderBottomRightRadiusStyle}
                >
                    <Text>{message.content}</Text>
                    <HStack>
                        <Spacer />
                        <ConfirmationIcon message={message} />
                        <Text
                            fontSize={'.7rem'}
                            color={createdAtColor}
                        >
                            {formattedCreatedAt}
                        </Text>
                    </HStack>
                </Box>
            </VStack>
            {isCurrentUserMessage && <Avatar size="sm" name={message.sender.name} />}
        </HStack>
    )
}
