"use client";
import ChatModal from '@/components/messages/chatModal';
import { useChatContext } from '@/contexts/chatContext';
import { Center, Heading, Image, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


import { useEffect, useState } from 'react';

export default function ConversationsPage() {
    const navigate = useNavigate();
    const { viewType } = useChatContext();
    const [isClient, setIsClient] = useState(false)

    const msgChatSvgSize = {
        sm: 200,
        md: 400,
    }
    useEffect(() => {
        setIsClient(true)
    }, [])

    if (!isClient) return null;

    switch (viewType) {
        case 'desktop':
            return (
                <Center>
                    <VStack>
                        <Heading size="md" my={5}>
                            Nenhuma mensagem selecionada
                        </Heading>
                        <Image
                            src={"/vectors/undraw_chat_re_re1u.svg"}
                            width={msgChatSvgSize}
                            height={msgChatSvgSize}
                        />
                    </VStack>
                </Center>
            );
        case 'mobile':
            return <ChatModal
                onClose={() => {
                    navigate(-1);
                }}
            />
        default:
            return <></>
    }
}
