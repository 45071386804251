'use client'
import { routes } from "@/app/routes";
import { Id } from "@/app/types";
import AttachmentList from "@/components/attachments/attachmentList";
import FileLoad from "@/components/fileUpload/fileLoad";
import ResponsiblesList from "@/components/responsibles/responsiblesList";
import { Assignee } from "@/lib/api/assigners.types";
import {
    requestCreateAttachment
} from "@/lib/api/attachments";
import { Attachment } from "@/lib/api/attatchment.types";
import { requestGetServices } from "@/lib/api/services";
import { Service } from "@/lib/api/services.types";
import { requestCreateTask, requestGetTaskById, requestUpdateTask } from "@/lib/api/task";
import { Task, TaskCreateRequest, emptyTaskCreateRequest } from "@/lib/api/task.types";
import { arrayColumn, downloadAttachment, viewAttachment } from "@/lib/api/utils";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState, useSelector } from "@/lib/redux/store";
import priorityText, { priorities, priorityColor } from "@/utils/translators";
import { CalendarIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Textarea,
    useToast
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Field, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';

type Props = {
    id?: Id
}

export default function TaskAddOrEditPage({ id }: Props) {
    const toast = useToast()
    const navigate = useNavigate();
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const [isUploading, setIsUploading] = useState(false);
    const [services, setServices] = useState<Service[]>([]);
    const [task, setTask] = useState<Task>();
    const isEdit = !!id;

    useEffect(() => {
        requestGetServices().then(res => {
            setServices(res.data)
        }).catch((err) => {
            console.log(err)
        })

        if (!isEdit) {
            return;
        }

        requestGetTaskById(id).then(res => {
            setTask(res.data)
        })
    }, [id])

    if (!task && isEdit) {
        return null;
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('O título é obrigatório'),
        description: Yup.string().required('A descrição é obrigatória'),
        priority: Yup.string().required('Você precisa preencher uma prioridade'),
        due_date: Yup.string().required('Você precisa preencher uma data de vencimento').test(
            'date-is-valid',
            'A data de vencimento não pode ser no passado',
            (value) => {
                const dueDate = moment(value);
                const now = moment();
                return dueDate.isAfter(now);
            }
        ),
        assigners: Yup.array().test('at-least-one', 'Você precisa direcionar sua tarefa para um responsável ou um departamento ', function (value) {
            const { assigners, departments } = this.parent;
            return assigners.length > 0 || departments.length > 0;
        })
    });

    return <Formik
        initialValues={isEdit ? task : emptyTaskCreateRequest}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={(values: Task | TaskCreateRequest, { setSubmitting }) => {
            if (isEdit) {
                const task = values as Task
                requestUpdateTask(id as string, task).then(res => {
                    toast({
                        title: 'Tarefa atualizada com sucesso',
                        status: 'success',
                    })
                    setSubmitting(false);
                    navigate(routes.TASKS)
                }).catch((err) => {
                    toast({
                        title: 'Erro ao atualizar tarefa',
                        status: 'error',
                    })
                }).finally(() => {
                    setSubmitting(false);
                })
            } else {
                const task = values as TaskCreateRequest
                const userClinic = auth.userClinic?.clinic
                task.clinic_id = userClinic?.id
                requestCreateTask(task).then(res => {
                    toast({
                        title: 'Tarefa criada com sucesso',
                        status: 'success',
                    })
                    setSubmitting(false);
                    navigate(routes.TASKS)
                }).catch((err) => {
                    toast({
                        title: 'Erro ao criar tarefa',
                        status: 'error',
                    })
                }).finally(() => {
                    setSubmitting(false);
                })
            }
        }}
    >
        {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid
        }) => {

            const selectedService = services.find((service) => service.id === values.service_id);
            const noService = {
                label: 'Nenhum',
                value: '' as Id
            }

            return (
                <Box maxWidth={'1000px'} width="100%">
                    <form onSubmit={handleSubmit}>
                        <SimpleGrid
                            columns={{
                                xl: 2
                            }}
                            spacing={4}
                        >
                            <Box>
                                <FormLabel my={4} size={'md'}>Data de vencimento</FormLabel>
                                <FormControl isInvalid={!!errors.due_date}>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <CalendarIcon color='gray.300' />
                                        </InputLeftElement>
                                        <Input
                                            name="due_date"
                                            id="due_date"
                                            type="datetime-local"
                                            value={values.due_date}
                                            placeholder="Escolha uma data e hora"
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {!!errors.due_date && errors.due_date}
                                    </FormErrorMessage>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormLabel my={4} size={'md'}>Prioridade</FormLabel>
                                <FormControl isInvalid={!!errors.priority}>
                                    <HStack mt={2} spacing={3}>
                                        <RadioGroup
                                            defaultValue={values.priority}
                                            onChange={(e) => {
                                                setFieldValue('priority', e);
                                            }}
                                        >
                                            <Stack spacing={5} direction='row'>
                                                {priorities.map((priority, i) => {

                                                    return (
                                                        <Radio key={i} colorScheme={priorityColor(priority)} value={priority}>
                                                            {priorityText(priority)}
                                                        </Radio>
                                                    )
                                                })}
                                            </Stack>
                                        </RadioGroup>
                                    </HStack>
                                    <FormErrorMessage>
                                        {!!errors.priority && errors.priority}
                                    </FormErrorMessage>
                                </FormControl>
                            </Box>
                        </SimpleGrid>
                        <FormControl isInvalid={!!errors.service_id}>
                            <FormLabel my={4} size='md'>Serviço</FormLabel>
                            <Select
                                onChange={(e) => {
                                    setFieldValue('service_id', e.value)
                                    if (e.value) {
                                        setFieldValue('title', e.label)
                                    } else {
                                        if (arrayColumn(services, 'name').includes(e.label)) {
                                            setFieldValue('title', '')
                                        }
                                    }
                                }}
                                placeholder={'Selecione um serviço'}
                                value={
                                    !!selectedService ?
                                        {
                                            value: selectedService.id as Id,
                                            label: selectedService.name
                                        } : noService}
                                options={
                                    [
                                        ...[noService],
                                        ...services.map((service) => {
                                            return {
                                                value: service.id,
                                                label: service.name
                                            }
                                        })
                                    ]
                                }
                            />
                            <FormErrorMessage>
                                {!!errors.service_id && errors.service_id}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.title}>
                            <FormLabel my={4} size='md'>Título</FormLabel>
                            <Input
                                as={Field}
                                disabled={!!values.service_id}
                                name="title"
                                id="title"
                                variant='outline'
                                placeholder='Um título curto'
                                onChange={handleChange}
                            />
                            <FormErrorMessage>
                                {!!errors.title && errors.title}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.description}>
                            <FormLabel my={4} size='md'>Descrição</FormLabel>
                            <Textarea
                                as={Field}
                                name="description"
                                id="description"
                                onChange={handleChange}
                                placeholder='Descreva mais a tarefa que você está criando'
                                size='md'
                                mt={4}
                                resize='none'
                                minHeight={100}
                                focusBorderColor='blue.500'
                            />
                            <FormErrorMessage>
                                {!!errors.description && errors.description}
                            </FormErrorMessage>
                        </FormControl>
                        <FormLabel my={4} size='md'>Anexar arquivo</FormLabel>
                        <FileLoad
                            isUploading={isUploading}
                            onError={(error) => {
                                toast({
                                    title: error,
                                    status: 'error',
                                })
                            }}
                            onFileLoad={(file) => {
                                setIsUploading(true)
                                requestCreateAttachment(file.content)
                                    .then(res => {
                                        res.data.forEach((a: any) => {
                                            const updatedFile = { ...file, ...a }
                                            setFieldValue('attachments', [...values.attachments, updatedFile as Attachment])
                                        })
                                    })
                                    .catch(error => {
                                        console.error("UPLOAD_ATTACHMENT|", JSON.stringify(error))
                                        toast({
                                            title: 'Ocorreu um erro ao enviar seu anexo',
                                            status: 'error',
                                        })
                                    })
                                    .finally(() => {
                                        setIsUploading(false);
                                    })
                            }}
                        />
                        <AttachmentList
                            hideHeader
                            enableDelete
                            enableProgress
                            enableCheckIcon
                            enableDownload={false}
                            attachments={values.attachments}
                            onDelete={(i) => {
                                const removeAttachment = async () => { }
                                removeAttachment().then(() => {
                                    setFieldValue('attachments', values.attachments.filter((a, index) => index !== i))
                                });

                            }}
                            onDownloadOrShare={(i) => { downloadAttachment(values.attachments[i]) }}
                            onView={(i) => { viewAttachment(values.attachments[i]) }}
                        />
                        <FormControl isInvalid={!!errors.assigners}>
                            <ResponsiblesList
                                assigners={values.assigners}
                                departments={values.departments}
                                withAddButton
                                onAddAssignee={(assignee: Assignee) => {
                                    setFieldValue('assigners', [...values.assigners, assignee])
                                }}
                                onRemoveAssignee={(assignee: Assignee) => {
                                    setFieldValue('assigners', values.assigners.filter(a => a.id !== assignee.id) as Assignee[])
                                }}
                                onAddDepartment={(department: any) => {
                                    setFieldValue('departments', [...values.departments, department])
                                }}
                                onRemoveDepartment={(department: any) => {
                                    setFieldValue('departments', values.departments.filter(d => d.id !== department.id) as any[])
                                }}
                            />

                            <FormErrorMessage>
                                {Boolean(errors.assigners) && errors.assigners as string}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            my={4}
                            isLoading={isSubmitting}
                            isDisabled={!isValid}
                            type='submit'
                            size='lg'
                            w='100%'
                            h='50px'
                            borderRadius={10}
                            variant='solid'
                            colorScheme='blue'
                        >
                            {isEdit ? 'Salvar' : 'Criar'}
                        </Button>
                    </form>
                </Box>
            )
        }}
    </Formik>

}