import { Id } from "@/app/types";
import { useChatContext } from "@/contexts/chatContext";
import { Notification } from "@/lib/api/notifications.types";
import { requestGetMe, requestMarkAllNotificationsAsRead, requestMarkNotificationAsRead } from "@/lib/api/user";
import { AuthStateProps, setAuthState } from "@/lib/redux/slices/auth/slice";
import { IRootState, useDispatch, useSelector } from "@/lib/redux/store";
import { Box, Button, Icon, Menu, MenuButton, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdNotificationsNone } from "react-icons/md";
import NotificationsContentWeb from "./notificationContentWeb";
import NotificationsContentMobile from "./notificationContentMobile";
import { Capacitor } from "@capacitor/core";
import { SAFE_AREA_TOP_CSS } from "@/app/config";

export default function NotificationModal() {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const toast = useToast();
    const dispatch = useDispatch();
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
    );

    const { windowIsReady } = useChatContext();

    const currentUser = auth.userInfo;
    const currentUserId = currentUser?.id as Id;
    const [notifications, setNotifications] = useState<Notification[]>(auth.userInfo?.notifications ?? [])
    const hasNotifications = notifications.length > 0;

    const fetchUserInfo = () => {
        requestGetMe().then((response) => {
            setNotifications(response.data.notifications);
            dispatch(setAuthState({
                ...auth,
                userInfo: response.data,
                notifications: response.data.notifications,
            }))
        }).catch((err) => {
            console.log(err)
        })
    }

    const markAllAsRead = () => {
        requestMarkAllNotificationsAsRead().then((response) => {
            toast({
                title: `Notificações marcadas como lidas`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setNotifications(response.data.notifications);
        })
    }

    const markNotificationAsRead = (id: Id) => {
        requestMarkNotificationAsRead(id).then((response) => {
            setNotifications(response.data.notifications);
        }).catch((err) => { console.log(err) })
    }

    const notificationsChannel = `App.Models.User.${currentUserId}`;

    const connectWebSocket = () => {
        window.Echo.private(notificationsChannel)
            .notification(() => {
                fetchUserInfo();
            });
    }

    useEffect(() => {
        if (!windowIsReady) {
            return;
        }
        if (window.Echo === undefined)
            return;

        fetchUserInfo();
        connectWebSocket();

        return () => {
            window.Echo.leave(notificationsChannel);
        }
    }, [windowIsReady, currentUserId]);


    const NotificationButton = () => (
        <Box
            position="relative"
        >
            <Icon
                mt="6px"
                as={MdNotificationsNone}
                w="18px"
                h="18px"
                me="10px"
            />

            {hasNotifications && (
                <Box
                    position="absolute"
                    bottom="0"
                    right="0"
                    bg="red"
                    color="white"
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                    fontSize="8px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding="2px"
                >
                    {notifications.length}
                </Box>
            )}
        </Box>
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    const isMobile = Capacitor.isNativePlatform();

    return (<>
        <Box display={{ base: 'none', lg: 'block' }} >
            <Menu isLazy>
                {({ onClose }) => {
                    return (
                        <>
                            <MenuButton p="0px">
                                <NotificationButton />
                            </MenuButton>
                            <MenuButton>
                            </MenuButton>
                            <MenuList
                                boxShadow={shadow}
                                p={4}
                                borderRadius="20px"
                                border={"1px solid rgba(0, 0, 0, 0.15)"}
                                mt="22px"
                                me={{ base: '30px', md: 'unset' }}
                                minW={{ base: 'unset', md: '400px', xl: '450px' }}
                            >
                                {
                                    isMobile ? (
                                        <NotificationsContentMobile
                                            onClose={() => {
                                                fetchUserInfo();
                                                onClose();
                                            }}
                                            onNotificationClick={markNotificationAsRead}
                                            notifications={notifications}
                                        />
                                    ) : (
                                        <NotificationsContentWeb
                                            onClose={() => {
                                                fetchUserInfo();
                                                onClose();
                                            }}
                                            onNotificationClick={markNotificationAsRead}
                                            notifications={notifications}
                                        />
                                    )
                                }

                            </MenuList>
                        </>
                    )
                }}
            </Menu>
        </Box>
        <Box display={{ base: 'block', lg: 'none' }} >
            <>
                <Box onClick={onOpen} >
                    <NotificationButton />
                </Box>

                <Modal
                    size={{
                        base: 'full',
                    }}
                    isOpen={isOpen}
                    onClose={() => {
                        fetchUserInfo();
                        onClose();
                    }}
                >
                    <ModalOverlay />
                    <ModalContent pt={SAFE_AREA_TOP_CSS}>
                        <ModalHeader>Notificações</ModalHeader>
                        <ModalCloseButton
                            position={'fixed'}
                            top={`calc(1rem+${SAFE_AREA_TOP_CSS})`}
                            right={'1rem'}
                            size='lg'
                            zIndex={2}
                        />
                        <ModalBody>
                            {isMobile ? (
                                <NotificationsContentMobile
                                    onClose={onClose}
                                    onNotificationClick={markNotificationAsRead}
                                    notifications={notifications}
                                />) : (
                                <NotificationsContentWeb
                                    onClose={onClose}
                                    onNotificationClick={markNotificationAsRead}
                                    notifications={notifications}
                                />
                            )}
                        </ModalBody>
                        <ModalFooter gap={4}>
                            {hasNotifications && (
                                <Button
                                    variant='ghost'
                                    onClick={() => {
                                        markAllAsRead();
                                        onClose();
                                    }}
                                >
                                    Marcar todas como lidas
                                </Button>
                            )}
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        </Box>
    </>)
}