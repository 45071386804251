'use client'
import { Id } from "@/app/types";
import { Notification, formattedNotification } from "@/lib/api/notifications.types";
import { requestToggleNotifications } from "@/lib/api/user";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState, useSelector } from "@/lib/redux/store";
import { Center, Flex, Heading, Image, VStack, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import NotificationCard from "./notificationCard";
import NotificationSwitch from "./notificationSwitch";

import { PushNotifications, Token } from '@capacitor/push-notifications';


type NotificationsContentMobileProps = {
    onClose: () => void;
    notifications: Notification[];
    onNotificationClick: (id: Id) => void;
}

export default function NotificationsContentMobile({ onClose, notifications, onNotificationClick }: NotificationsContentMobileProps) {
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const toast = useToast();
    const [notifiable, setNotifiable] = useState(!!auth?.userInfo?.notifiable);
    const hasNotifications = notifications.length > 0;

    const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();
      
        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }
      
        if (permStatus.receive !== 'granted') {
          console.log('User denied permissions!');
        }
      
        await PushNotifications.register();
      }

      
    useEffect(() => {
        PushNotifications.addListener('registration', (token: Token) => {
            console.log('Push registration success, token: ', token.value);
        });
        
        PushNotifications.addListener('registrationError', (error: any) => {
            console.error('Push registration error: ', error);
            toast({
                title: `Erro ao registrar notificações`,
                status: 'error',
            });
        });

        PushNotifications.addListener('pushNotificationReceived', (notification: any) => {
            console.log('Push notification received: ', notification);
        });

        
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) => {
            console.log('Push notification action performed: ', notification);
            onNotificationClick(notification.notification.data.id);
            onClose();
        });
    }, []);

    const notificationRequestPermission = async (checked) => {
        if (checked) {
            await registerNotifications()
        }
        
        toggleNotification(checked);
    }

    const toggleNotification = (checked) => {
        requestToggleNotifications(checked).then((response) => {
            setNotifiable(response.data.notifiable);
        }).catch((err) => {
            toast({
                title: `Erro ao alterar notificações`,
                status: 'error',
            })
        });
    }
    return <>
        <NotificationSwitch
            isChecked={notifiable}
            onChange={notificationRequestPermission}
        />
        {!hasNotifications ? (
            <Center>
                <VStack>
                    <Image my={10} width={100} src="/vectors/undraw_void_-3-ggu.svg" />
                    <Heading size='md' mb={10}>
                        Você não tem notificações.
                    </Heading>
                </VStack>
            </Center>
        ) : (
            <Flex mt={10} flexDirection="column" maxH={'500px'} overflowY={'scroll'}>
                {notifications.map((notification, key) => {
                    const formatted = formattedNotification(notification)
                    return (<NotificationCard
                        key={key}
                        notification={formatted}
                        onDetailsClick={(id) => {
                            onNotificationClick(id);
                            onClose()
                        }}
                    />)
                })}
            </Flex>
        )}
    </>
}