import { Avatar, Box, Card, CardBody, Flex, Spacer, Tag, TagLabel, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Service } from "@/lib/api/services.types";

type Props = {
    service: Service
    isChecked?: boolean
    onClick?: (service: Service) => void
}

export default function ServiceItem({
    service,
    isChecked = false,
    onClick = () => { }
}: Props) {
    return (
        <Card
            width={'100%'}
            cursor={'pointer'}
            onClick={() => {
                onClick && onClick(service)
            }}
        >
            <CardBody>
                <Flex maxWidth='500px' alignItems='center' gap='2'>
                    <Text>{service.name}</Text>
                    <Spacer />
                    <Box p='2'>
                        {isChecked && (
                            <CheckCircleIcon color={'blue.400'} />
                        )}
                    </Box>
                </Flex>
            </CardBody>
        </Card>
    )
}