import { requestCheckPermission } from "@/lib/api/permissions";
import { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import {IRootState} from "@/lib/redux/store";
import {AuthStateProps} from "@/lib/redux/slices/auth/slice";

type Props = {
    children: JSX.Element,
    scope: string
}

export default function PermissionGate(props: Props) {
    const { scope, children }: Props = props;
    const [hasPermission, setHasPermission] = useState(false);
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const appPermissions = auth?.userInfo?.app_permissions ?? [];

    useEffect(() => {
        if (appPermissions.includes(scope)) {
            setHasPermission(true);
        }
    }, [appPermissions]);

    if (!hasPermission) return null;

    return (
        <>{children}</>
    )
}