import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Política de Privacidade</h1>

      <h2>1. Coleta e Uso de Dados</h2>
      <p>Armazenamos apenas o e-mail dos usuários para possibilitar o login e a criação de contas pelos gerentes da empresa. Não coletamos outros dados pessoais nem compartilhamos informações com terceiros.</p>

      <h2>2. Permissões Necessárias</h2>
      <ul>
        <li><strong>Arquivos e Câmera:</strong> Para anexar fotos às tarefas.</li>
        <li><strong>Notificações:</strong> Para enviar atualizações relevantes.</li>
        <li><strong>Internet:</strong> Para sincronizar dados entre dispositivos e servidores.</li>
      </ul>

      <h2>3. Segurança</h2>
      <p>Os dados são protegidos com criptografia e armazenados de forma segura para evitar acesso não autorizado.</p>

      <h2>4. Direitos do Usuário</h2>
      <p>Os usuários podem solicitar a exclusão do e-mail associado à conta a qualquer momento, em conformidade com a LGPD e GDPR.</p>

      <h2>5. Contato</h2>
      <p>Em caso de dúvidas ou solicitações relacionadas à privacidade, entre em contato conosco pelo e-mail de suporte.</p>

      <footer style={{ textAlign: 'center', marginTop: '30px', fontSize: '0.9em', color: '#666' }}>
        &copy; 2024 - Todos os direitos reservados.
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;
