import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {useTaskDetailsContext} from "@/contexts/taskDetailsContext";
import {FaTrash} from "react-icons/fa";
import {requestDeleteTask} from "@/lib/api/task";
import {useNavigate, useParams} from "react-router-dom";
import {routes} from "@/app/routes";
import {useState} from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { SAFE_AREA_TOP_CSS } from "@/app/config";

export default function TaskDeleteConfirmationModal() {
  const { isOpen: isOpen, onOpen, onClose } = useDisclosure()
  const { task } = useTaskDetailsContext();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  return (
      <>
        {
          <IconButton
              onClick={onOpen}
              colorScheme={'red'}
              aria-label={'Remover'}>
            <CloseIcon />
          </IconButton>
        }
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent pt={SAFE_AREA_TOP_CSS}>
            <ModalHeader>Cancelar</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Deseja cancelar essa tarefa?
            </ModalBody>

            <ModalFooter>
              <Button
                  isLoading={isLoading}
                  loadingText='Removendo'
                  isDisabled={isLoading}
                  colorScheme='blue'
                  mr={3}
                  onClick={() => {
                    setIsLoading(true)
                    requestDeleteTask(task.id)
                        .then(() => {
                          toast({
                            title: 'Tarefa cancelada com sucesso',
                            status: 'success',
                          })
                          navigate(routes.TASKS)
                          onClose()
                        })
                        .catch((err) => {
                          toast({
                            title: 'Ocorreu um erro ao remover a tarefa',
                            status: 'error'
                          });
                        })
                        .finally(() => {
                          setIsLoading(false)
                        });
                  }}>
                Sim
              </Button>
              <Button variant='ghost' onClick={onClose}>Não</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
  )
}
