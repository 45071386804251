import { Square, HStack, Spacer, Switch, Text, Box } from "@chakra-ui/react";
import { IoNotificationsOutline } from "react-icons/io5";

type Props = {
    isChecked: boolean;
    onChange: (checked: boolean) => void;
}
export default function NotificationSwitch(props: Props) {

    return (
        <Square bg={"blue.500"} borderRadius={15} minH={15} p={4}>
            <Box color={'white'} minW='100%'>
                <HStack textAlign={'start'} >
                    <IoNotificationsOutline size={25} width={50} />
                    <Box>
                        <Text fontSize='.8rem'>Receber notificações</Text>
                    </Box>
                    <Spacer />
                    <Switch
                        colorScheme={'gray'}
                        isChecked={props.isChecked}
                        onChange={(e) => {
                            props.onChange(e.target.checked);
                        }}
                    />
                </HStack>
            </Box>
        </Square>
    )
}