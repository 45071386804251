import { Box, Button, ButtonGroup, Flex, Heading, Input, InputGroup, InputRightElement, SimpleGrid, Spacer } from "@chakra-ui/react";
import { IoSend } from "react-icons/io5";
import CustomCard from "../card/customCard";
import { useState } from "react";
import { requestCreateComment } from "@/lib/api/comment";
import { Id } from "@/app/types";
type Props = {
    taskId: Id,
    onClick?: () => void;
    onCommentAdded: (feedback: string, id: Id) => void;
    onCommentAddError: (error: string) => void;
}

export default function CommentAddButton({ taskId, onClick, onCommentAdded, onCommentAddError }: Props) {
    const [comment, setComment] = useState<string>('');
    const [isLoading, setIsloading] = useState<boolean>(false);

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (!comment) return

        setIsloading(true);
        requestCreateComment({
            comment: comment,
            task_id: taskId
        }).then(({ data }) => {
            onCommentAdded('Comentário adicionado com sucesso', data.id);
        }).catch((_error) => {
            onCommentAddError('Erro ao adicionar comentário')
        }).finally(() => {
            setIsloading(false);
            setComment('')
        })
    }

    return (
        <CustomCard
            py={2}
            minHeight={'50px'}
            onClick={onClick}
            position='fixed'
            bottom='0'
            left={4}
            right={4}
            zIndex={3}
        >
            <form onSubmit={handleCommentSubmit}>
                <InputGroup size='lg'>
                    <Input
                        size='lg'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        type={'text'}
                        placeholder='Digite seu comentário...'
                    />
                    <InputRightElement width='6rem'>
                        <Button
                            isLoading={isLoading}
                            isDisabled={!comment}
                            type='submit'
                            colorScheme={'blue'}
                            h='1.75rem'
                            size='md'
                        >
                            Enviar
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </form>
        </CustomCard>
    )
}