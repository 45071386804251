import { Box, Card, CardBody, Flex, SimpleGrid, Skeleton, SkeletonText, Stack } from "@chakra-ui/react";

export default function TaskSkeleton() {
    return (<Card>
        <CardBody>
            <SimpleGrid spacing={2} columns={2}>
                <Skeleton borderRadius={20} height='23px' />
                <Skeleton borderRadius={20} height='23px' />
                <Skeleton borderRadius={20} height='23px' width={'50%'} />
            </SimpleGrid>
            <Skeleton my={3} height='70px' />
            <SkeletonText mt='4' noOfLines={2} spacing='4' skeletonHeight='2' />
            <Flex
                mt={2}
                w='100%'
                display='flex'
                gap={2}
            >
                <Box ms='auto'>
                    <Skeleton borderRadius={20} height='30px' width={'40px'} />
                </Box>
                <Box>
                    <Skeleton borderRadius={20} height='30px' width={'40px'} />
                </Box>
            </Flex>
        </CardBody>
    </Card>)
}