import { Id } from "@/app/types";
import { Role } from "@/lib/api/roles.types";
import { UserDepartment } from "@/lib/api/user.types";
import { CloseIcon } from "@chakra-ui/icons";
import { Card, Flex, Avatar, Heading, IconButton, CardBody, Box } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { MdWork } from "react-icons/md";

type Props = {
    userDepartment: UserDepartment
    roles: Role[]
    onRoleChange: (roleId: Id | null) => void
    onDelete: () => void

}

export default function UserDepartmentCard(props: Props) {
    const { userDepartment, roles, onDelete } = props;

    const isRoleSelected = !!userDepartment?.role?.id
    return (
        <Card width='100%'>
            <CardBody>
                <Flex mb={3}>
                    <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                        <Avatar size="sm" bg='red.500' icon={<MdWork fontSize='1rem' />} />
                        <Box>
                            <Heading size='sm'>{userDepartment.department.name}</Heading>
                        </Box>
                    </Flex>
                    <IconButton
                        size='sm'
                        onClick={onDelete}
                        colorScheme='gray'
                        aria-label='See menu'
                        icon={<CloseIcon />}
                    />
                </Flex>
                <Select
                    isSearchable={false}
                    value={isRoleSelected ? {
                        value: userDepartment.role.id,
                        label: userDepartment.role.name,
                    } : null}
                    placeholder='Selecione o cargo'
                    options={roles.map((role) => {
                        return {
                            value: role.id,
                            label: role.name
                        }
                    })}
                    onChange={(e) => {
                        props.onRoleChange(e.value)
                    }}
                />
            </CardBody>

        </Card>
    )
}