"use client";

import { SAFE_AREA_TOP_CSS } from "@/app/config";
import { routes, sidebarRoutes } from "@/app/routes";
import { bottomBarHeight } from "@/app/types";
import BottomBar from "@/components/bottomBar/bottomBar";
import Navbar from "@/components/navbar/navbar";
import runOneSignalMobile from "@/components/notifications/oneSignalMobile";
import runOneSignalWeb from "@/components/notifications/oneSignalWeb";
import Sidebar from "@/components/sidebar/sidebar";
import { AuthProvider } from "@/contexts/authContext";
import { ChatProvider } from "@/contexts/chatContext";
import TaskFiltersContext, { TaskFiltersType, emptyTaskFilters } from "@/contexts/taskFiltersContext";
import { instance } from "@/lib/api/api";
import { requestGetMe } from "@/lib/api/user";
import { AuthStateProps, setAuthState } from "@/lib/redux/slices/auth/slice";
import { IRootState, useDispatch, useSelector } from "@/lib/redux/store";
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from "@capacitor/core";
import { Box, ChakraProvider, Portal, extendTheme } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function ManagerLayout({ children }) {
    const navigate = useNavigate();

    // reducers 
    const auth = useSelector<IRootState, AuthStateProps>(state => state.auth);
    const dispatch = useDispatch()

    // contexts
    const [filters, setFilters] = useState<TaskFiltersType>(emptyTaskFilters);

    // set tokens
    instance.defaults.headers.common['Authorization'] = `Bearer ${auth.userToken}`;
    useEffect(() => {
        if (!auth.success) {
            navigate(routes.LOGIN);
            return;
        }
    });


    useEffect(() => {
        const handleBackButton = () => {
            const isRootPath = window.location.pathname === routes.HOME; // substitua pela rota inicial
            if (!isRootPath && window.history.length > 1) {
                window.history.back();
            } else {
                CapacitorApp.exitApp();
            }
        };

        CapacitorApp.addListener('backButton', handleBackButton);

        return () => {
            CapacitorApp.removeAllListeners();
        };

    }, [])

    // get user profile
    useEffect(() => {
        const clinicId = auth?.userClinic?.clinic?.id
        requestGetMe(clinicId).then(response => {
            dispatch(setAuthState({
                ...auth,
                userInfo: response.data
            }))
        }).catch(error => {
            console.log(error);
            return;
        })
    }, [])


    // oneSignal
    const [asked, setAsked] = useState(false);
    useEffect(() => {
        if (asked || !auth?.userInfo) {
            return;
        }

        if (Capacitor.isNativePlatform()) {
            runOneSignalMobile(auth.userInfo);
        } else {
            runOneSignalWeb(auth.userInfo);
        }

        setAsked(true);
    }, [])

    const theme = extendTheme({
        colors: {
            blue: {
                600: "#4577EF",
                500: "#4577EF",
            },
        },
    })


    return (
        <ChakraProvider
            theme={theme}
            toastOptions={{ defaultOptions: { position: 'top' } }}
        >
            <AuthProvider>
                <ChatProvider>
                    <TaskFiltersContext.Provider value={{ filters, setFilters }}>
                        <Box>
                            <Box
                                float='right'
                                maxWidth={{ base: '100%', xl: 'calc( 100% - 110px )' }}
                                width={{ base: '100%', xl: 'calc( 100% - 110px )' }}
                                overflow='auto'
                                transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                                transitionDuration='.2s, .2s, .35s'
                                transitionProperty='top, bottom, width'
                                transitionTimingFunction='linear, linear, ease'
                            >
                                <Portal>
                                    <Navbar
                                        fixed={true}
                                        brandText={"Hub X One"}
                                    />
                                </Portal>
                                <Box
                                    mx='auto'
                                    p={{ base: '1.25rem' }}
                                    pt='6rem'
                                    mt={SAFE_AREA_TOP_CSS}
                                    pe='20px'
                                    mb={bottomBarHeight}
                                >
                                    <Outlet />
                                </Box>
                                <Portal >
                                    <Box display={{ base: 'block', xl: 'none' }}>
                                        <BottomBar items={sidebarRoutes} minH={bottomBarHeight} />
                                    </Box>
                                </Portal>
                            </Box>
                            <Box display={{ base: 'none', xl: 'block' }}>
                                <Sidebar routes={sidebarRoutes} />
                            </Box>
                        </Box>
                    </TaskFiltersContext.Provider>
                </ChatProvider>
            </AuthProvider>
        </ChakraProvider>
    );
}
