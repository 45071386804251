import { Id } from "@/app/types"
import { FormattedNotification } from "@/lib/api/notifications.types"
import { Avatar, Box, Button, Flex, Spacer, Text, VStack } from "@chakra-ui/react"
import {Link} from "react-router-dom";

type Props = {
    notification: FormattedNotification
    onDetailsClick?: (id:Id) => void
}
export default function NotificationCard({ notification, onDetailsClick }: Props) {
    const sizeByType = 'sm'
    return (
        <VStack alignItems={'start'} minW={'300px'}>
            <Flex gap={2} alignItems={'center'}>
                <Box w='30px'>
                    <Avatar
                        size='sm'
                        name={notification.user.name}
                        src={notification?.user.picture}
                    />
                </Box>
                <Box w=''>
                    <Text fontSize={sizeByType} as='b'>{notification.user.name} • {notification.date}  </Text>
                    <Text fontSize={sizeByType} >{notification.message}</Text>
                </Box>
            </Flex>
            <Box width={'100%'} textAlign={'end'}>
                <Button
                    as={Link}
                    size='sm'
                    colorScheme='blue'
                    to={notification.url}
                    onClick={() => {
                        onDetailsClick && onDetailsClick(notification.id)
                    }}
                >
                    Detalhes
                </Button>
            </Box>
        </VStack>
    )
}