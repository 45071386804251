import { Task } from "@/lib/api/task.types";
import { createContext, useContext } from "react";

const TaskDetailsContext = createContext(null);

export default TaskDetailsContext;


export type TaskDetailsContextType = {
    task: Task,
    setTask: (task: Task) => void,
    getTask: () => Promise<any>,
    isLoading: boolean,
}

export function useTaskDetailsContext() {
    return useContext<TaskDetailsContextType>(TaskDetailsContext)
};