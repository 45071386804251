import { Id } from "@/app/types";
import { instance } from "./api";
import { Task, TaskCreateRequest, TaskFiltersOptions, TaskFinishRequest, TaskResponse } from "./task.types";
import { TaskFiltersType } from "@/contexts/taskFiltersContext";

const getParams = (clinicId: Id | null = null, filters?: TaskFiltersType) => {
    let queryParams = {}

    if (clinicId) {
        queryParams = {
            clinic_id: clinicId
        }
    }
    if (filters) {
        Object.keys(filters).forEach(function (key) {
            let value = filters[key];
            if (!!value) {
                if (typeof filters[key] === 'object') {
                    filters[key] = filters[key].value
                }
            }
        })

        queryParams = {
            ...queryParams,
            ...filters
        }
    }

    return queryParams
}
export async function requestGetAllTasks(clinicId: Id | null = null, filters?: TaskFiltersType) {
    let url = '/tasks'
    return await instance.get<TaskResponse>(url, { params: getParams(clinicId, filters) });
}

export async function requestGetAssignedToMeTasks(clinicId: Id | null = null, filters?: TaskFiltersType) {
    let url = '/tasks/filters/assigned_to_me'
    return await instance.get<TaskResponse>(url, { params: getParams(clinicId, filters) });
}

export async function requestGetCreatedByMeTasks(clinicId: Id | null = null, filters?: TaskFiltersType) {
    let url = '/tasks/filters/created_by_me'
    return await instance.get<TaskResponse>(url, { params: getParams(clinicId, filters) });
}

export async function requestGetUnassignedTasks(clinicId: Id | null = null, filters?: TaskFiltersType) {
    let url = '/tasks/filters/unassigned'
    return await instance.get<TaskResponse>(url, { params: getParams(clinicId, filters) });
}

export async function requestGetFinishedByMeTasks(clinicId: Id | null = null, filters?: TaskFiltersType) {
    let url = '/tasks/filters/finished_by_me'
    return await instance.get<TaskResponse>(url, { params: getParams(clinicId, filters) });
}

export async function requestGetAssignedTasks(clinicId: Id | null = null, filters?: TaskFiltersType) {
    let url = '/tasks/filters/assigned'
    return await instance.get<TaskResponse>(url, { params: getParams(clinicId, filters) });
}

export async function requestGetTaskById(id: Id) {
    return await instance.get<Task>(`/tasks/${id}`);
}

export async function requestDeleteTask(id: Id) {
    return await instance.delete(`/tasks/${id}`);
}

export async function requestFinishTask(id: Id, body: TaskFinishRequest) {
    return await instance.post(`/tasks/${id}/finish`, body);
}

export async function requestCreateTask(body: TaskCreateRequest) {
    return await instance.post('/tasks', body);
}

export async function requestUpdateTask(id: string, body: Task) {
    return await instance.put(`/tasks/${id}`, body);
}

export async function requestAssignTask(id: Id, body: { assigner_id: Id }) {
    return await instance.post(`/tasks/${id}/assign`, body);
}

export async function requestClaimTask(id: Id, body: { assigner_id: Id }) {
    return await instance.post(`/tasks/${id}/claim`, body);
}

export async function requestGetTaskFilterOptions() {
    return await instance.get<TaskFiltersOptions>('/tasks/filters/options');
}