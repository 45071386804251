import { useSelector } from "@/lib/redux/store";

import { Capacitor } from '@capacitor/core';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { USE_QUERY_STALE_SECONDS } from './app/config';
import { requestIntegrityToken } from './components/playIntegrity/playIntegrity';
import RequestEmailDeletionPage from './pages/deletion-email';
import LoginLayout from './pages/login/layout';
import LoginPage from './pages/login/page';
import ResetPasswordPage from './pages/login/recover-password/[token]/page';
import RecoverPasswordPage from './pages/login/recover-password/page';
import ManagerLayout from './pages/manager/layout';
import ConversationPage from './pages/manager/messages/[conversationID]/page';
import MessagesLayout from './pages/manager/messages/layout';
import ConversationsPage from './pages/manager/messages/page';
import ManagerPage from './pages/manager/page';
import ClinicEditPage from './pages/manager/settings/clinics/[id]/page';
import ClinicAddPage from './pages/manager/settings/clinics/add/page';
import ClinicsPage from './pages/manager/settings/clinics/page';
import DepartmentEditPage from './pages/manager/settings/departments/[id]/page';
import DepartmentAddPage from './pages/manager/settings/departments/add/page';
import DepartmentsPage from './pages/manager/settings/departments/page';
import SettingsLayout from './pages/manager/settings/layout';
import SettingsPage from './pages/manager/settings/page';
import ProfileSettingsPage from './pages/manager/settings/profile/page';
import ServiceEditPage from './pages/manager/settings/services/[id]/page';
import ServiceAddPage from './pages/manager/settings/services/add/page';
import ServicesPage from './pages/manager/settings/services/page';
import UserEditPage from './pages/manager/settings/users/[id]/page';
import UserAddPage from './pages/manager/settings/users/add/page';
import UsersPage from './pages/manager/settings/users/page';
import TaskDetailsPage from './pages/manager/tasks/[id]/TaskDetails';
import TaskAddPage from './pages/manager/tasks/add/Add';
import AllTasksPage from './pages/manager/tasks/all/All';
import TaskEditPage from './pages/manager/tasks/edit/[id]/page';
import FinishedTasksPage from './pages/manager/tasks/finished/Finished';
import InProgressTasksPage from './pages/manager/tasks/in_progress/InProgress';
import UnfinishedTasksPage from './pages/manager/tasks/my_tasks/MyTasks';
import TasksPage from './pages/manager/tasks/page';
import PrivacyPolicyPage from './pages/terms';

function App() {
  const auth = useSelector(state => state.auth);
  const isLogged = !!auth.success;
  const isAndroid = Capacitor.getPlatform() == 'android';

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: USE_QUERY_STALE_SECONDS * 1000,
      },
    },
  })

  useEffect(() => {
    if (isAndroid) {
      requestIntegrityToken()
    }

  }, [isAndroid])

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/request-email-deletion" element={<RequestEmailDeletionPage />} />
          {
            isLogged &&
            <Route path="/manager" element={<ManagerLayout />}>
              <Route index element={<ManagerPage />} />
              <Route path="tasks">
                <Route index element={<TasksPage />} />
                <Route path=":id" element={<TaskDetailsPage />} />
                <Route path="add" element={<TaskAddPage />} />
                <Route path="edit/:id" element={<TaskEditPage />} />
                <Route path="all" element={<AllTasksPage />} />
                <Route path="finished" element={<FinishedTasksPage />} />
                <Route path="in_progress" element={<InProgressTasksPage />} />
                <Route path="my_tasks" element={<UnfinishedTasksPage />} />
              </Route>
              <Route path="messages" element={<MessagesLayout />}>
                <Route index element={<ConversationsPage />} />
                <Route path=":conversationID" element={<ConversationPage />} />
              </Route>
              <Route path="settings" element={<SettingsLayout />}>
                <Route index element={<SettingsPage />} />
                <Route path="profile" element={<ProfileSettingsPage />} />
                <Route path="users">
                  <Route index element={<UsersPage />} />
                  <Route path=":id" element={<UserEditPage />} />
                  <Route path="add" element={<UserAddPage />} />
                </Route>
                <Route path="departments">
                  <Route index element={<DepartmentsPage />} />
                  <Route path=":id" element={<DepartmentEditPage />} />
                  <Route path="add" element={<DepartmentAddPage />} />
                </Route>
                <Route path="clinics">
                  <Route index element={<ClinicsPage />} />
                  <Route path=":id" element={<ClinicEditPage />} />
                  <Route path="add" element={<ClinicAddPage />} />
                </Route>
                <Route path="services">
                  <Route index element={<ServicesPage />} />
                  <Route path=":id" element={<ServiceEditPage />} />
                  <Route path="add" element={<ServiceAddPage />} />
                </Route>
              </Route>

            </Route>
          }
          {!isLogged &&
            <Route element={<LoginLayout />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/recover-password" element={<RecoverPasswordPage />} />
              <Route path="/login/recover-password/:token" element={<ResetPasswordPage />} />
            </Route>
          }
          <Route path="*" element={isLogged ?
            <Navigate to="/manager" replace /> :
            <Navigate to="/login" replace />
          } />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;