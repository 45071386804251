import { Department } from "@/lib/api/departments.types"
import { User } from "@/lib/api/user.types"
import { Avatar, Tag, TagLabel } from "@chakra-ui/react"
import { MdWork } from "react-icons/md"

type Props = {
    department: Department
    color?: string
}
export default function DepartmentBadge({ department, color = 'blue' }: Props) {
    return (
        <Tag size='md' colorScheme={color} borderRadius='full'>
            <TagLabel>{department.name}</TagLabel>
        </Tag>
    )
}