import { Box, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { FaRegComment } from "react-icons/fa6";

type CommentsCountProps = {
    count: number
    onlyNumber: boolean,
    [x: string]: any,
}
export default function CommentsCountBadge({ count, onlyNumber, ...rest }: CommentsCountProps) {
    return (
        <Tag size='lg' colorScheme='gray' borderRadius='full' {...rest}>
            <TagLeftIcon as={FaRegComment} />
            <TagLabel>{count}{!onlyNumber && ' comentários'}</TagLabel>
        </Tag>
    )
}