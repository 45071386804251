import { Id } from "@/app/types";
import { instance } from "./api";
import { CreateAttachmentResponse } from "./attatchment.types";

export async function requestCreateAttachment(file: File) {
    const formData = new FormData();
    formData.append('attachments', file);

    const headers = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }

    return await instance.post<CreateAttachmentResponse[]>('/attachments', formData, headers);
}

export async function requestDownloadAttachment(id: Id) {
    return await instance.get(`/attachments/${id}/download`, {
        responseType: 'blob'
    });
}