'use client';

import { TaskFiltersType, emptyTaskFilters } from "@/contexts/taskFiltersContext";
import { requestGetTaskFilterOptions } from "@/lib/api/task";
import { TaskFiltersOptions } from "@/lib/api/task.types";
import { Avatar, Box, Button, Card, CardBody, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Spacer, Tag, TagRightIcon, VStack, useDisclosure } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa6";
import SearchBar from "../search/searchBar";
import { SAFE_AREA_TOP_CSS } from "@/app/config";


type Props = {
    filters: TaskFiltersType
    setFilters: (filters: TaskFiltersType) => void
    isLoading?: boolean
    onSetFilters?: (filters) => void;
}
export default function TasksFilters({
    filters,
    setFilters,
    onSetFilters
}: Props) {

    const [filterOptions, setFilterOptions] = useState<TaskFiltersOptions | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const noFilter = { label: 'Todos', value: null }

    const getFilterValue = (filter) => {
        return filters[filter] ?? noFilter;
    }

    useEffect(() => {
        requestGetTaskFilterOptions()
            .then(({ data }) => {
                const _filterOptions = data;
                for (const key in _filterOptions) {
                    if (_filterOptions[key as keyof TaskFiltersOptions]) {
                        _filterOptions[key as keyof TaskFiltersOptions].unshift(noFilter)
                    }
                }
                setFilterOptions(data)
            })
    }, [])

    if (!filterOptions) return <Progress isIndeterminate my={1} />;

    const activeFiltersCount = Object.values(filters).filter(Boolean).length

    const ActionButtons = () => (
        <>
            <Button variant='ghost'
                onClick={() => {
                    setFilters(emptyTaskFilters)
                    onSetFilters?.(emptyTaskFilters)
                }}
            >
                Limpar filtros
            </Button>
            <Button colorScheme='blue' mr={3} onClick={() => {
                onSetFilters?.(filters)
                onClose()
            }}>
                Definir
            </Button>
        </>
    )
    return <Box
        mb={2}>
        <HStack>
            <SearchBar
                onSearch={(value) => {
                    if (!value) {
                        return
                    }
                    const newFilters = { ...filters, search: value }
                    setFilters(newFilters)
                    onSetFilters?.(newFilters)
                }}
                value={filters.search}
            />
            <Spacer />
            <Tag
                cursor={'pointer'}
                onClick={onOpen}
                size='lg'
                colorScheme='blue'
                borderRadius='full'>
                <Avatar
                    size='xs'
                    name={activeFiltersCount.toString()}
                    ml={-1}
                    mr={2}
                />
                <TagRightIcon mr={1}>
                    <FaFilter size='md' />
                </TagRightIcon>
            </Tag>
        </HStack>
        <Modal isOpen={isOpen} onClose={onClose} size='full'>
            <ModalOverlay />
            <ModalContent pt={SAFE_AREA_TOP_CSS}>
                <ModalHeader>Filtrar tarefas</ModalHeader>
                <ModalCloseButton
                    position={'fixed'}
                    top={`calc(1rem+${SAFE_AREA_TOP_CSS})`}
                    right={'1rem'}
                    size='lg'
                    zIndex={2}
                />
                <ModalBody>
                    <HStack>
                        <Spacer />
                        <ActionButtons />
                    </HStack>
                    <VStack gap={2}>
                        <FormControl>
                            <FormLabel>Criado por</FormLabel>
                            <Select
                                options={filterOptions.created_by as any[]}
                                onChange={(value) => setFilters({ ...filters, created_by: value })}
                                value={getFilterValue('created_by')}
                                placeholder="Selecione um usuário"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => 'Nenhum usuário encontrado'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Atribuído para</FormLabel>
                            <Select
                                options={filterOptions.assigner as any[]}
                                onChange={(value) => setFilters({ ...filters, assigner: value })}
                                value={getFilterValue('assigner')}
                                placeholder="Selecione um usuário"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => 'Nenhum usuário encontrado'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Prioridade</FormLabel>
                            <Select
                                options={filterOptions.priority as any[]}
                                onChange={(value) => setFilters({ ...filters, priority: value })}
                                value={getFilterValue('priority')}
                                placeholder="Selecione uma prioridade"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => 'Nenhum prioridade'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Status</FormLabel>
                            <Select
                                options={filterOptions.status as any[]}
                                onChange={(value) => setFilters({ ...filters, status: value })}
                                value={getFilterValue('status')}
                                placeholder="Selecione um status"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => 'Nenhum status encontrado'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Serviço</FormLabel>
                            <Select
                                options={filterOptions.service as any[]}
                                onChange={(value) => setFilters({ ...filters, service: value })}
                                value={getFilterValue('service')}
                                placeholder="Selecione um serviço"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => 'Nenhum serviço encontrado'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Clinica</FormLabel>
                            <Select
                                options={filterOptions.clinic as any[]}
                                onChange={(value) => setFilters({ ...filters, clinic: value })}
                                value={getFilterValue('clinic')}
                                placeholder="Selecione uma clínica"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => 'Nenhuma clínica encontrada'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Departamento</FormLabel>
                            <Select
                                options={filterOptions.department as any[]}
                                onChange={(value) => setFilters({ ...filters, department: value })}
                                value={getFilterValue('department')}
                                placeholder="Selecione um departamento"
                                isClearable
                                isSearchable
                                noOptionsMessage={() => 'Nenhum departamento encontrado'}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Data de vencimento</FormLabel>
                            <Card>
                                <CardBody>
                                    <FormLabel>Data inicial</FormLabel>
                                    <Input type="date" value={filters.due_date_from} onChange={(e) => setFilters({ ...filters, due_date_from: e.target.value })} />
                                    <FormLabel>Data final</FormLabel>
                                    <Input type="date" value={filters.due_date_to} onChange={(e) => setFilters({ ...filters, due_date_to: e.target.value })} />
                                </CardBody>
                            </Card>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Data de criação</FormLabel>
                            <Card>
                                <CardBody>
                                    <FormLabel>Data inicial</FormLabel>
                                    <Input type="date" value={filters.created_at_from} onChange={(e) => setFilters({ ...filters, created_at_from: e.target.value })} />
                                    <FormLabel>Data final</FormLabel>
                                    <Input type="date" value={filters.created_at_to} onChange={(e) => setFilters({ ...filters, created_at_to: e.target.value })} />
                                </CardBody>
                            </Card>
                        </FormControl>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <ActionButtons />
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
}