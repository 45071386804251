import { Id } from "@/app/types";
import { instance } from "./api";
import { Conversation, CreateConversation, CreateMessage, Message } from "./messages.types";

export async function requestGetMessages(conversationID: string) {
    return await instance.get<Message[]>("/messages");
}

export async function requestPostMessage(body: CreateMessage) {
    return await instance.post<Message>("/messages", body);
}

export async function requestGetConversations() {
    return await instance.get<Conversation[]>("/conversations");
}

export async function requestGetConversation(id: Id, quietly: boolean) {
    const params = {};
    if (quietly)
        params["quietly"] = true;
    return await instance.get<Conversation>(`/conversations/${id}`,
        {
            params: params
        }
    );
}

export async function requestPostConversation(body: CreateConversation) {
    return await instance.post<Conversation>("/conversations", body);
}

export async function requestMarkMessageAsRead(id: Id) {
    return await instance.put<Message>(`/messages/${id}/read`);
}