import { Center, Heading, Image, VStack } from "@chakra-ui/react";

type Props = {}
export default function EmptyTaskList({ }: Props) {
    const noTaskSvgSize = {
        sm: 300,
        md: 500,
    }
    return (
        <Center>
            <VStack>
            <Heading size="lg" my={5}>
                Nenhuma tarefa encontrada
            </Heading>
            <Image
                src={"/vectors/undraw_add_tasks_re_s5yj.svg"}
                width={noTaskSvgSize}
                height={noTaskSvgSize}
            />
            </VStack>
        </Center>
    )
}