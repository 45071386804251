'use client'
import { routes } from "@/app/routes";
import { requestPostConversation } from "@/lib/api/message";
import { CreateConversation } from "@/lib/api/messages.types";
import { requestGetUsers } from "@/lib/api/user";
import { User } from "@/lib/api/user.types";
import { Box, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaMessage } from "react-icons/fa6";
import { BeatLoader } from "react-spinners";
import UserItem from "../settings/users/userItem";
import { AuthStateProps } from "@/lib/redux/slices/auth/slice";
import { IRootState } from "@/lib/redux/store";
import { useSelector } from "react-redux";
import { useChatContext } from "@/contexts/chatContext";
import { SAFE_AREA_TOP_CSS } from "@/app/config";


export default function NewConversationButton() {
    const [isLoading, setIsloading] = useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const navigate = useNavigate(); 
    const { users } = useChatContext();
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

    useEffect(() => {
        setFilteredUsers(users);
    }, [users])

    const handleUserSelect = (user) => {
        requestPostConversation({
            users: [user.id]
        } as CreateConversation).then((res) => {
            navigate(routes.CONVERSATION_BY_ID.replace(':conversationID', res.data.id.toString()))
            onClose()
        });
    };

    return (
        <Box
            position='fixed'
            bottom={'2rem'}
            right={4}
            zIndex={3}
        >
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    setFilteredUsers(users);
                    setIsloading(false);
                    onClose()
                }}
                motionPreset='slideInRight'
                size='full'>
                <ModalOverlay />
                <ModalContent pt={SAFE_AREA_TOP_CSS}>
                    <ModalHeader>Selecione um usuário</ModalHeader>
                    <ModalCloseButton
                        position={'fixed'}
                        top={`calc(1rem+${SAFE_AREA_TOP_CSS})`}
                        right={'1rem'}
                        size='lg'
                        zIndex={2}
                        onClick={onClose}
                    />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Input
                                placeholder='Pesquisar'
                                onChange={(e) => {
                                    setFilteredUsers(users.filter((user) =>
                                        user.name.toLowerCase().includes(e.target.value.toLowerCase())
                                    ));
                                }}
                            />
                            {filteredUsers.map((user) => (
                                <UserItem
                                    key={user.id}
                                    user={user as User}
                                    onClick={() => {
                                        handleUserSelect(user);
                                    }}

                                />
                            ))}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <IconButton
                aria-label="add"
                isRound
                icon={<FaMessage />}
                onClick={() => {
                    onOpen()
                }}
                disabled={isLoading}
                spinner={<BeatLoader size={8} color='white' />}
                isLoading={isLoading}
                colorScheme={'blue'}
                size='lg'
            >
                Nova conversa
            </IconButton>
        </Box>
    )
}