import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import TaskFinishModal from "./taskFinishModal";
import { useTaskDetailsContext } from "@/contexts/taskDetailsContext";
import { SAFE_AREA_TOP_CSS } from "@/app/config";
export default function TaskFinishConfirmationModal() {
  const { isOpen: isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isFinishModalOpen, onOpen: onFinishModalOpen, onClose: onFinishModalClose } = useDisclosure();
  const { getTask, task } = useTaskDetailsContext();

  const toast = useToast();

  return (
    <>
      {task.can_finish && <Button colorScheme='blue' onClick={onOpen} >Finalizar</Button>}
      <TaskFinishModal
        isOpen={isFinishModalOpen}
        onClose={onFinishModalClose}
        comments={[]}
        onFinish={(() => {
          toast({
            title: 'Tarefa finalizada',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
          return getTask();
        })}
        onError={() => { }} />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent pt={SAFE_AREA_TOP_CSS}>
          <ModalHeader>Finalizar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Deseja finalizar essa tarefa?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {
              onFinishModalOpen();
              onClose();
            }}>
              Sim
            </Button>
            <Button variant='ghost' onClick={onClose}>Não</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
