"use client"
import { Task } from "@/lib/api/task.types";
import { TASK_ASSIGNEE_STATUS_CANCELLED, TASK_ASSIGNEE_STATUS_DONE, colorByDueDate, taskStatus } from "@/utils/translators";
import { Box, Card, CardBody, Flex, HStack, Heading, Spacer, Text } from "@chakra-ui/react";
import AttachmentCountBadge from "../badges/attachmentCountBadge";
import CommentsCountBadge from "../badges/commentsCountBadge";
import DepartmentBadge from "../badges/departmentBadge";
import DueDateBadge from "../badges/duedateBadge";
import PriorityBadge from "../badges/priorityBadge";
import UserBadge from "../badges/userBadge";

type Props = {
    task: Task
}

export default function TaskCard({ task }: Props) {
    const taskIsDone = task.status === TASK_ASSIGNEE_STATUS_DONE;
    const taskIsCanceled = task.status === TASK_ASSIGNEE_STATUS_CANCELLED;
    return (
        <Card>
            <CardBody>
                <HStack spacing={2} mb={2}>
                    <Heading
                        size={'md'}
                        textDecoration={(taskIsDone || taskIsCanceled) ? 'line-through' : 'none'}
                    >
                        {task.title}
                    </Heading>

                    <Spacer />
                    <UserBadge user={task.created_by_user} />
                </HStack>
                <HStack spacing={2} mb={2}>
                    <Text>Vencimento:</Text> <Spacer />
                    <DueDateBadge due_date={task.due_date} isDone={taskIsDone || taskIsCanceled} />
                </HStack>
                <HStack spacing={2} mb={2}>
                    <Text>Prioridade:</Text> <Spacer />
                    <PriorityBadge priority={task.priority} />
                </HStack>
                <HStack spacing={2} mb={2}>
                    <Text>Departamento:</Text> <Spacer />
                    {task.departments.map((department, key) => {
                        return <DepartmentBadge key={key} department={department} />
                    })}
                </HStack>
                <HStack spacing={2} mb={2}>
                    <Text>Clinica:</Text> <Spacer />
                    <>{task.clinic.name}</>
                </HStack>
                <HStack spacing={2} mb={2}>
                    <Text>Status:</Text> <Spacer />
                    <>{taskStatus(task.status)}</>
                </HStack>
                <Flex
                    w='100%'
                    display='flex'
                    gap={2}
                >
                    <Box ms='auto'>
                        <CommentsCountBadge onlyNumber count={task.comments_count} />
                    </Box>
                    <Box>
                        <AttachmentCountBadge onlyNumber count={task.attachments_count} />
                    </Box>
                </Flex>
            </CardBody>
            <Box bg={!taskIsDone && !taskIsCanceled ? colorByDueDate(task.due_date) : 'gray'} w="100%" p={.5} />
        </Card>
    )
}