import { routes } from "@/app/routes";
import { Conversation } from "@/lib/api/messages.types";
import { Avatar, AvatarBadge, Box, Flex, FlexProps, HStack, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import { Link } from "react-router-dom";

import ConfirmationIcon from "./confirmationIcon";

interface Props extends FlexProps {
    conversation: Conversation,
    isConversationOpen?: boolean
}

export default function ConversationCard({
    conversation,
    isConversationOpen,
    ...props
}: Props) {
    const conversationBg = useColorModeValue("gray.100", "blue.800");
    const conversationBgWhenOpen = useColorModeValue("gray.200", "blue.900");
    const textColor = useColorModeValue("gray.700", "white");

    const lastMessage = conversation?.last_message;

    if (!conversation) return null;

    const badgeText = `${conversation.unread_messages_count}`;

    return (
        <Flex
            key={conversation.id}
            color={textColor}
            maxH={'4rem'}
            width={'100%'}
            borderRadius={!isConversationOpen ? '15' : 0}
            bg={isConversationOpen ? conversationBgWhenOpen : conversationBg}
            py={!isConversationOpen ? 8 : 3}
            as={Link}
            to={!isConversationOpen ? routes.CONVERSATION_BY_ID.replace(':conversationID', conversation.id.toString()) : '#'}
            alignItems={'center'}
            {...props}
        >
            <Avatar
                name={conversation.principal_participant.name}
                mr={3}
                size='md'
            >
                {!isConversationOpen && conversation.unread_messages_count > 0 && (
                    <AvatarBadge boxSize='1.25em' bg='green.500'>
                        <Text fontSize='0.5em' color={textColor}>{badgeText}</Text>
                    </AvatarBadge>
                )}
            </Avatar>
            <Box flex="1">
                <HStack>
                    <Heading size="sm">
                        {conversation.principal_participant.name}
                    </Heading>
                </HStack>
                {!isConversationOpen && (
                    <HStack>
                        <ConfirmationIcon message={lastMessage} />
                        <Text
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            maxW={'10rem'}
                        >
                            {lastMessage?.content}
                        </Text>
                    </HStack>
                )}
            </Box>
            {!isConversationOpen && (
                <HStack>
                    <Box ml={1} fontSize="sm" color="gray.500">
                        {conversation.last_message?.created_at && moment(conversation.last_message?.created_at).fromNow()}
                    </Box>
                </HStack>
            )}
        </Flex>
    )
}