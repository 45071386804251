import { Attachment } from "@/lib/api/attatchment.types";
import { fileExtensionColor, fileExtensionIcon } from "@/utils/translators";
import { Capacitor } from "@capacitor/core";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, ButtonGroup, HStack, Icon, IconButton, Progress, Text } from "@chakra-ui/react";
import { FaDownload, FaShare } from "react-icons/fa6";
import { IoMdOpen } from "react-icons/io";

type Props = {
    attachment?: Attachment;
    enableDownloadOrShare?: boolean;
    enableView?: boolean;
    enableDelete?: boolean;
    enableProgress?: boolean;
    enableCheckIcon?: boolean;
    onDelete?: () => void;
    onDownloadOrShare?: () => void;
    onView?: () => void;
}

export default function AttachmentItem({
    attachment = {} as Attachment,
    enableView = true,
    enableDownloadOrShare: enableDownloadOrShare = true,
    enableDelete = true,
    enableProgress = false,
    enableCheckIcon = false,
    onDelete = () => { },
    onDownloadOrShare: onDownloadOrShare = () => { },
    onView: onView = () => { }
}: Props) {
    const isMobile = Capacitor.isNativePlatform();

    return (
        <>
            <HStack alignItems="center" spacing={4} width="100%">
                <HStack spacing={3} flex="1" overflow="hidden">
                    <Icon
                        boxSize={7}
                        as={fileExtensionIcon(attachment.extension)}
                        color={fileExtensionColor(attachment.extension)}
                    />
                    <Text> {attachment.original_name} </Text>
                </HStack>
                <HStack spacing={3}>
                    {!!attachment.id && enableCheckIcon && <CheckIcon color='green.400' />}
                    <ButtonGroup gap='2'>
                        {enableDownloadOrShare && (
                            <IconButton
                                icon={isMobile ? <FaShare /> : <FaDownload />}
                                aria-label={isMobile ? 'Compartilhar' : 'Baixar'}
                                onClick={onDownloadOrShare}
                            />
                        )}
                        {(enableView && isMobile) && (
                            <IconButton
                                icon={<IoMdOpen />}
                                aria-label={'Abrir'}
                                onClick={onView}
                            />
                        )}
                        {enableDelete && (
                            <IconButton
                                icon={<CloseIcon />}
                                aria-label='Apagar'
                                onClick={onDelete}
                            />
                        )}
                    </ButtonGroup>
                </HStack>
            </HStack>
            {enableProgress && (
                <Box pt={1} pb={2}>
                    <Progress colorScheme='green' size='xs' value={attachment.progress} />
                </Box>
            )}
        </>
    )
}