import { Id } from "@/app/types"

export type Department = {
    id: Id
    name: string
    description: string
    created_at: string
    updated_at: string,
    children?: Department[]
    parents?: Department[]
}

export type CreateDepartmentRequest = {
    name: string
    description: string
    children?: Department[]
    parents?: Department[]
}

export type UpdateDepartmentRequest = {
    name: string
    description: string
    children?: Department[]
    parents?: Department[]
}

export const createDepartmentRequestInitialValues: CreateDepartmentRequest = {
    name: '',
    description: ''
}
