import { GCP_NUMBER } from '@/app/config';
import { PlayIntegrity } from '@capacitor-community/play-integrity';

export const requestIntegrityToken = async () => {
    const nonce = generateNonce()
    try {
        const result = await PlayIntegrity.requestIntegrityToken({
            nonce: nonce,
            googleCloudProjectNumber: GCP_NUMBER,
        });

        console.log('Resultado do Play Integrity:', result);

        validateIntegrity(result);

        return result;
    } catch (err) {
        console.error('Erro ao solicitar token de integridade:', err);
    }
};

const validateIntegrity = (result) => {

    if (result.token) {
        console.log('Token recebido com sucesso:', result.token);

    } else {
        console.warn('Token inválido ou ausente!');

    }
};

const generateNonce = () => {
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);

    const byteArray = Array.from(array);
    return btoa(String.fromCharCode(...byteArray));
};
