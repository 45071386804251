/* eslint-disable */

import { Box, Flex, Grid, HStack, IconButton, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { Link } from "react-router-dom";

import { useLocation } from 'react-router-dom';
;
import { useCallback } from 'react';


export function SidebarLinks(props) {
  const { routes } = props;

  const { pathname } = useLocation();
  let activeIcon = useColorModeValue('brand.500', 'blue.400');
  let textColor = useColorModeValue('secondaryGray.500', 'white');

  const activeRoute = useCallback(
    (routeName) => {
      return pathname?.includes(routeName);
    },
    [pathname],
  );

  return <Grid templateColumns='repeat(1, 1fr)' gap={2}>
    {routes.map((route, index) => (
      <Box key={index}>
        <Tooltip label={route.name}>
          <Link key={index}
              to={route.path ? route.path : ''}
          >
            <Box>
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                }
                py="5px"
                ps="10px"
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <IconButton
                    variant='ghost'
                    width={'100%'}
                    size={'lg'}
                    color={activeRoute(route.path.toLowerCase())
                      ? activeIcon
                      : textColor}
                    icon={route.icon}
                  />

                </Flex>
                <Box
                  h="36px"
                  w="4px"
                  bg={
                    activeRoute(route.path.toLowerCase())
                      ? activeIcon
                      : 'transparent'
                  }
                  borderRadius="5px"
                />
              </HStack>
            </Box>
          </Link>
        </Tooltip>
      </Box>
    ))}
  </Grid>
}

export default SidebarLinks;