"use client";

import { routes } from "@/app/routes";
import { Box, Tab, TabList, Tabs } from "@chakra-ui/react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PermissionGate from "@/components/permissionGate/PermissionGate";

export default function SettingsLayout({ children }) {
    const tabs = [
        { name: "Perfil", route: routes.PROFILE, scope:"app-settings-profile"},
        { name: "Usuários", route: routes.USERS, scope:"app-settings-user-read"},
        { name: "Departamentos", route: routes.DEPARTMENTS, scope:"app-settings-department-read"},
        { name: "Clínicas", route: routes.CLINICS, scope:"app-settings-clinic-read"},
        { name: "Serviços", route: routes.SERVICES, scope:"app-settings-service-read"},
    ];

    const location = useLocation();
    const navigate = useNavigate();
    const pathname = location.pathname;
    const currentTab = tabs.findIndex((tab) => pathname.includes(tab.route));

    return (
        <Tabs
            maxWidth={'100%'}
            isFitted
            variant='solid-rounded'
            colorScheme='blue'
            isLazy
            defaultIndex={currentTab}
            index={currentTab}
        >
            <TabList gap={2} overflowX='scroll' width={'100%'}>
                {tabs.map((tab, index) => (
                    <PermissionGate key={index} scope={tab.scope}>
                        <Tab
                            borderRadius={5}
                            key={index}
                            onClick={() => {
                                navigate(tab.route);
                            }}
                        >
                            {tab.name}
                        </Tab>
                    </PermissionGate>
                ))}
            </TabList>
            <Box py={6} px={2}>
                <Outlet/>
            </Box>
        </Tabs>
    );
}
